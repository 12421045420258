import {

    START_LOAD_LICENSING_INFO,
    LOAD_LICENSING_INFO_SUCCESS,
    LOAD_LICENSING_INFO_FAILURE,
    INIT_LOAD_LICENSING_INFO_ACTION_STATUS,

    SELECT_LICENSING_DATA,

    OPEN_ADD_OR_EDIT_LICENSE_MODAL,
    ADD_OR_EDIT_LICENSE_CHANGE_INPUT,

    START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE,
    INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS,
    RESET_SUBMIT_SAVE_LICENSE_MODAL,

    OPEN_EDIT_CPT_RCD_MODAL,
    EDIT_CPT_RCD_MODAL_CHANGE_INPUT,

    START_SUBMIT_EDIT_CPT_RCD,
    SUBMIT_EDIT_CPT_RCD_SUCCESS,
    SUBMIT_EDIT_CPT_RCD_FAILURE,
    INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS,
    RESET_EDIT_CPT_RCD_MODAL,


} from './LicensingConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';




function startLoadLicensingInfo() {
    return {
      type: START_LOAD_LICENSING_INFO,
    };
}
export function loadLicensingInfo(params) {
      return function(dispatch) {
        dispatch(startLoadLicensingInfo());
        httpClient
          .get(UrlConfig.LOAD_LICENSING_INFO, params)
          .then(resp => dispatch(loadLicensingInfoSuccess(resp)))
          .catch(err => dispatch(loadLicensingInfoFailure(err)));
      };
}
function loadLicensingInfoSuccess(resp) {
    return {
      type: LOAD_LICENSING_INFO_SUCCESS,
      payload: resp,
    };
}
function loadLicensingInfoFailure(err) {
    return {
        type: LOAD_LICENSING_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadLicensingInfoActionStatus() {
    return {
      type: INIT_LOAD_LICENSING_INFO_ACTION_STATUS
    };
}


export function selectLicensingData(array) {
    return {
        type: SELECT_LICENSING_DATA,
        payload: array,
    };
}


export function openAddOrEditLicenseModal(mode, licenseInfo) {
    return {
        type: OPEN_ADD_OR_EDIT_LICENSE_MODAL,
        payload: {
            mode,
            licenseInfo,
        },
    };
}
export function addOrEditLicenseChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_LICENSE_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}


function startAddOrEditLicenseEmpRequireRcd() {
    return {
        type: START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
    };
}
export function addOrEditLicenseEmpRequireRcd(params) {
      return function(dispatch) {
        dispatch(startAddOrEditLicenseEmpRequireRcd());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD, params)
          .then(resp => dispatch(addOrEditLicenseEmpRequireRcdSuccess(resp)))
          .catch(err => dispatch(addOrEditLicenseEmpRequireRcdFailure(err)));
      };
}
function addOrEditLicenseEmpRequireRcdSuccess(resp) {
    return {
        type: ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS,
        payload: resp,
    };
}
function addOrEditLicenseEmpRequireRcdFailure(err) {
    return {
        type: ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE,
        payload: err,
    };
}
export function initAddOrEditLicenseEmpRequireRcdActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS,
    };
}
export function resetSubmitSaveLicenseModal() {
    return {
        type: RESET_SUBMIT_SAVE_LICENSE_MODAL,
    };
}


export function openEditCptRcdModal(cptRcd, employee, licenseBasic) {
    return {
        type: OPEN_EDIT_CPT_RCD_MODAL,
        payload: {
            cptRcd,
            employee,
            licenseBasic,
        },
    };
}
export function editCptRcdModalChangeInput({name, value}) {
    return {
        type: EDIT_CPT_RCD_MODAL_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}


function startSubmitEditCptRcd() {
    return {
        type: START_SUBMIT_EDIT_CPT_RCD,
    };
}
export function submitEditCptRcd(rcd) {
    return function(dispatch) {
        dispatch(startSubmitEditCptRcd());
        httpClient
            .post(UrlConfig.SUBMIT_EDIT_CPT_RCD, rcd)
            .then(resp => dispatch(submitEditCptRcdSuccess(resp)))
            .catch(err => dispatch(submitEditCptRcdFailure(err)));
    };
}
function submitEditCptRcdSuccess(resp) {
    return {
        type: SUBMIT_EDIT_CPT_RCD_SUCCESS,
        payload: resp,
    };
}
function submitEditCptRcdFailure(err) {
    return {
        type: SUBMIT_EDIT_CPT_RCD_FAILURE,
        payload: err,
    };
}
export function initSubmitEditCptRcdActionStatus() {
    return {
        type: INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS,
    };
}
export function resetEditCptRcdModal() {
    return {
        type: RESET_EDIT_CPT_RCD_MODAL,
    }; 
}

