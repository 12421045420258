import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, Tooltip, message, Upload, Icon, Select, Button } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { localStorageClient } from '../../../../utils/localStorageClient';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};


class AddOrEditLicenseModalDialog extends Component {

    onInputChange = ({name, value}) => {
        this.props.addOrEditLicenseChangeInput({name, value});
    }


    attachmentHandleChange = (info) => {
        let fileList = [...info.fileList]; 
        fileList = fileList.slice(-1);
        this.props.addOrEditLicenseChangeInput({name: 'latestUploadFileList', value: fileList});
        if(!!fileList && fileList.length > 0) {
            const file = fileList[0];
            if (file.response) {
                const filename = file.response.data.fileNameWithTimeStamp;
                this.props.addOrEditLicenseChangeInput({name: 'latestUpload', value: filename});
            }
        }
    }


    submitSaveLicenseModal = () => {
        const {
            addOrEditLicenseModal,
        } = this.props;
        const {
            mode,
        } = addOrEditLicenseModal;
        const {
            guid,
            employee,
            licenseBasicInfo,
            licenseNo,
            regiStatus,
            licensedDate,
            examStatus,
            examDeadline,
            examLicenseInfo,
            latestUpload,
            historyUpload,
            remarks,
            feedback,
            comments,
        } = addOrEditLicenseModal.fields;
        const form = this.props.form;

        form.validateFields((err) => {
            if(!err) {
                Modal.confirm({
                    title: 'Are you sure to submit the changes, and notify Compliance Team by email?',
                    onOk: () => {
                        this.props.addOrEditLicenseEmpRequireRcd({
                            guid,
                            employee,
                            licenseBasicInfo,
                            licenseNo,
                            regiStatus,
                            licensedDate,
                            examStatus,
                            examDeadline,
                            examLicenseInfo,
                            latestUpload,
                            historyUpload,
                            remarks,
                            feedback,
                            comments,
                            notify: true,
                            mode,
                            source: 'Report',
                        });
                    }
                });
            }
        });
    }


    componentDidUpdate() {
        const {
            addOrEditLicenseModal,
            addOrEditLicenseEmpRequiredRcdStatus,
        } = this.props;

        if(addOrEditLicenseEmpRequiredRcdStatus === ACTION_STATUS.SUCCESS) {
            let opMode = addOrEditLicenseModal.mode === 'ADD' ? 'Add' : 'Edit';
            let msg = opMode + ' License Info succeeded';
            message.success(msg);
            this.props.initAddOrEditLicenseEmpRequireRcdActionStatus();
            this.props.resetSubmitSaveLicenseModal();
            const employee = localStorageClient.getUserName();
            this.props.loadLicensingInfo({employee});
        }
        if(addOrEditLicenseEmpRequiredRcdStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditLicenseEmpRequireRcdActionStatus();
        }

    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditLicenseModal,
            userAccountOptions,
            licenseBasicInfoArray,
            licensingExamCPTProofUploadUrl,
            addOrEditLicenseEmpRequiredRcdStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditLicenseModal;

        const {
            employee,
            licenseBasicInfo,
            licenseNo,
            regiStatus,
            licensedDate,
            examStatus,
            examDeadline,
            examLicenseInfo,
            latestUpload,
            historyUpload,
            remarks,
            feedback,
            comments,
            latestUploadFileList,
            historyUploadFileList,
        } = addOrEditLicenseModal.fields;


        const modalTitle = 'Edit License Record';
        const isSubmitLoading = addOrEditLicenseEmpRequiredRcdStatus === ACTION_STATUS.LOGINING;

        const historyUploadUrl = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_ATT_DOWNLOAD_URL + '/' + historyUpload;
        const historyUploadLink = <a href={historyUploadUrl} target="_blank">{historyUpload}</a>

        const licenseBasicInfoStr = licenseBasicInfo.country + ' / ' + licenseBasicInfo.regulator + ' / ' + licenseBasicInfo.license;


        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveLicenseModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveLicenseModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveLicenseModal() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Employee">
                                    {employee}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="License Type">
                                    {licenseBasicInfoStr}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="License No">
                                    {licenseNo}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Register Status">
                                    {regiStatus}
                                </FormItem>
                            </Col>
                        </Row>  
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Licensed Date">
                                    {licensedDate}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Exam Status">
                                    {examStatus}
                                </FormItem>
                            </Col>
                        </Row>  
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label={
                                    <span>
                                        Proof of Exam Results&nbsp;
                                        <Tooltip title={<div>{'Please compress all your Exam results, including the proofs submitted previously, into one single zip archive before upload. You may retrieve your previous records from "History Upload".'}
                                                            <br/>{'请将本年度所有考试证明（包括之前提交过的证明），打zip包后一次上传。提交过的证明可以从“History Upload”截取。'}</div>}>
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }>
                                {getFieldDecorator('latestUpload', {
                                    initialValue: latestUpload,
                                    rules: [{
                                        required: true,
                                        message: 'Please upload the Proof',
                                    }],
                                    })(
                                    <Upload
                                        name="latestUpload"
                                        withCredentials={true}
                                        multiple={false}
                                        fileList={latestUploadFileList}
                                        action={licensingExamCPTProofUploadUrl}
                                        onChange={this.attachmentHandleChange}
                                    >
                                        <Button style={{ width: 300, marginLeft: 10 }}>
                                            <Icon type="upload" />Upload
                                        </Button>
                                    </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Exam Deadline">
                                    {examDeadline}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="History Upload">
                                    {historyUploadLink}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label={
                                    <span>
                                        Exam Info&nbsp;
                                        <Tooltip title={<div>{'Please indicate the paper you have passed.'}
                                                            <br/>{'请写下您提交的证明是哪一份考卷。'}</div>}>
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }>
                                    {getFieldDecorator('examLicenseInfo', {
                                        initialValue: examLicenseInfo,
                                        rules: [{
                                            required: true,
                                            message: 'Please input Exam Info',
                                        }],
                                        })(
                                            <TextArea 
                                                style={{ width: 300, marginLeft: 10 }}
                                                rows={3} 
                                                value={examLicenseInfo} 
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'examLicenseInfo', value: e.target.value })
                                            }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                    <TextArea 
                                        style={{ width: 300, marginLeft: 10 }}
                                        rows={3} 
                                        value={remarks} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                </FormItem>
                                <FormItem {...formItemLayout} label="Feedback">
                                    {feedback}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <p>Please compress all your Exam results, including the proofs submitted previously, into one single zip archive before upload. 
                                    You may retrieve your previous records from "History Upload". And please indicate the paper you have passed in "Exam Info".</p>
                                <p>请将本年度所有考试证明（包括之前提交过的证明），打zip包后一次上传。提交过的证明可以从“History Upload”截取。请在“Exam Info”中写下您提交的证明是哪一份考卷。</p>
                            </Col>
                        </Row>
                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>

            </div>
        );
    }
}

const AddOrEditLicenseModalDialogForm = Form.create({ name: 'add_or_edit_license_modal_dialog' })(AddOrEditLicenseModalDialog);

export default AddOrEditLicenseModalDialogForm;
