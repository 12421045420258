import React, { Component } from 'react';
// import { AgGridReact } from "@ag-grid-community/react";
import { AgGridReact } from "ag-grid-react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';
class ExpenseSummaryNewGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: props.expenseSummaryNewGridColumns,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'item', headerName: 'Item', cellClass: 'non-number', width: 150},
                        {field: 'description', headerName: 'Description', cellClass: 'non-number', width: 120},
                        {field: 'status', headerName: 'status', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                        {field: 'remarks', headerName: 'Remarks', cellClass: 'non-number', width: 250},
                        {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 120},
                        {field: 'createTime', headerName: 'createTime', cellClass: 'non-number', width: 150},
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                    },
                    rowClassRules:{
                        'MarkBackgroundRed': function(params) {
                            const today = moment().format('YYYY-MM-DD');
                            return params.data.status === 'Pending' && params.data.deadline < today;
                        },
                        'MarkBackgroundGreen': function(params) {
                            const today = moment().format('YYYY-MM-DD');
                            return params.data.status === 'Pending' && params.data.deadline >= today;
                        },
                    },
                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.expenseSummaryInfoSubList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;"> Summary </div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },
        };
    }

    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let expenseSummaryNewInfoArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectExpenseSummaryNewInfo(expenseSummaryNewInfoArray);
    }

    render() {

        const {
            // query
            loadExpenseSummaryNewInfoActionStatus,
            loadExpenseSummaryNewInfoResp,
        } = this.props;


        const isLoading = loadExpenseSummaryNewInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadExpenseSummaryNewInfoArray = [];
        if(!!loadExpenseSummaryNewInfoResp && !!loadExpenseSummaryNewInfoResp.data) {
            loadExpenseSummaryNewInfoArray = loadExpenseSummaryNewInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            // columnDefs={this.state.columnDefs}
                            // rowData={loadExpenseSummaryNewInfoArray}
                            // defaultColDef={this.state.defaultColDef}
                            // rowSelection={this.state.rowSelection}
                            // // rowClassRules={this.state.rowClassRules}
                            // // suppressRowClickSelection={true}
                            // // suppressAggFuncInHeader={true}
                            // enableSorting={true}
                            // enableFilter={true}
                            // animateRows={true}
                            // deltaRowDataMode={false}
                            // enableColResize={true}
                            // enableRangeSelection={true}
                            // getRowNodeId={this.getRowNodeId}
                            // context={this.state.context}
                            // statusBar={this.state.statusBar}
                            // // event
                            // onSelectionChanged={this.onDataSelectionChanged}

                            // detailCellRendererParams={this.state.detailCellRendererParams}
                            // masterDetail={true}
                            // frameworkComponents={this.state.frameworkComponents}
                            // onGridReady={this.onGridReady}
                            // resizable={true}
                            // sideBar={true}





                            columnDefs={this.state.columnDefs}
                            rowData={loadExpenseSummaryNewInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // event
                            masterDetail={true}
                            sideBar={true}
                            resizable={true}
                            // onGridReady={this.onGridReady}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            onSelectionChanged={this.onDataSelectionChanged}

                        />
                    )}
            </div>
        );
    }
}

export default ExpenseSummaryNewGrid;
