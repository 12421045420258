import React from 'react';
import { createReducer } from '../../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';


import {
    OPEN_OPERATION_RECORD_DIALOG,
    START_QUERY_OPERATION_RECORD,
    QUERY_OPERATION_RECORD_SUCESS,
    QUERY_OPERATION_RECORD_FAILURE,
    INIT_QUERY_OPERATION_RECORD_STATUS,
    RESET_OPERATION_RECORD_DIALOG,
    // detail
    SHOW_DETAIL_RCD,
    RESET_DETAIL_RCD,
} from './OperationRecordConstants';


const initialState = {
    operationRecordDialogModal: {
        isOpened: false,
        queryFields: {
            module: '',
            dataId: '',
            operateTimeStart: '',
            operateTimeEnd: '',
        },
        customColumns: [],
        // detail
        isDetailOpened: false,
        detail: null,
    },
};



function openOperationRecordDialog(state, queryParams) {
    return {
        ...state,
        operationRecordDialogModal: {
            ...state.operationRecordDialogModal,
            isOpened: true,
            queryFields: queryParams.queryFields,
            customColumns: queryParams.customColumns,
        }
    }
}
function startQueryOperationRecord(state) {
    return {
        ...state,
        queryOperationRecordStatus: ACTION_STATUS.LOGINING,
    }
}
function queryOperationRecordSuccess(state, resp) {
    return {
        ...state,
        queryOperationRecordStatus: ACTION_STATUS.SUCCESS,
        queryOperationRecordResp: resp,
    }
}
function queryOperationRecordFailure(state, err) {
    return {
        ...state,
        queryOperationRecordStatus: ACTION_STATUS.ERROR,
        queryOperationRecordResp: err,
    }
}
function initQueryOperationRecordStatus(state) {
    return {
        ...state,
        queryOperationRecordStatus: ACTION_STATUS.READY,
    }
}
function resetOperationRecordDialog(state) {
    return {
        ...state,
        operationRecordDialogModal: initialState.operationRecordDialogModal,
    } 
}


// detail
function showDetailRcd(state, detail) {
    return {
        ...state,
        operationRecordDialogModal: {
            ...state.operationRecordDialogModal,
            isDetailOpened: true,
            detail: detail,
        }
    }
}
function resetDetailRcd(state) {
    return {
        ...state,
        operationRecordDialogModal: {
            ...state.operationRecordDialogModal,
            isDetailOpened: false,
            detail: null,
        }
    }
}


export default createReducer(initialState, {
    [OPEN_OPERATION_RECORD_DIALOG]: openOperationRecordDialog,
    [START_QUERY_OPERATION_RECORD]: startQueryOperationRecord,
    [QUERY_OPERATION_RECORD_SUCESS]: queryOperationRecordSuccess,
    [QUERY_OPERATION_RECORD_FAILURE]: queryOperationRecordFailure,
    [INIT_QUERY_OPERATION_RECORD_STATUS]: initQueryOperationRecordStatus,
    [RESET_OPERATION_RECORD_DIALOG]: resetOperationRecordDialog,
    // detail
    [SHOW_DETAIL_RCD]: showDetailRcd,
    [RESET_DETAIL_RCD]: resetDetailRcd,
});

