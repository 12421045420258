
const DIRECTION_TYPE_CODE = {
  LONG: 'LONG',
  SHORT: 'SHORT',
};

const DIRECTION_TYPE_LABEL = {
  LONG: 'Long',
  SHORT: 'Short',
};

export const DIRECTION_TYPE_MAP = [
  // Long
  {
    code: DIRECTION_TYPE_CODE.LONG,
    label: DIRECTION_TYPE_LABEL.LONG
  },
  // Sell
  {
    code: DIRECTION_TYPE_CODE.SHORT,
    label: DIRECTION_TYPE_LABEL.SHORT
  },
];