import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Icon, Upload} from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};


class AddOrEditExpenseSummaryNewModalDialog extends Component {

    refreshQuery() {
        const {
            category,
            invDateStart,
            invDateEnd,
            invNo,
            firm
        } = this.props.expenseSummaryNewQueryFields;
        this.props.loadExpenseSummaryNewInfo({category, invDateStart, invDateEnd, invNo, firm})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'Add Expense Summary Info';
        } else if(mode === 'EDIT') {
            return 'Edit Expense Summary Info';
        } else if(mode === 'COPY') {
            return 'Copy Expense Summary Info';
        } else if(mode === 'DEL') {
            return 'DEL Expense Summary Info';
        } else if(mode === 'APPROVE') {
            return 'APPROVE Expense Summary Info';
        } else if(mode === 'REJECT') {
            return 'REJECT Expense Summary Info';
        } else if(mode === 'CALLBACK') {
            return 'CALLBACK Expense Summary Info';
        }else if(mode === 'OLB') {
            return 'OLB Expense Summary Info';
        }else if(mode === 'COMPLETE') {
            return 'CALLBACK Expense Summary Info';
        }
    }


    componentDidUpdate() {
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditExpenseSummaryNewStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditExpenseSummaryNewModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditExpenseSummaryNewStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditExpenseSummaryNewModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditExpenseSummaryNewActionStatus();
            this.props.resetAddOrEditExpenseSummaryNewModal();
            this.refreshQuery();
        }
        if(addOrEditExpenseSummaryNewStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditExpenseSummaryNewActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditExpenseSummaryNewChangeInput({name, value});
        if(name === 'invDate' || name === 'ccy') {
            this.props.form.resetFields();
            let currInvDate = null;
            let currCcy = null;
            if(name === 'invDate') {
                currInvDate = value;
                currCcy = this.props.addOrEditExpenseSummaryNewModal.fields.ccy;
            } else {
                currInvDate = this.props.addOrEditExpenseSummaryNewModal.fields.invDate;
                currCcy = value;
            }
            this.props.refreshFx({currInvDate, currCcy});
        } else if (name === 'amount') {
            this.props.form.resetFields();
            const fx = this.props.addOrEditExpenseSummaryNewModal.fields.fx;
            this.props.refreshAmtUSD({currAmount: value, currFx: fx});
        }
    }


    submitAddOrEditExpenseSummaryNew = () => {
        const {
            addOrEditExpenseSummaryNewModal,
        } = this.props;
        const {
            mode,
        } = addOrEditExpenseSummaryNewModal;
        const {
            id,
            guid,
            category,
            billingEntity,
            payee,
            payer,
            frequency,
            description,
            approver,
            payInstruction,
            broker,
            invDate,
            invNo,
            ccy,
            amount,
            fx,
            amtUSD,
            payDate,
            remarks,
            comments,
            fileList,

        } = addOrEditExpenseSummaryNewModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditExpenseSummaryNew({
                    id,
                    guid,
                    category,
                    billingEntity,
                    payee,
                    payer,
                    frequency,
                    description,
                    approver,
                    payInstruction,
                    broker,
                    invDate,
                    invNo,
                    ccy,
                    amount,
                    fx,
                    amtUSD,
                    payDate,
                    remarks,
                    comments,
                    fileList,
                    mode,
                });                    
            }
        });
    }

    attachmentHandleChange = (info) => {
        let fileList = [...info.fileList]; 
        let attachment = '';
        let attachmentOriginName= '';
        let fail = false;
        fileList.forEach(file => {
          if (file.response) {
            if(file.response.respCode !== 'S0001') {
                let err = file.response.respMessage;
                message.error(err);
                fail = true;
                return;
              }
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            attachment = attachment + ";" + filename;
            attachmentOriginName = attachmentOriginName + ";" + originalFileName;
          }
        });

        this.props.addOrEditExpenseSummaryNewChangeInput({name: 'attachment', value: attachment});
        this.props.addOrEditExpenseSummaryNewChangeInput({name: 'attachmentOriginName', value: attachmentOriginName});

        let newFileList = fail ? [] : fileList;
        this.props.addOrEditExpenseSummaryNewChangeInput({name: 'fileList', value: newFileList});
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditExpenseSummaryNewModal,
            categoryOptions,
            fundFirmRelatedOptions,
            ccyOptions,
            frequencyOptions,
            instructionyOptions,
            approverOptions,
            payInstructionOptions,
            addOrEditExpenseSummaryNewStatus,
            monthlyReportAttachmentUploadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditExpenseSummaryNewModal;

        const {
            guid,
            category,
            billingEntity,
            payee,
            payer,
            frequency,
            description,
            approver,
            payInstruction,
            broker,
            invDate,
            invNo,
            ccy,
            amount,
            fx,
            amtUSD,
            payDate,
            remarks,
            comments,
            fileList,
        } = addOrEditExpenseSummaryNewModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const invDateMoment = !!invDate ? moment(invDate) : null;
        const payDateMoment = !!payDate ? moment(payDate) : null;
        const isSubmitLoading = addOrEditExpenseSummaryNewStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditExpenseSummaryNewModal()}
                    footer={[
                        <Button key="back" onClick={() => this.props.resetAddOrEditExpenseSummaryNewModal()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditExpenseSummaryNew}>
                          Submit
                        </Button>,
                      ]}
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Category">
                                {getFieldDecorator('category', {
                                    initialValue: category,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Category',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'category', value: val })
                                            }} 
                                            placeholder="Please select Category">
                                        {categoryOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Billing Entity">
                                {getFieldDecorator('billingEntity', {
                                    initialValue: billingEntity,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Billing Entity',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'billingEntity', value: val })
                                            }} 
                                            placeholder="Please select Billing Entity">
                                        {fundFirmRelatedOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Payee">
                                {getFieldDecorator('payee', {
                                    initialValue: payee,
                                    rules: [{
                                        required: true,
                                        message: 'Please input payee',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'payee', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Payer">
                                {getFieldDecorator('payer', {
                                    initialValue: payer,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Payer',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'payer', value: val })
                                            }} 
                                            placeholder="Please select Payer">
                                        {fundFirmRelatedOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Inv No.">
                                {getFieldDecorator('invNo', {
                                    initialValue: invNo,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Inv No.',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'invNo', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Inv Date">
                                {getFieldDecorator('invDate', {
                                    initialValue: invDateMoment,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Inv Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'invDate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                           
                           <Col span={12}>
                               <FormItem {...formItemLayout} label="CCY">
                               {getFieldDecorator('ccy', {
                                   initialValue: ccy,
                                   rules: [{
                                       required: true,
                                       message: 'Please select CCY',
                                   }],
                                   })(
                                   <Select
                                           allowClear
                                           style={{ width: 200, marginLeft: 10 }}
                                           onChange={(val) => {
                                               this.onInputChange({ name: 'ccy', value: val })
                                           }} 
                                           placeholder="Please select CCY">
                                       {ccyOptions}
                                   </Select>
                                   )}
                               </FormItem>
                           </Col>
                           <Col span={12}>
                                <FormItem {...formItemLayout} label="Amount">
                                {getFieldDecorator('amount', {
                                    initialValue: amount,
                                    rules: [{
                                        required: false,
                                        pattern: new RegExp(/^(0|[1-9][0-9]*)(\.[0-9]{1,2})?$/, "g"),
                                        message: 'Please input correct Amount',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'amount', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                       </Row>
                       <Row gutter={24}>
                            
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="FX">
                                {getFieldDecorator('fx', {
                                    initialValue: fx,
                                    rules: [{
                                        required: true,
                                        message: 'Please input FX',
                                    }],
                                    })(
                                    <Input
                                        disabled
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'fx', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="USD Amt">
                                {getFieldDecorator('amtUSD', {
                                    initialValue: amtUSD,
                                    rules: [{
                                        required: false,
                                        message: 'Please input USD Amt',
                                    }],
                                    })(
                                    <Input
                                        disabled
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'amtUSD', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>



                        

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Frequency">
                                {getFieldDecorator('frequency', {
                                    initialValue: frequency,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Frequency',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'frequency', value: val })
                                            }} 
                                            placeholder="Please select Frequency">
                                        {frequencyOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Description">
                                {getFieldDecorator('description', {
                                    initialValue: description,
                                    rules: [{
                                        required: true,
                                        message: 'Please input Description',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'description', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>                        

                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Approver">
                                {getFieldDecorator('approver', {
                                    initialValue: approver,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Approver',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'approver', value: val })
                                            }} 
                                            placeholder="Please select Approver">
                                        {approverOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Pay instruction">
                                {getFieldDecorator('payInstruction', {
                                    initialValue: payInstruction,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Pay instruction',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'payInstruction', value: val })
                                            }} 
                                            placeholder="Please select Pay instruction">
                                        {payInstructionOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Broker">
                                {getFieldDecorator('broker', {
                                    initialValue: broker,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Broker',
                                    }],
                                    })(
                                    <Input
                                        style={{ width: 200, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'broker', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Attachment">
                                  <Upload
                                    name="attachment"
                                    withCredentials={true}
                                    multiple={true}
                                    showUploadList={true}
                                    fileList={fileList}
                                    action={monthlyReportAttachmentUploadUrl}
                                    onChange={this.attachmentHandleChange}
                                  >
                                    <Button>
                                      <Icon type="upload" /> Upload Attachment
                                    </Button>
                                  </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                {getFieldDecorator('remarks', {
                                    initialValue: remarks,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Remarks',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Comments">
                                {getFieldDecorator('comments', {
                                    initialValue: comments,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Comments',
                                    }],
                                    })(
                                    <TextArea 
                                        style={{ width: 200, marginLeft: 10 }}
                                        rows={3} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        
                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditExpenseSummaryNewModalDialogForm = Form.create({ name: 'AddOrEditExpenseSummaryNewModalDialog' })(AddOrEditExpenseSummaryNewModalDialog);
export default AddOrEditExpenseSummaryNewModalDialogForm;
