// query
export const ON_REPORT_SUMMARY_QUERY_INPUT_CHANGE = 'ON_REPORT_SUMMARY_QUERY_INPUT_CHANGE';
export const START_LOAD_REPORTING_SUMMARY_INFO = 'START_LOAD_REPORTING_SUMMARY_INFO';
export const LOAD_REPORTING_SUMMARY_INFO_SUCCESS = 'LOAD_REPORTING_SUMMARY_INFO_SUCCESS';
export const LOAD_REPORTING_SUMMARY_INFO_FAILURE = 'LOAD_REPORTING_SUMMARY_INFO_FAILURE';
export const INIT_LOAD_REPORTING_SUMMARY_INFO_ACTION_STATUS = 'INIT_LOAD_REPORTING_SUMMARY_INFO_ACTION_STATUS';
// select
export const SELECT_REPORTING_SUMMRAY = 'SELECT_REPORTING_SUMMRAY';
// add or edit
export const OPEN_ADD_OR_EDIT_REPORTING_SUMMARY_MODAL = 'OPEN_ADD_OR_EDIT_REPORTING_SUMMARY_MODAL';
export const ADD_OR_EDIT_REPORTING_SUMMARY_CHANGE_INPUT = 'ADD_OR_EDIT_REPORTING_SUMMARY_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_REPORTING_SUMMARY_MODAL = 'RESET_SUBMIT_SAVE_REPORTING_SUMMARY_MODAL';
export const START_SUBMIT_SAVE_REPORTING_SUMMARY = 'START_SUBMIT_SAVE_REPORTING_SUMMARY';
export const SUBMIT_SAVE_REPORTING_SUMMARY_SUCCESS = 'SUBMIT_SAVE_REPORTING_SUMMARY_SUCCESS';
export const SUBMIT_SAVE_REPORTING_SUMMARY_FAILURE = 'SUBMIT_SAVE_REPORTING_SUMMARY_FAILURE';
export const INIT_SUBMIT_SAVE_REPORTING_SUMMARY_ACTION_STATUS = 'INIT_SUBMIT_SAVE_REPORTING_SUMMARY_ACTION_STATUS';
// batch change status
export const OPEN_BATCH_CHANGE_STATUS_MODAL = 'OPEN_BATCH_CHANGE_STATUS_MODAL';
export const RESET_BATCH_CHANGE_STATUS_MODAL = 'RESET_BATCH_CHANGE_STATUS_MODAL';
export const BATCH_CHANGE_STATUS_CHANGE_INPUT = 'BATCH_CHANGE_STATUS_CHANGE_INPUT';
export const START_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY = 'START_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY';
export const BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_SUCCESS = 'BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_SUCCESS';
export const BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_FAILURE = 'BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_FAILURE';
export const INIT_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_ACTION_STATUS = 'INIT_BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY_ACTION_STATUS';
// finish
export const SELECT_REPORTING_SUMMRAY_TASK = 'SELECT_REPORTING_SUMMRAY_TASK';
export const OPEN_FINISH_TASK_MODAL = 'OPEN_FINISH_TASK_MODAL';
export const RESET_FINISH_TASK_MODAL = 'RESET_FINISH_TASK_MODAL';
export const FINISH_TASK_CHANGE_INPUT = 'FINISH_TASK_CHANGE_INPUT';
export const START_FINISH_REPORTING_SUMMARY_TASK = 'START_FINISH_REPORTING_SUMMARY_TASK';
export const FINISH_REPORTING_SUMMARY_TASK_SUCCESS = 'FINISH_REPORTING_SUMMARY_TASK_SUCCESS';
export const FINISH_REPORTING_SUMMARY_TASK_FAILURE = 'FINISH_REPORTING_SUMMARY_TASK_FAILURE';
export const INIT_FINISH_REPORTING_SUMMARY_TASK_ACTION_STATUS = 'INIT_FINISH_REPORTING_SUMMARY_TASK_ACTION_STATUS';

