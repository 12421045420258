import {connect} from 'react-redux';
import GeneralAgreementDashboard from '../components/GeneralAgreementDashboard';

import {
    // query
    loadGeneralAgreementInfo,
    initLoadGeneralAgreementInfoActionStatus,
    // select
    selectGeneralAgreement,
    setGridEventOnState,
    // add or edit
    openAddOrEditGeneralAgreementModal,
    resetSubmitSaveGeneralAgreementModal,
    addOrEditGeneralAgreemenChangeInput,
    submitSaveGeneralAgreementModal,
    initSubmitSaveGeneralAgreementModalActionStatus,
    // change status
    openChangeGeneralAgreementStatusModal,
    resetChangeGeneralAgreementStatusModal,
    changeGeneralAgreemenStatusChangeInput,
    submitChangeGeneralAgreementStatusModal,
    initSubmitChangeGeneralAgreementStatusModalActionStatus,
    // showAllshowValid
    showAllShowValidBtnOnClicked,

} from '../GeneralAgreementActions';


const mapStateToProps = state => {
    return {
        // options
        departmentOptions: state.generalAgreement.departmentOptions,
        companyOptions: state.generalAgreement.companyOptions,
        agreementTypeOptions: state.generalAgreement.agreementTypeOptions,
        noticePeriodOptions: state.generalAgreement.noticePeriodOptions,
        ccyOptions: state.generalAgreement.ccyOptions,
        // upload/download
        generalAgreementUploadUrl: state.generalAgreement.generalAgreementUploadUrl,
        generalAgreementDownloadUrl: state.generalAgreement.generalAgreementDownloadUrl,
        // query
        generalAgreementGridEvent: state.generalAgreement.generalAgreementGridEvent,
        selectedGeneralAgreementArray: state.generalAgreement.selectedGeneralAgreementArray,
        loadGeneralAgreementActionStatus: state.generalAgreement.loadGeneralAgreementActionStatus,
        loadGeneralAgreementResp: state.generalAgreement.loadGeneralAgreementResp,
        // select
        selectedGeneralAgreementArray: state.generalAgreement.selectedGeneralAgreementArray,
        // add or edit
        addOrEditGeneralAgreementModal: state.generalAgreement.addOrEditGeneralAgreementModal,
        submitSaveGeneralAgreementActionStatus: state.generalAgreement.submitSaveGeneralAgreementActionStatus,
        // change status
        changeGeneralAgreementStatusModal: state.generalAgreement.changeGeneralAgreementStatusModal,
        submitChangeGeneralAgreementStatusActionStatus: state.generalAgreement.submitChangeGeneralAgreementStatusActionStatus,
        // showAllshowValid
        onlyShowValidGeneralAgreement: state.generalAgreement.onlyShowValidGeneralAgreement,
        
    };
};


const mapDispatchToProps = dispatch => {
    return {
        // query
        loadGeneralAgreementInfo: () => dispatch(loadGeneralAgreementInfo()),
        initLoadGeneralAgreementInfoActionStatus: () => dispatch(initLoadGeneralAgreementInfoActionStatus()),
        // select
        selectGeneralAgreement: (array) => dispatch(selectGeneralAgreement(array)),
        setGridEventOnState: (event) => dispatch(setGridEventOnState(event)),
        // add or edit
        openAddOrEditGeneralAgreementModal: (mode, data) => dispatch(openAddOrEditGeneralAgreementModal(mode, data)),
        resetSubmitSaveGeneralAgreementModal: () => dispatch(resetSubmitSaveGeneralAgreementModal()),
        addOrEditGeneralAgreemenChangeInput: (input) => dispatch(addOrEditGeneralAgreemenChangeInput(input)),
        submitSaveGeneralAgreementModal: (mode, bean) => dispatch(submitSaveGeneralAgreementModal(mode, bean)),
        initSubmitSaveGeneralAgreementModalActionStatus: () => dispatch(initSubmitSaveGeneralAgreementModalActionStatus()),
        // change status
        openChangeGeneralAgreementStatusModal: (mode, bean) => dispatch(openChangeGeneralAgreementStatusModal(mode, bean)),
        resetChangeGeneralAgreementStatusModal: () => dispatch(resetChangeGeneralAgreementStatusModal()),
        changeGeneralAgreemenStatusChangeInput: (param) => dispatch(changeGeneralAgreemenStatusChangeInput(param)),
        submitChangeGeneralAgreementStatusModal: (bean) => dispatch(submitChangeGeneralAgreementStatusModal(bean)),
        initSubmitChangeGeneralAgreementStatusModalActionStatus: () => dispatch(initSubmitChangeGeneralAgreementStatusModalActionStatus()),
        // showAllshowValid
        showAllShowValidBtnOnClicked: () => dispatch(showAllShowValidBtnOnClicked()),

    };
};


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(GeneralAgreementDashboard);

