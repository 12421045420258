import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker, message, Select, Button,Upload, Icon  } from 'antd';
import moment from 'moment';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import Table from 'braft-extensions/dist/table'
import 'braft-extensions/dist/table.css'
const confirm = Modal.confirm;
const FormItem = Form.Item;
const TextArea = Input.TextArea;




const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
let ref = "";

const blockImportFn = Table()[2].importer;
const blockExportFn = Table()[2].exporter;
class AddOrEditMeetingAndTrainingCalenderModalDialog extends Component {

    constructor(props){
        super(props);
        this.state={
            detailData:{
            }
        }
    }
    state = {
        editorState: null
    }
    refreshQuery() {
        const {
            userName,
            status
        } = this.props.meetingAndTrainingCalenderQueryFields;
        this.props.loadMeetingAndTrainingCalenderInfo({userName, status})
    }
    

    getPageNameByMode = (mode) => {
        if(mode === 'ADD') {
            return 'ADD Meeting ';
        } 
    }
    submitContent = async () => {

        const htmlContent = this.state.editorState.toHTML()

    }
    onInputChange = ({name, value}) => {
        const { detailData } = this.state;
        let updateData =  {
            ...detailData,
            [name]: value,
            // editorState: ref,
        };
        this.setState({
            detailData: updateData
        })
    }





    componentDidUpdate() {
        let {data} = this.props;
        const {
            refreshFxStatus,
            refreshFxResp,
            addOrEditMeetingAndTrainingCalenderStatus,
        } = this.props;
        if(refreshFxStatus === ACTION_STATUS.SUCCESS) {
            this.props.initRefreshFxActionStatus();
            const {
                amount,
                fx,
            } = this.props.addOrEditMeetingAndTrainingCalenderModal.fields;
            this.props.refreshAmtUSD({currAmount: amount, currFx: fx});
        }
        if(refreshFxStatus === ACTION_STATUS.ERROR) {
            let err = 'Load FxRate failed';
            if(!!refreshFxResp && !!refreshFxResp.respMessage) {
                err = refreshFxResp.respMessage;
            }
            message.error(err);
            this.props.initRefreshFxActionStatus();
        }
        if(addOrEditMeetingAndTrainingCalenderStatus === ACTION_STATUS.SUCCESS) {
            const mode = this.props.addOrEditMeetingAndTrainingCalenderModal.mode;
            const info = this.getPageNameByMode(mode) + ' succeeded';
            message.success(info);
            this.props.initAddOrEditMeetingAndTrainingCalenderActionStatus();
            this.props.resetAddOrEditMeetingAndTrainingCalenderModal();
            this.props.loadRestrictedPersonalList({broker:undefined})
            // this.refreshQuery();
        }
        if(addOrEditMeetingAndTrainingCalenderStatus === ACTION_STATUS.ERROR) {
            this.props.initAddOrEditMeetingAndTrainingCalenderActionStatus();
        }
    }



    submitAddOrEditMeetingAndTrainingCalender = () => {
        const {
            addOrEditMeetingAndTrainingCalenderModal,
        } = this.props;
        const {
            mode,
        } = addOrEditMeetingAndTrainingCalenderModal;
        const {
            id,
            analyst,
            type,
            researchdepth,
            stockpitch,
            note,
            broker,
            subject,
            evaluation,
          
        } = addOrEditMeetingAndTrainingCalenderModal.fields;

        const {
            detailData
        } = this.state;
        let data = {
            note:ref,
        };
        data = {...addOrEditMeetingAndTrainingCalenderModal.fields,...detailData};
        const form = this.props.form;
        const flage=0;
        let that = this;
        form.validateFields((err) => {
            if(!err) {
                this.props.addOrEditMeetingAndTrainingCalender({
                    id: data.id,
                    analyst: detailData.analyst,
                    type: detailData.type,
                    researchdepth,
                    stockpitch,
                    note: ref,
                    broker: detailData.broker,
                    subject: detailData.subject,
                    mode,
                    flage,
                    evaluation:detailData.evaluation,
                });                    
            }
        });

        if (mode=='EDIT'){
            confirm({
                title: 'Move to update Company Score?',
                content: '',
                onOk() {
                  
                    that.props.addOrEditMeetingAndTrainingCalender({
                    id: data.id,
                    mode,
                    flage: 1,
                });   
                window.open("https://ims.pinpointfund.com/research/14", "_blank");
                },

                onCancel() {},
              });
        }
        this.setState({
            detailData: {}
        });
        this.props.loadRestrictedPersonalList({undefined,undefined,undefined,undefined,undefined})
        this.props.loadRestrictedPersonalList({broker: undefined})

    }
    groupStructureUploadChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'chartFileList', value: []});
            this.onInputChange({name: 'uploadChartRealName', value: undefined});
            this.onInputChange({name: 'uploadChartOriName', value: undefined});
            return;
        }
        fileList = fileList.slice(-1);
        const file = fileList[0];
        this.onInputChange({name: 'chartFileList', value: fileList});
         if (!!file && !!file.response && !!file.response.data) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            this.onInputChange({name: 'uploadChartRealName', value: filename});
            this.onInputChange({name: 'uploadChartOriName', value: originalFileName});
        } else {
            if(!!file && !!file.response) {  // 有response才能判定失败，否则有可能正在上传中
                this.onInputChange({name: 'uploadChartRealName', value: undefined});
                this.onInputChange({name: 'uploadChartOriName', value: undefined});
                file.status = 'error';
                const errMsg = !!file.response.respMessage ? file.response.respMessage : 'Upload Failed';
                file.response = errMsg;
                message.error(errMsg);
            }
        }
    }
    handleEditorChange = (editorState) => {
        const htmlContent = editorState.toHTML();
        ref= editorState.toHTML();
    }
    closeMonthlyReportDialog = () =>{
        this.setState({
            detailData: {}
        })
        ref = ""
        this.props.resetAddOrEditMeetingAndTrainingCalenderModal();
    }
    render() {

        const options = {
            defaultColumns: 3, // 默认列数
            defaultRows: 3, // 默认行数
            withDropdown: true, // 插入表格前是否弹出下拉菜单
            columnResizable: true, // 是否允许拖动调整列宽，默认false
            exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
            includeEditors: ['editor-id-1'], // 指定该模块对哪些BraftEditor生效，不传此属性则对所有BraftEditor有效
            excludeEditors: ['editor-id-2'],  // 指定该模块对哪些BraftEditor无效
            exportAttrString: 'border="1" style="border-collapse: collapse"'
          }
          BraftEditor.use(Table(options))  

        const {
            detailData
        } = this.state;


        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditMeetingAndTrainingCalenderModal,
            userAccountOptions,
            typeOptions,
            evaluationOptions,
            brokerOptions,
            statusOptions,
            categoryOptions,
            fundFirmRelatedOptions,
            ccyOptions,
            addOrEditMeetingAndTrainingCalenderStatus,
            meetingAndTrainingCalenderUploadUrl,
            meetingAndTrainingCalenderDownloadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditMeetingAndTrainingCalenderModal;

        const {
            employee,
            usertype,
            analyst,
            subject,
            broker,
            type,
            researchdepth,
            stockpitch,
            note,
            username,
            evaluation,
            fundandcompany,
            validetodate,
            uploadChartOriName,
            uploadChartRealName,
            chartFileList,
            

        } = addOrEditMeetingAndTrainingCalenderModal.fields;

        const modalTitle = this.getPageNameByMode(mode);
        const isSubmitLoading = addOrEditMeetingAndTrainingCalenderStatus === ACTION_STATUS.LOGINING;
        const showRemark = mode === 'ADD';
        let data = {
            // month: moment().format('YYYY-MM'),
            // fileList: []
        };
        // data = op === 'ADD' ? {...data,...detailData} : {...monthlyReportDetail,...detailData};
        data = {...addOrEditMeetingAndTrainingCalenderModal.fields,...detailData};
        const editorState = ref === ""  ?  BraftEditor.createEditorState(data.note, { blockImportFn, blockExportFn }): BraftEditor.createEditorState(ref, { blockImportFn, blockExportFn });
       
        // const editorState = BraftEditor.createEditorState(addOrEditMeetingAndTrainingCalenderModal.fields.note, { blockImportFn, blockExportFn })
       
        const extendControlsContent = [
            {
              key: 'antd-uploader',
              type: 'component',
              component: (
                <Upload
                  accept="*"
                  showUploadList={false}
                >
                  <button type="button" className="control-item button upload-button" data-title="插入图片">
                    上传图片
                  </button>
                </Upload>
              ),
            },
        ];
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                   
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.closeMonthlyReportDialog()}
                    footer={[
                        <Button key="back" onClick={() => this.closeMonthlyReportDialog()}>
                          Close
                        </Button>,
                        <Button key="save" type="primary" loading={isSubmitLoading} onClick={this.submitAddOrEditMeetingAndTrainingCalender}>
                          Submit
                        </Button>,
                      ]}
                >
                <Form layout="horizontal" required={false}>
                    {
                     !showRemark && 
                     <div>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="type" required>
                                    {getFieldDecorator('type', {
                                    initialValue: data.type,
                                    rules: [{
                                        required: true,
                                        message: 'Please select type',
                                    }],
                                    })(
                                        <Select
                                                allowClear
                                                showSearch
                                                style={{ width: 200, marginLeft: 10 }}
                                                onChange={(val) => {
                                                    this.onInputChange({ name: 'type', value: val })
                                                }} 
                                                placeholder="Please select type">
                                            {typeOptions}
                                        </Select>
                                    )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={24} >
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="analyst" required>
                                    {getFieldDecorator('analyst', {
                                        initialValue: data.analyst,
                                        rules: [{
                                            required: true,
                                            message: 'Please input analyst*',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 200, marginLeft: 10 }}
                                            // value={data.analyst}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'analyst', value: e.target.value })
                                            }}
                                        />
                                        )}
                                    </FormItem>
                                </Col>

                            </Row>
                            <Row gutter={24} >
                                <Col span={24}>
                                <FormItem {...formItemLayout} label="broker" required>
                                {getFieldDecorator('broker', {
                                        initialValue: data.broker,
                                        rules: [{
                                            required: true,
                                            message: 'Please input broker*',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'broker', value: val })
                                            }} 
                                            placeholder="Please select broker">
                                            {brokerOptions}
                                        </Select>)}
                                    </FormItem>           
                                </Col>
                            </Row>
                        <Row gutter={12}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="evaluation">
                                {getFieldDecorator('stockpitch', {
                                    initialValue: data.evaluation,
                                    rules: [{
                                        required: false,
                                        message: 'Please input Stock Pitch',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'evaluation', value: val })
                                            }} 
                                            placeholder="Please select evaluation">
                                        {evaluationOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="note">
                                    {
                                    <BraftEditor
                                    id="editor-id-1"
                                    value={editorState}
                                    onChange={this.handleEditorChange}
                                    onSave={this.submitContent}
                                    extendControls={extendControlsContent}
                                    /> } 
                                </FormItem>
                            </Col>
                        </Row> 




                        </div>
                    }
                        {
                        showRemark && 
                        <div>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="subject" required>
                                        <Input
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'subject', value: e.target.value })
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="broker" required>
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'broker', value: val })
                                            }} 
                                            placeholder="Please select broker">
                                            {brokerOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>   
                        </div>
                    }               
                        
                        
                </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditMeetingAndTrainingCalenderModalDialogForm = Form.create({ name: 'AddOrEditMeetingAndTrainingCalenderModalDialog' })(AddOrEditMeetingAndTrainingCalenderModalDialog);
export default AddOrEditMeetingAndTrainingCalenderModalDialogForm;
