import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import GridColumnMap from './GridColumnMap';

import {

    START_LOAD_LICENSING_INFO,
    LOAD_LICENSING_INFO_SUCCESS,
    LOAD_LICENSING_INFO_FAILURE,
    INIT_LOAD_LICENSING_INFO_ACTION_STATUS,

    SELECT_LICENSING_DATA,

    OPEN_ADD_OR_EDIT_LICENSE_MODAL,
    ADD_OR_EDIT_LICENSE_CHANGE_INPUT,

    START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS,
    ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE,
    INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS,
    RESET_SUBMIT_SAVE_LICENSE_MODAL,

    OPEN_EDIT_CPT_RCD_MODAL,
    EDIT_CPT_RCD_MODAL_CHANGE_INPUT,

    START_SUBMIT_EDIT_CPT_RCD,
    SUBMIT_EDIT_CPT_RCD_SUCCESS,
    SUBMIT_EDIT_CPT_RCD_FAILURE,
    INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS,
    RESET_EDIT_CPT_RCD_MODAL,


} from './LicensingConstants';

const Option = Select.Option;

const initialState = {
    selectedLicenseArray: [],
    loadLicensingInfoResp: [],

    licensingGridColumns: GridColumnMap.licensingGridColumns,
    licensingCPTYearRcdGridColumns: GridColumnMap.licensingCPTYearRcdGridColumns,

    examResultAttBaseUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_ATT_DOWNLOAD_URL,
    licensingExamCPTProofUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_EXAM_CPT_PROOF_UPLOAD_URL,

    addOrEditLicenseModal: {
        isOpened: false,
        mode: 'EDIT',
        respErrMsg: '',
        fields: {
            guid: '',
            employee: '',
            licenseBasicInfo: {guid: '',},
            licenseNo: '',
            regiStatus: '',
            licensedDate: '',
            examStatus: '',
            examDeadline: '',
            examLicenseInfo: '',
            latestUpload: '',
            historyUpload: '',
            remarks: '',
            feedback: '',
            comments: '',
            latestUploadFileList: [],
            historyUploadFileList: [],
        },
    },

    editCptRcdModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            guid: '',
            employee: '',
            licenseBasic: '',
            year: '',
            licenseEmpRequireRcd: {guid: '',},
            cptHourRequire: '',
            cptHourFinished: '',
            cptHourAccepted: '',
            trainingInfo: '',
            latestUpload: '',
            historyUpload: '',
            remarks: '',
            feedback: '',
            comments: '',
            latestUploadFileList: [],
            historyUploadFileList: [],
        },
    },

}



function startLoadLicensingInfo(state) {
    return {
      ...state,
      loadLicensingInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadLicensingInfoSuccess(state, resp) {
    return {
        ...state,
        loadLicensingInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadLicensingInfoResp: resp,
    };
}
function loadLicensingInfoFailure(state, err) {
    return {
        ...state,
        loadLicensingInfoActionStatus: ACTION_STATUS.ERROR,
        loadLicensingInfoResp: err,
    };
}
function initLoadLicensingInfoActionStatus(state) {
    return {
        ...state,
        loadLicensingInfoActionStatus: ACTION_STATUS.READY,
        selectedLicenseArray: [],
    };
}


function selectLicensingData(state, array) {
    return {
        ...state,
        selectedLicenseArray: array,
    };
}


function openAddOrEditLicenseModal(state, {mode, licenseInfo}) {
    const latestUploadFileList = [];
    const latestUpload = licenseInfo.latestUpload;
    if(!!latestUpload) {
        const file = {
            uid: '-1',
            name: latestUpload,
            status: 'done',
            url: state.examResultAttBaseUrl + "/" + latestUpload,
            response: {
                respCode: "S0001",
                latestUpload,
            }
        };
        latestUploadFileList.push(file);
    }

    const historyUploadFileList = [];
    const historyUpload = licenseInfo.historyUpload;
    if(!!historyUpload) {
        const file = {
            uid: '-1',
            name: historyUpload,
            status: 'done',
            url: state.examResultAttBaseUrl + "/" + historyUpload,
            response: {
                respCode: "S0001",
                historyUpload,
            }
        };
        historyUploadFileList.push(file);
    }

    return {
        ...state,
        addOrEditLicenseModal: {
            ...state.addOrEditLicenseModal,
            isOpened: true,
            mode,
            fields: {
                ...state.addOrEditLicenseModal.fields,
                guid: licenseInfo.guid,
                employee: licenseInfo.employee,
                licenseBasicInfo: licenseInfo.licenseBasicInfo,
                licenseNo: licenseInfo.licenseNo,
                regiStatus: licenseInfo.regiStatus,
                licensedDate: licenseInfo.licensedDate,
                examStatus: licenseInfo.examStatus,
                examDeadline: licenseInfo.examDeadline,
                examLicenseInfo: licenseInfo.examLicenseInfo,
                latestUpload: licenseInfo.latestUpload,
                historyUpload: licenseInfo.historyUpload,
                remarks: licenseInfo.remarks,
                feedback: licenseInfo.feedback,
                latestUploadFileList: latestUploadFileList,
                historyUploadFileList: historyUploadFileList,
            }
        },
    }
}
function addOrEditLicenseChangeInput(state, {name, value}) {
    let updatedFields = null;
    if(name === 'licenseBasicInfoGuid') {
        updatedFields = {
            ...state.addOrEditLicenseModal.fields,
            licenseBasicInfo: {
                guid: value,
            },
        };
    } else {
        updatedFields = {
            ...state.addOrEditLicenseModal.fields,
            [name]: value,
        };
    }
    return {
        ...state,
        addOrEditLicenseModal: {
          ...state.addOrEditLicenseModal,
          fields: updatedFields,
        },
    };
}



function startAddOrEditLicenseEmpRequiredRcd(state) {
    return {
      ...state,
      addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditLicenseEmpRequiredRcdSuccess(state, resp) {
    return {
        ...state,
        addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditLicenseEmpRequiredRcdFailure(state, err) {
    return {
        ...state,
        addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.ERROR,
        addOrEditLicenseModal: {
            ...state.addOrEditLicenseModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditLicenseEmpRequiredRcdActionStatus(state) {
    return {
        ...state,
        addOrEditLicenseEmpRequiredRcdStatus: ACTION_STATUS.READY,
    };
}
function resetSubmitSaveLicenseModal(state) {
    return {
        ...state,
        addOrEditLicenseModal: initialState.addOrEditLicenseModal,
        selectedLicenseArray: [],
    }
}



function openEditCptRcdModal(state, payload) {
    const latestUploadFileList = [];
    const latestUpload = payload.cptRcd.latestUpload;
    if(!!latestUpload) {
        const file = {
            uid: '-1',
            name: latestUpload,
            status: 'done',
            url: state.examResultAttBaseUrl + "/" + latestUpload,
            response: {
                respCode: "S0001",
                latestUpload,
            }
        };
        latestUploadFileList.push(file);
    }

    const historyUploadFileList = [];
    const historyUpload = payload.cptRcd.historyUpload;
    if(!!historyUpload) {
        const file = {
            uid: '-1',
            name: historyUpload,
            status: 'done',
            url: state.examResultAttBaseUrl + "/" + historyUpload,
            response: {
                respCode: "S0001",
                historyUpload,
            }
        };
        historyUploadFileList.push(file);
    }

    return {
        ...state,
        editCptRcdModal: {
            ...state.editCptRcdModal,
            isOpened: true,
            fields: {
                ...state.editCptRcdModal.fields,
                guid: payload.cptRcd.guid,
                employee: payload.employee,
                licenseBasic: payload.licenseBasic,
                year: payload.cptRcd.year,
                licenseEmpRequireRcd: payload.cptRcd.licenseEmpRequireRcd,
                cptHourRequire: payload.cptRcd.cptHourRequire,
                cptHourFinished: payload.cptRcd.cptHourFinished,
                cptHourAccepted: payload.cptRcd.cptHourAccepted,
                trainingInfo: payload.cptRcd.trainingInfo,
                latestUpload: payload.cptRcd.latestUpload,
                historyUpload: payload.cptRcd.historyUpload,
                remarks: payload.cptRcd.remarks,
                feedback: payload.cptRcd.feedback,
                comments: payload.cptRcd.comments,
                latestUploadFileList: latestUploadFileList,
                historyUploadFileList: historyUploadFileList,
            },
        },
    };
}
function editCptRcdModalChangeInput(state, {name, value}) {
    return {
        ...state,
        editCptRcdModal: {
            ...state.editCptRcdModal,
            fields: {
                ...state.editCptRcdModal.fields,
                [name]: value,
            },
        },
    };
}



function startSubmitEditCptRcd(state) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.LOGINING,
      };
}
function submitEditCptRcdSuccess(state, resp) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitEditCptRcdFailure(state, err) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.ERROR,
        editCptRcdModal: {
            ...state.editCptRcdModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitEditCptRcdActionStatus(state) {
    return {
        ...state,
        submitEditCptRcdStatus: ACTION_STATUS.READY,
    };
}
function resetEditCptRcdModal(state) {
    return {
        ...state,
        editCptRcdModal: initialState.editCptRcdModal,
        selectedLicenseArray: [],
    }
}



export default createReducer(initialState, {

    [START_LOAD_LICENSING_INFO]: startLoadLicensingInfo,
    [LOAD_LICENSING_INFO_SUCCESS]: loadLicensingInfoSuccess,
    [LOAD_LICENSING_INFO_FAILURE]: loadLicensingInfoFailure,
    [INIT_LOAD_LICENSING_INFO_ACTION_STATUS]: initLoadLicensingInfoActionStatus,

    [SELECT_LICENSING_DATA]: selectLicensingData,

    [OPEN_ADD_OR_EDIT_LICENSE_MODAL]: openAddOrEditLicenseModal,
    [ADD_OR_EDIT_LICENSE_CHANGE_INPUT]: addOrEditLicenseChangeInput,

    [START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD]: startAddOrEditLicenseEmpRequiredRcd,
    [ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS]: addOrEditLicenseEmpRequiredRcdSuccess,
    [ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE]: addOrEditLicenseEmpRequiredRcdFailure,
    [INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS]: initAddOrEditLicenseEmpRequiredRcdActionStatus,
    [RESET_SUBMIT_SAVE_LICENSE_MODAL]: resetSubmitSaveLicenseModal,

    [OPEN_EDIT_CPT_RCD_MODAL]: openEditCptRcdModal,
    [EDIT_CPT_RCD_MODAL_CHANGE_INPUT]: editCptRcdModalChangeInput,

    [START_SUBMIT_EDIT_CPT_RCD]: startSubmitEditCptRcd,
    [SUBMIT_EDIT_CPT_RCD_SUCCESS]: submitEditCptRcdSuccess,
    [SUBMIT_EDIT_CPT_RCD_FAILURE]: submitEditCptRcdFailure,
    [INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS]: initSubmitEditCptRcdActionStatus,
    [RESET_EDIT_CPT_RCD_MODAL]: resetEditCptRcdModal,

});
