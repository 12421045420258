import React, {Component} from 'react';
import { Breadcrumb, Select, DatePicker, Button, message, Input,Divider } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import './ExpenseSummaryNewDashboard.css';
import ExpenseSummaryNewGrid from './ExpenseSummaryNewGrid';
import AddOrEditExpenseSummaryNewModalDialog from './AddOrEditExpenseSummaryNewModalDialog';
import DelExpenseSummaryNewModalDialog from './DelExpenseSummaryNewModalDialog';
// import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import { localStorageClient } from '../../../../utils/localStorageClient';

class ExpenseSummaryNewDashboard extends Component {
    componentDidMount() {

    }


    componentDidUpdate() {
        const {
            // query
            loadExpenseSummaryNewInfoActionStatus,
            loadExpenseSummaryNewInfoResp,
        } = this.props;

        if(loadExpenseSummaryNewInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadExpenseSummaryNewInfoActionStatus();
        }
        if(loadExpenseSummaryNewInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load Expense Summary Info failed';
            if(!!loadExpenseSummaryNewInfoResp && !!loadExpenseSummaryNewInfoResp.respMessage) {
                err = loadExpenseSummaryNewInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadExpenseSummaryNewInfoActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    addOrEditBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditExpenseSummaryNewModal(mode, null);
        } else if (mode === 'EDIT'){
            const {
                selectedExpenseSummaryNewInfoArray,
            } = this.props;
            if(selectedExpenseSummaryNewInfoArray.length !== 1) {
                message.warning("Please choose one and only one Expense Summary Info");
                return;
            }
            if(selectedExpenseSummaryNewInfoArray[0].payDate!==null) {
                message.warning("payDate is not null ,You cannot edit completed payments ");
                return;
            }

            const currentExpenseSummaryNewInfo = selectedExpenseSummaryNewInfoArray[0];
            this.props.openAddOrEditExpenseSummaryNewModal(mode, currentExpenseSummaryNewInfo);

        }else {
            const {
                selectedExpenseSummaryNewInfoArray,
            } = this.props;
            if(selectedExpenseSummaryNewInfoArray.length !== 1) {
                message.warning("Please choose one and only one Expense Summary Info");
                return;
            }
            const currentExpenseSummaryNewInfo = selectedExpenseSummaryNewInfoArray[0];
            this.props.openAddOrEditExpenseSummaryNewModal(mode, currentExpenseSummaryNewInfo);
        }
    }

    delBtnOnClicked = (mode) => {
        const {
            selectedExpenseSummaryNewInfoArray,
        } = this.props;
        if(selectedExpenseSummaryNewInfoArray.length !== 1) {
            message.warning("Please choose one and only one Expense Summary Info");
            return;
        }
        
        if(mode ==='DEL' && selectedExpenseSummaryNewInfoArray[0].approvalStatus !=='Unprocessed') {
            message.warning("You cannot delete processing/processed/completed entries.");
            return;
        }
        const userName = localStorageClient.getUserName();


        if(mode ==='APPROVE' || mode ==='REJECT' ) {
            if(selectedExpenseSummaryNewInfoArray[0].approvalStatus !=='PendingApproval' && selectedExpenseSummaryNewInfoArray[0].approver !==userName){
            message.warning("You check  approvalStatus='PendingApproval' and approver is userName ");
            return;
            }
        }

        const currentExpenseSummaryNewInfo = selectedExpenseSummaryNewInfoArray[0];
        this.props.openDelExpenseSummaryNewModal(currentExpenseSummaryNewInfo,mode);
    }




    refreshBtnOnClicked = () => {
        const employee = localStorageClient.getUserName();
        const {
            category,
            invDateStart,
            invDateEnd,
            invNo,
            firm
        } = this.props.expenseSummaryNewQueryFields;
        this.props.loadExpenseSummaryNewInfo({category, invDateStart, invDateEnd, invNo, firm,employee,source: 'Report',})
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {

        const {
            categoryOptions,
            expenseSummaryNewQueryFields,
            loadExpenseSummaryNewInfoActionStatus,
        } = this.props;

        const {
            category,
            invDateStart,
            invDateEnd,
        } = expenseSummaryNewQueryFields;

        const refreshBtnIsLoading = loadExpenseSummaryNewInfoActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='ExpenseSummaryNewWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Expense Summary</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'category', value: val })
                        }} 
                        placeholder="Category">
                        {categoryOptions}
                    </Select>
                    <Input 
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="invNo"
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'invNo', value: event.target.value })
                        }} 
                    />
                    {/* <Input 
                        placeholder="firm"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'firm', value: event.target.value })
                        }} 
                    />
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date Start"
                      onChange={(invDateStart, dateString) => {
                        this.onInputChange({ name: 'invDateStart', value: dateString });
                      }}
                    />
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Inv Date End"
                      onChange={(invDateEnd, dateString) => {
                        this.onInputChange({ name: 'invDateEnd', value: dateString });
                      }}
                    /> */}
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('ADD')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('COPY')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked('DEL')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked('SUBMIT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Submit
                    </Button>
                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.delBtnOnClicked('APPROVE')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 1 }}
                        className='positive-button'>
                        Approve
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked('REJECT')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negative-button'>
                        Reject
                    </Button>
                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.delBtnOnClicked('CALLBACK')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 1 }}
                        className='negativeeee-button'>
                        CallBack
                    </Button>
                    <Button 
                        onClick={()=>this.delBtnOnClicked('OLB')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        className='negativeeee-button'>
                        OLB
                    </Button>
                    <Divider type='vertical' />
                    <Button 
                        onClick={()=>this.delBtnOnClicked('COMPLETE')} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 1 }}
                        className='negativwww-button'>
                        Complete
                    </Button>
                    {/* <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'ExpenseSummaryNew'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        style={{ fontSize: 10, marginLeft: 10 }}
                        type='primary'>
                        Operation Record
                    </Button> */}
                </div>

                <div className='ExpenseSummaryNewGrid'><ExpenseSummaryNewGrid {...this.props} /></div>
                <AddOrEditExpenseSummaryNewModalDialog {...this.props} />
                <DelExpenseSummaryNewModalDialog {...this.props} />
                {/* <OperationRecordDialog {...this.props} /> */}

            </div>
        );
    }
}

export default ExpenseSummaryNewDashboard;
