import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class ChangeGeneralAgreementStatusModalDialog extends Component {

    refreshBtnOnClicked = () => {
        this.props.loadGeneralAgreementInfo();
    }

    componentDidUpdate() {
        const {
            submitChangeGeneralAgreementStatusActionStatus,
        } = this.props;
        const {
            mode,
        } = this.props.changeGeneralAgreementStatusModal;

        if(submitChangeGeneralAgreementStatusActionStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' General Agreement succeeded.';
            message.success(msg);
            this.props.initSubmitChangeGeneralAgreementStatusModalActionStatus();
            this.props.resetChangeGeneralAgreementStatusModal();
            this.refreshBtnOnClicked();
        }
        if(submitChangeGeneralAgreementStatusActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitChangeGeneralAgreementStatusModalActionStatus();
        }
    }

    getActionFromMode(mode) {
        if(mode === 'DEL') {
            return 'Delete';
        } else if(mode === 'SUBMIT') {
            return 'Submit';
        } else {
            return 'Expire';
        }
    }

    onInputChange = ({name, value}) => {
        this.props.changeGeneralAgreemenStatusChangeInput({name, value});
    }


    submitChangeGeneralAgreementStatusModal = () => {
        const {
            mode,
        } = this.props.changeGeneralAgreementStatusModal;
        const {
            id,
            status,
            title,
            department,
            personInCharge,
            company,
            counterparty,
            agreementType,
            executionDate,
            expiryDate,
            noticePeriod,
            terms,
            uploadOriName,
            uploadRealName,
            amount,
            amountCCY,
            payer,
            remarks,
            comments,
            source,
        } = this.props.changeGeneralAgreementStatusModal.fields;

        // Report端只能DEL New状态的
        if(mode === 'DEL' && status !== 'New') {
            message.warn('Only the General Agreements in New status can be Deleted');
            return;
        }
        // 只有 New、Rejected、Valid状态的可以SUBMIT
        if(mode === 'SUBMIT' && status !== 'New' && status !== 'Rejected' && status !== 'Valid') {
            message.warn('Only the General Agreements in New, Rejected or Valid status can be Submitted');
            return;
        }
        // 只有Valid状态的可以EXPIRE
        if(mode === 'EXPIRE' && status !== 'Valid') {
            message.warn('Only the General Agreements in Valid status can be Expired');
            return;
        }

        Modal.confirm({
            title: 'Are you sure to ' + this.getActionFromMode(mode) + ' this General Agreement?',
            onOk: () => {
                this.props.submitChangeGeneralAgreementStatusModal({
                    id,
                    status,
                    title,
                    department,
                    personInCharge,
                    company,
                    counterparty,
                    agreementType,
                    executionDate,
                    expiryDate,
                    noticePeriod,
                    terms,
                    uploadOriName,
                    uploadRealName,
                    amount,
                    amountCCY,
                    payer,
                    remarks,
                    comments,
                    source,
                    modes: mode,
                });
            }
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            changeGeneralAgreementStatusModal,
            submitChangeGeneralAgreementStatusActionStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = changeGeneralAgreementStatusModal;

        const {
            id,
            remarks,
            comments,
        } = changeGeneralAgreementStatusModal.fields;

        const modalTitle = this.getActionFromMode(mode) + ' General Agreement';
        const isSubmitLoading = submitChangeGeneralAgreementStatusActionStatus === ACTION_STATUS.LOGINING;

        return(
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={600}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetChangeGeneralAgreementStatusModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetChangeGeneralAgreementStatusModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitChangeGeneralAgreementStatusModal() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='Please input remarks' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>

                        </Row>

                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }

}

const ChangeGeneralAgreementStatusModalDialogForm = Form.create({ name: 'ChangeGeneralAgreementStatusModalDialog' })(ChangeGeneralAgreementStatusModalDialog);
export default ChangeGeneralAgreementStatusModalDialogForm;

