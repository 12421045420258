import {connect} from 'react-redux';
import ReportingSummaryDashboard from '../components/ReportingSummaryDashboard';


import {
    // query
    onQueryInputChange,
    loadReportingSummaryInfo,
    initLoadReportingSummaryInfoActionStatus,
    // select
    selectReportingSummary,
    // add or edit
    openAddOrEditReportingSummaryModal,
    addOrEditReportingSummaryChangeInput,
    resetSubmitSaveReportingSummaryModal,
    submitSaveReportingSummary,
    submitSaveReportingSummaryFailure,
    initSubmitSaveReportingSummaryActionStatus,
    // batch change status
    openBatchChangeStatusModal,
    resetBatchChangeStatusModal,
    batchChangeStatusChangeInput,
    batchChangeStatusForReportingSummary,
    initBatchChangeStatusForReportingSummaryActionStatus,
    // finish
    selectReportingSummaryTask,
    openFinishTaskModal,
    resetFinishTaskModal,
    finishTaskChangeInput,
    finishReportingSummaryTask,
    initFinishReportingSummaryTaskActionStatus,

} from '../ReportingSummaryActions';

// import {
//     // operationRcd
//     doOperationRecordAction,
// } from '../../../common/operationRcd/OperationRecordActions';

// import {
//     queryPendingTasksNum,
// } from '../../pendingTasks/PendingTasksActions'


const mapStateToProps = state => {
    return {
        // basic options
        locationOptions: state.reportingSummary.locationOptions,
        fundCompanyOptions: state.reportingSummary.fundCompanyOptions,
        frequencyOptions: state.reportingSummary.frequencyOptions,
        weekdayOptions: state.reportingSummary.weekdayOptions,
        monthOptions: state.reportingSummary.monthOptions,
        responsibleDeptOptions: state.reportingSummary.responsibleDeptOptions,
        // query
        reportingSummaryQueryFields: state.reportingSummary.reportingSummaryQueryFields,
        loadReportingSummaryInfoActionStatus: state.reportingSummary.loadReportingSummaryInfoActionStatus,
        loadReportingSummaryInfoResp: state.reportingSummary.loadReportingSummaryInfoResp,
        // select
        selectedReportingSummaryArray: state.reportingSummary.selectedReportingSummaryArray,
        // add or edit
        addOrEditReportingSummaryModal: state.reportingSummary.addOrEditReportingSummaryModal,
        addOrEditReportingSummaryStatus: state.reportingSummary.addOrEditReportingSummaryStatus,
        // batch change status
        batchChangeStatusModal: state.reportingSummary.batchChangeStatusModal,
        batchChangeStatusForReportingSummaryStatus: state.reportingSummary.batchChangeStatusForReportingSummaryStatus,
        // finish
        selectedReportingSummaryTaskArray: state.reportingSummary.selectedReportingSummaryTaskArray,
        finishTaskModal: state.reportingSummary.finishTaskModal,
        finishReportingSummaryTaskStatus: state.reportingSummary.finishReportingSummaryTaskStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,
            // user account
        userAccountEmailOptions: state.reportingSummary.userAccountEmailOptions,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadReportingSummaryInfo: (params) => dispatch(loadReportingSummaryInfo(params)),
        initLoadReportingSummaryInfoActionStatus: () => dispatch(initLoadReportingSummaryInfoActionStatus()),
        // select
        selectReportingSummary: (array) => dispatch(selectReportingSummary(array)),
        // add or edit
        openAddOrEditReportingSummaryModal: (mode, data) => dispatch(openAddOrEditReportingSummaryModal(mode, data)),
        addOrEditReportingSummaryChangeInput: (data) => dispatch(addOrEditReportingSummaryChangeInput(data)),
        resetSubmitSaveReportingSummaryModal: () => dispatch(resetSubmitSaveReportingSummaryModal()),
        submitSaveReportingSummary: (bean) => dispatch(submitSaveReportingSummary(bean)),
        submitSaveReportingSummaryFailure: (err) => dispatch(submitSaveReportingSummaryFailure(err)),
        initSubmitSaveReportingSummaryActionStatus: () => dispatch(initSubmitSaveReportingSummaryActionStatus()),
        // batch change status
        openBatchChangeStatusModal: (mode, idList) => dispatch(openBatchChangeStatusModal(mode, idList)),
        resetBatchChangeStatusModal: () => dispatch(resetBatchChangeStatusModal()),
        batchChangeStatusChangeInput: (data) => dispatch(batchChangeStatusChangeInput(data)),
        batchChangeStatusForReportingSummary: (params) => dispatch(batchChangeStatusForReportingSummary(params)),
        initBatchChangeStatusForReportingSummaryActionStatus: () => dispatch(initBatchChangeStatusForReportingSummaryActionStatus()),
        // finish
        selectReportingSummaryTask: (array) => dispatch(selectReportingSummaryTask(array)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),
        resetFinishTaskModal: () => dispatch(resetFinishTaskModal()),
        finishTaskChangeInput: (param) => dispatch(finishTaskChangeInput(param)),
        finishReportingSummaryTask: (task) => dispatch(finishReportingSummaryTask(task)),
        initFinishReportingSummaryTaskActionStatus: () => dispatch(initFinishReportingSummaryTaskActionStatus()),
        // queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // operationRcd
        // doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(ReportingSummaryDashboard);
