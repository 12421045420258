
import { message } from 'antd';
import { localStorageClient } from '../utils/localStorageClient';
import ResponeUtil from '../utils/ResponeUtil';

function succResponseHandle(response) {
  if (!(response.data) || !(response.data.respCode)) {
      const errRespData = ResponeUtil.formatErrResp(ResponeUtil.RESP_CODES.SERVICE_EXCEPTION,
          ResponeUtil.RESP_MSG.SERVICE_EXCEPTION);
      return Promise.reject(errRespData);
  }

  if (response.data.respCode === ResponeUtil.RESP_CODES.SUCCESS) {
      return response.data;
  }

  return Promise.reject(response.data);
}

function errResponseHandle(error) {
  if (!error.response) {
      return Promise.reject(ResponeUtil.formatErrResp(ResponeUtil.RESP_CODES.NETWORK_ERROR, ResponeUtil.RESP_MSG.NETWORK_ERROR));
  }

  const data = error.response.data;
  if (data && data.hasOwnProperty('respCode') &&
      data.hasOwnProperty('respMessage') && data.respCode.indexOf('F') === 0) {
      return Promise.reject(data);
  }

  const respCode = data ? data.respCode : 'F' + error.response.status;
  return Promise.reject(ResponeUtil.formatErrResp(respCode, ''));
}

export default {
  succResponseHandle,
  errResponseHandle,
};
