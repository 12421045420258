import React, {Component} from 'react';
import { Button, message, Modal,Divider } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../utils/localStorageClient';
import './AccountHoldingDashboard.css';
import AccountHoldingGrid from './AccountHoldingGrid';
import AddOrEditAccountDialog from './AddOrEditAccountDialog';
import HistoryUploadDialog from '../../common/historyUpload/components/HistoryUploadDialog';
import AddOrEditHoldingDialog from './AddOrEditHoldingDialog';
import DelHoldingDialog from './DelHoldingDialog';

class AccountHoldingDashboard extends Component {
    componentDidMount() {
        this.refreshBtnOnClicked();
    }


    componentDidUpdate() {
        const {
            loadPersonalAccountActionStatus,
            loadPersonalAccountResp,
            submitPersonalAccountActionStatus,
            submitPersonalAccountResp,
            deletePersonalAccountActionStatus,
            deletePersonalAccountResp,
        } = this.props;
        if(loadPersonalAccountActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadPersonalAccountActionStatus();
        }
        if(loadPersonalAccountActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load Personal Account failed';
            if(!!loadPersonalAccountResp && !!loadPersonalAccountResp.respMessage) {
                err = loadPersonalAccountResp.respMessage;
            }
            message.error(err);
            this.props.initLoadPersonalAccountActionStatus();
        }
        if(submitPersonalAccountActionStatus === ACTION_STATUS.SUCCESS) {
            message.success('Submit Personal Account succeeded');
            this.props.initSubmitPersonalAccountActionStatus();
            this.refreshBtnOnClicked();
        }
        if(submitPersonalAccountActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Submit Personal Account failed';
            if(!!submitPersonalAccountResp && !!submitPersonalAccountResp.respMessage) {
                err = loadPersonalAccountResp.respMessage;
            }
            message.error(err);
            this.props.initSubmitPersonalAccountActionStatus();
        }
        if(deletePersonalAccountActionStatus === ACTION_STATUS.SUCCESS) {
            message.success('Delete Personal Account succeeded');
            this.props.initDeletePersonalAccountActionStatus();
            this.refreshBtnOnClicked();
        }
        if(deletePersonalAccountActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Delete Personal Account failed';
            if(!!deletePersonalAccountResp && !!deletePersonalAccountResp.respMessage) {
                err = deletePersonalAccountResp.respMessage;
            }
            message.error(err);
            this.props.initDeletePersonalAccountActionStatus();
        }
    }


    refreshBtnOnClicked = () => {
        const param = {
            employee: localStorageClient.getUserName(),
            approvalStatus: '',
            needHoldingData: 'true',
            source: 'Report',
        }
        this.props.loadPersonalAccount(param);
    }


    addOrEditAccountBtnClicked = (mode) => {
        let personalAccount = null;
        let employee = null;
        if(mode === 'EDIT') {
            const {
                selectedPersonalAccountList,
            } = this.props;
            if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
                message.warn('Please choose one and only one Personal Account');
                return;
            }
            personalAccount = selectedPersonalAccountList[0];
            if(personalAccount.approvalStatus !== 'New' 
                    && personalAccount.approvalStatus !== 'Approved' 
                    && personalAccount.approvalStatus !== 'Rejected'
                    && personalAccount.approvalStatus !== 'FinalApproved') {
                message.warn('You can only edit a Personal Account in New/Approved/FinalApproved/Rejected status');
                return;
            }
            employee = personalAccount.employee;

        } else {
            employee = localStorageClient.getUserName();
            personalAccount = {employee};
        }

        this.props.loadAccountOptionsForAddOrEdit(employee);
        this.props.openAddOrEditAccountDialog(mode, personalAccount);
    }


    delAccountBtnClicked = () => {
        const {
            selectedPersonalAccountList,
        } = this.props;
        if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
            message.warn('Please choose one and only one Personal Account');
            return;
        }
        const personalAccount = selectedPersonalAccountList[0];
        // 状态限制
        if(personalAccount.approvalStatus !== 'New') {
            message.warn('You can only Delete a Personal Account in New status');
            return;
        }
        // 提交
        personalAccount.source = 'Report';
        personalAccount.mode = 'DEL';
        Modal.confirm({
            title: 'Are you sure to Delete this Person Account?',
            onOk: () => {
                this.props.deletePersonalAccount(personalAccount);
            }
        });
    }


    submitAccountBtnClicked = () => {
        const {
            selectedPersonalAccountList,
        } = this.props;
        if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
            message.warn('Please choose one and only one Personal Account');
            return;
        }
        const personalAccount = selectedPersonalAccountList[0];
        // 状态限制
        if(personalAccount.approvalStatus !== 'New' 
                && personalAccount.approvalStatus !== 'Approved' 
                && personalAccount.approvalStatus !== 'Rejected') {
            message.warn('You can only submit a Personal Account in New, Approved or Rejected status');
            return;
        }
        // 提交Approved状态的Account时，accountNo、attachment必填
        if(personalAccount.approvalStatus === 'Approved' &&(!personalAccount.accountNo || !personalAccount.attachment)) {
            message.warn('accountNo and attachement are required when Submit a Personal Account in Approved status');
            return;
        }
        // 提交
        personalAccount.source = 'Report';
        personalAccount.mode = 'SUBMIT';
        Modal.confirm({
            title: 'Are you sure to Submit this Person Account?',
            onOk: () => {
                this.props.submitPersonalAccount(personalAccount);
            }
        });
    }

    historyUploadOnClicked = () => {
        const {
            selectedPersonalAccountList,
            personalAccountDownloadUrl,
        } = this.props;
        if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
            message.warn('Please choose one and only one Personal Account');
            return;
        }
        const account = selectedPersonalAccountList[0];
        this.props.doHistoryUploadAction({action:'OPEN_AND_QUERY', queryFields: {module: 'PersonalAccountHolding', dataId: ''+account.id}, 
                                            baseDownloadUrl: personalAccountDownloadUrl });
    }

    addOrEditHoldingBtnClicked = (mode) => {
        let personalHolding = null;
        if(mode === 'EDIT') {
            const {
                selectedPersonalHoldingList,
            } = this.props;
            if(!selectedPersonalHoldingList || selectedPersonalHoldingList.length !== 1) {
                message.warn('Please choose one and only one Personal Holding');
                return;
            }
            personalHolding = selectedPersonalHoldingList[0];

        } else {
            const {
                selectedPersonalAccountList,
            } = this.props;
            if(!selectedPersonalAccountList || selectedPersonalAccountList.length !== 1) {
                message.warn('Please choose one and only one Personal Account');
                return;
            }
            const personalAccount = selectedPersonalAccountList[0];
            // 只能添加到FinalApproved状态的Account中
            if(personalAccount.approvalStatus !== 'New' 
                && personalAccount.approvalStatus !== 'Rejected'
                && personalAccount.approvalStatus !== 'Submitted') {
                message.warn('You can only add Personal Holding into a New/Rejected/Submitted Personal Account');
                return;
            }
            personalHolding = {
                employee: personalAccount.employee,
                personalAccountGuid: personalAccount.guid,
                personalAccountId: personalAccount.id,
                personalAccountName: personalAccount.accountName,
            };

        }
        this.props.openAddOrEditHoldingDialog(mode, personalHolding);
    }


    delHoldingBtnClicked = () => {
        const {
            selectedPersonalHoldingList,
        } = this.props;
        if(!selectedPersonalHoldingList || selectedPersonalHoldingList.length === 0) {
            message.warn('Please choose at least one Personal Holding');
            return;
        }
        const personalHoldingIdList = selectedPersonalHoldingList.map(e => e.id);
        this.props.openDelHoldingDialog(personalHoldingIdList);
    } 

    render() {
        const {
            loadPersonalAccountActionStatus,
        } = this.props;

        const refreshBtnIsLoading = loadPersonalAccountActionStatus === ACTION_STATUS.LOGINING;

        return (
            <div className='AccountHoldingWrapper'>
                <div className='AccountHoldingOperationBar'>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={() => this.addOrEditAccountBtnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'
                    >Add</Button>
                    <Button 
                        onClick={() => this.addOrEditAccountBtnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                    >Edit</Button>
                    <Button 
                        onClick={() => this.delAccountBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                    >Del</Button>

                    <Divider type='vertical' />

                    <span style={{ fontSize: 14 }}>Holding: </span>
                    <Button 
                        onClick={() => this.addOrEditHoldingBtnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='positive-button'
                    >Add</Button>
                    <Button 
                        onClick={() => this.addOrEditHoldingBtnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'
                    >Edit</Button>
                    <Button 
                        onClick={() => this.delHoldingBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        className='negative-button'
                    >Delete</Button>
                    <Divider type='vertical' />
                    <Button 
                        onClick={() => this.submitAccountBtnClicked()} 
                        size='small'
                        style={{ marginLeft: 10, fontSize: 12 }}
                        type='primary'>
                            Submit
                    </Button>
                    <Button 
                        onClick={() => this.historyUploadOnClicked()} 
                        style={{ marginLeft: 10, fontSize: 12 }}
                        size='small'
                        type='primary'>
                        HistoryUpload
                    </Button>
                </div>

                <div className='AccountHoldingGrid'><AccountHoldingGrid {...this.props} /></div>
                <AddOrEditAccountDialog {...this.props} />
                <HistoryUploadDialog {...this.props} />
                <AddOrEditHoldingDialog {...this.props} />
                <DelHoldingDialog {...this.props} />
            </div>
        );
    }
}


export default AccountHoldingDashboard;
