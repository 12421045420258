import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button, Select } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const TextArea = Input.TextArea;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class BatchChangeStatusModalDialog extends Component {

    refreshBtnOnClicked = () => {
        const {
            id,
            location,
            fundCompany,
        } = this.props.reportingSummaryQueryFields;
        this.props.loadReportingSummaryInfo({id, location, fundCompany});
    }


    componentDidUpdate() {
        const {
            batchChangeStatusForReportingSummaryStatus,
        } = this.props;
        const {
            mode,
        } = this.props.batchChangeStatusModal;

        if(batchChangeStatusForReportingSummaryStatus === ACTION_STATUS.SUCCESS) {
            const modalTitle = this.getModalAction(mode);
            const msg = modalTitle + ' succeeded.';
            message.success(msg);
            this.props.initBatchChangeStatusForReportingSummaryActionStatus();
            this.props.resetBatchChangeStatusModal();
            this.refreshBtnOnClicked();
        }
        if(batchChangeStatusForReportingSummaryStatus === ACTION_STATUS.ERROR) {
            this.props.initBatchChangeStatusForReportingSummaryActionStatus();
        }
    }


    getModalAction(mode) {
        if(mode === 'DEL') {
            return 'Batch delete Reporting Summary';
        } else if(mode === 'PAUSE') {
            return 'Batch pause Reporting Summary';
        } else if (mode === 'REPLACE'){
            return 'Batch REPLACE Person for Reporting Summary';
        }else {
            return 'Batch resume Reporting Summary';
        }
    }


    onInputChange = ({name, value}) => {
        this.props.batchChangeStatusChangeInput({name, value});
    }


    submitBatchChangeStatusModal = () => {
        const {
            mode,
            fields,
        } = this.props.batchChangeStatusModal;
        const {
            comments,
            idList,
            emailTo,
            
        } = fields;
        if(!idList || idList.length === 0) {
            message.error('System error! No Reporting Summary is selected.');
            return;
        }

        const idNum = idList.length;
        let action = '';
        if(mode === 'DEL') {
            action = 'delete';
        } else if(mode === 'PAUSE') {
            action = 'pause';
        } else if (mode === 'REPLACE'){
            action = 'replace person'
        }else {
            action = 'resume';
        }
        const confirmTitle = 'Are you sure to ' + action + ' the selected ' + idNum + ' Reporting Summary?';

        Modal.confirm({
            title: confirmTitle,
            onOk: () => {
                this.props.batchChangeStatusForReportingSummary({
                    idList,
                    actionMode: mode,
                    comments,
                    emailTo,
                    
                });
            }
        });
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            batchChangeStatusForReportingSummaryStatus,
            userAccountEmailOptions,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
            fields,
        } = this.props.batchChangeStatusModal;
        const {
            comments,
            emailTo,
            
        } = fields;

        const modalTitle = this.getModalAction(mode);
        

        const isSubmitLoading = batchChangeStatusForReportingSummaryStatus === ACTION_STATUS.LOGINING;
        const replaceFlag = mode ==='REPLACE'
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={600}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetBatchChangeStatusModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetBatchChangeStatusModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitBatchChangeStatusModal() }>
                          Submit
                        </Button>,
                    ]}
                >   
                 
                    <Form layout="horizontal">
                    <div style={{marginLeft: 0 }}>{
                            replaceFlag ?
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="Replace">
                                    {getFieldDecorator('emailTo', {
                                        initialValue: emailTo,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <Select 
                                            allowClear
                                            mode='multiple'
                                            style={{ width: 300, marginLeft: 0 }}
                                            placeholder='Please select employees' 
                                            onChange={(val) => {
                                                this.onEmailInputChange({ name: 'emailTo', value: val })
                                        }}>
                                            {userAccountEmailOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col> 
                        </Row>
                     : <div></div>
                           
                    }</div>
                       

                                        
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 0 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        
                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }

}

const BatchChangeStatusModalDialogForm = Form.create({ name: 'BatchChangeStatusModalDialog' })(BatchChangeStatusModalDialog);
export default BatchChangeStatusModalDialogForm;
