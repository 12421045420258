import {connect} from 'react-redux';
import ExpenseSummaryNewDashboard from '../components/ExpenseSummaryNewDashboard';


import {
    // query
    onQueryInputChange,
    loadExpenseSummaryNewInfo,
    initLoadExpenseSummaryNewInfoActionStatus,
    // select
    selectExpenseSummaryNewInfo,
    // add or edit
    openAddOrEditExpenseSummaryNewModal,
    addOrEditExpenseSummaryNewChangeInput,
    addOrEditExpenseSummaryNew,
    initAddOrEditExpenseSummaryNewActionStatus,
    resetAddOrEditExpenseSummaryNewModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelExpenseSummaryNewModal,
    delExpenseSummaryNewChangeInput,
    delExpenseSummaryNew,
    initDelExpenseSummaryNewActionStatus,
    resetDelExpenseSummaryNewModal,
    
} from '../ExpenseSummaryNewActions';

// import {
//     // operationRcd
//     doOperationRecordAction,
// } from '../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        categoryOptions: state.expenseSummaryNew.categoryOptions,
        fundFirmRelatedOptions: state.expenseSummaryNew.fundFirmRelatedOptions,
        ccyOptions: state.expenseSummaryNew.ccyOptions,
        frequencyOptions: state.expenseSummaryNew.frequencyOptions,
        instructionyOptions: state.expenseSummaryNew.instructionyOptions,
        approverOptions: state.expenseSummaryNew.approverOptions,
        payInstructionOptions: state.expenseSummaryNew.payInstructionOptions,

        monthlyReportAttachmentUploadUrl: state.expenseSummaryNew.monthlyReportAttachmentUploadUrl,
        monthlyReportAttachmentDownloadUrl: state.expenseSummaryNew.monthlyReportAttachmentDownloadUrl,
        // expenseSummaryNewQueryFields
        expenseSummaryNewQueryFields: state.expenseSummaryNew.expenseSummaryNewQueryFields,
        // query
        loadExpenseSummaryNewInfoActionStatus: state.expenseSummaryNew.loadExpenseSummaryNewInfoActionStatus,
        loadExpenseSummaryNewInfoResp: state.expenseSummaryNew.loadExpenseSummaryNewInfoResp,
        selectedExpenseSummaryNewInfoArray: state.expenseSummaryNew.selectedExpenseSummaryNewInfoArray,
        expenseSummaryNewGridColumns: state.expenseSummaryNew.expenseSummaryNewGridColumns,
        // add or edit
        addOrEditExpenseSummaryNewModal: state.expenseSummaryNew.addOrEditExpenseSummaryNewModal,
        addOrEditExpenseSummaryNewStatus: state.expenseSummaryNew.addOrEditExpenseSummaryNewStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.expenseSummaryNew.refreshFxStatus,
        refreshFxResp: state.expenseSummaryNew.refreshFxResp,
        // del
        delExpenseSummaryNewModal: state.expenseSummaryNew.delExpenseSummaryNewModal,
        delExpenseSummaryNewStatus: state.expenseSummaryNew.delExpenseSummaryNewStatus,

        // opeartionRcd
        operationRecord: state.operationRecord,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadExpenseSummaryNewInfo: (params) => dispatch(loadExpenseSummaryNewInfo(params)),
        initLoadExpenseSummaryNewInfoActionStatus: () => dispatch(initLoadExpenseSummaryNewInfoActionStatus()),
        // select
        selectExpenseSummaryNewInfo: (dataArray) => dispatch(selectExpenseSummaryNewInfo(dataArray)),
        // add or edit
        openAddOrEditExpenseSummaryNewModal: (mode, data) => dispatch(openAddOrEditExpenseSummaryNewModal(mode, data)),
        addOrEditExpenseSummaryNewChangeInput: (params) => dispatch(addOrEditExpenseSummaryNewChangeInput(params)),
        addOrEditExpenseSummaryNew: (info) => dispatch(addOrEditExpenseSummaryNew(info)),
        initAddOrEditExpenseSummaryNewActionStatus: () => dispatch(initAddOrEditExpenseSummaryNewActionStatus()),
        resetAddOrEditExpenseSummaryNewModal: () => dispatch(resetAddOrEditExpenseSummaryNewModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelExpenseSummaryNewModal: (info,mode) => dispatch(openDelExpenseSummaryNewModal(info,mode)),
        delExpenseSummaryNewChangeInput: (params) => dispatch(delExpenseSummaryNewChangeInput(params)),
        delExpenseSummaryNew: (info) => dispatch(delExpenseSummaryNew(info)),
        initDelExpenseSummaryNewActionStatus: () => dispatch(initDelExpenseSummaryNewActionStatus()),
        resetDelExpenseSummaryNewModal: () => dispatch(resetDelExpenseSummaryNewModal()),
        // operationRcd
        // doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(ExpenseSummaryNewDashboard);