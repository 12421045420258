import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


import {
    // query
    ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
    START_LOAD_WORKING_MANAGEMENT_INFO,
    LOAD_WORKING_MANAGEMENT_INFO_SUCCESS,
    LOAD_WORKING_MANAGEMENT_INFO_FAILURE,
    INIT_LOAD_WORKING_MANAGEMENT_INFO_ACTION_STATUS,
    // select
    SELECT_WORKING_MANAGEMENT,
    // add or edit
    OPEN_ADD_OR_EDIT_WORKING_MANAGEMENT_MODAL,
    ADD_OR_EDIT_WORKING_MANAGEMENT_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_WORKING_MANAGEMENT_MODAL,
    START_SUBMIT_SAVE_WORKING_MANAGEMENT,
    SUBMIT_SAVE_WORKING_MANAGEMENT_SUCCESS,
    SUBMIT_SAVE_WORKING_MANAGEMENT_FAILURE,
    INIT_SUBMIT_SAVE_WORKING_MANAGEMENT_ACTION_STATUS,


    //SUB add or edit
    OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL,
    ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL,
    START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS,
    SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE,
    INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS,



    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT,
    BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_ACTION_STATUS,
    // finish
    SELECT_WORKING_MANAGEMENT_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_WORKING_MANAGEMENT_TASK,
    FINISH_WORKING_MANAGEMENT_TASK_SUCCESS,
    FINISH_WORKING_MANAGEMENT_TASK_FAILURE,
    INIT_FINISH_WORKING_MANAGEMENT_TASK_ACTION_STATUS,


} from './WorkManagementConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadWorkManagementInfo() {
    return {
      type: START_LOAD_WORKING_MANAGEMENT_INFO,
    };
}

function getQueryReqParams(params) {
    let startDate = params.startDate !== undefined && params.startDate !== null && params.startDate !== ''
    ? params.startDate.format("YYYY-MM-DD") : '';
    let endDate = params.endDate !== undefined && params.endDate !== null && params.endDate !== ''
        ? params.endDate.format("YYYY-MM-DD") : '';
    const requestParams = {
    startDate: startDate,
    endDate: endDate,
    category: params.category,
    fundco: params.fundco,
    owner: params.owner,
    monthStart: params.monthStart,
    workstatus: params.status,
    };
    return requestParams;
}

export function loadWorkManagementInfo(params) {

    const requestParams = getQueryReqParams(params);

    return function(dispatch) {
        dispatch(startLoadWorkManagementInfo());
        httpClient
          .get(UrlConfig.LOAD_WORKING_MANAGEMENT_INFO, requestParams)
          .then(resp => dispatch(loadWorkManagementInfoSuccess(resp)))
          .catch(err => dispatch(loadWorkManagementInfoFailure(err)));
    };
}
function loadWorkManagementInfoSuccess(resp) {
    return {
      type: LOAD_WORKING_MANAGEMENT_INFO_SUCCESS,
      payload: resp,
    };
}
function loadWorkManagementInfoFailure(err) {
    return {
        type: LOAD_WORKING_MANAGEMENT_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadWorkManagementInfoActionStatus() {
    return {
      type: INIT_LOAD_WORKING_MANAGEMENT_INFO_ACTION_STATUS,
    };
}


// select
export function selectWorkManagement(array) {
    return {
        type: SELECT_WORKING_MANAGEMENT,
        payload: array,
    };
}


// add or edit
export function openAddOrEditWorkManagementModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_WORKING_MANAGEMENT_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditWorkManagementChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_WORKING_MANAGEMENT_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveWorkManagementModal() {
    return {
        type: RESET_SUBMIT_SAVE_WORKING_MANAGEMENT_MODAL,
    };
}

function startSubmitSaveWorkManagement() {
    return {
        type: START_SUBMIT_SAVE_WORKING_MANAGEMENT,
    };
}
export function submitSaveWorkManagement(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveWorkManagement());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_WORKING_MANAGEMENT;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_WORKING_MANAGEMENT;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveWorkManagementSuccess(resp)))
            .catch(err => dispatch(submitSaveWorkManagementFailure(err)));        
    };
}
function submitSaveWorkManagementSuccess(resp) {
    return {
        type: SUBMIT_SAVE_WORKING_MANAGEMENT_SUCCESS,
        payload: resp,
    };
}
export function submitSaveWorkManagementFailure(err) {
    return {
        type: SUBMIT_SAVE_WORKING_MANAGEMENT_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveWorkManagementActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_WORKING_MANAGEMENT_ACTION_STATUS,
    };
}


//sub add or edit
export function openAddOrEditWorkManagementSubModal(mode, data,idList) {
    return {
        type: OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL,
        payload: {
            mode,
            data,
            idList,
        },
    };
}
export function addOrEditWorkManagementSubChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveWorkManagementSubModal() {
    return {
        type: RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL,
    };
}

function startSubmitSaveWorkManagementSub() {
    return {
        type: START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB,
    };
}
export function submitSaveWorkManagementSub(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveWorkManagementSub());
        let postURL = null;
        if(mode === 'ADD' || mode === 'COPY') {
            postURL = UrlConfig.INSERT_WORKING_MANAGEMENTSUB;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_WORKING_MANAGEMENTSUB;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveWorkManagementSubSuccess(resp)))
            .catch(err => dispatch(submitSaveWorkManagementSubFailure(err)));        
    };
}
function submitSaveWorkManagementSubSuccess(resp) {
    return {
        type: SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS,
        payload: resp,
    };
}
export function submitSaveWorkManagementSubFailure(err) {
    return {
        type: SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveWorkManagementSubActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS,
    };
}



// batch change status
export function openBatchChangeStatusModal(mode, idList) {
    return {
        type: OPEN_BATCH_CHANGE_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetBatchChangeStatusModal() {
    return {
        type: RESET_BATCH_CHANGE_STATUS_MODAL,
    };
}
export function batchChangeStatusChangeInput({name, value}) {
    return {
        type: BATCH_CHANGE_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startBatchChangeStatusForWorkManagement() {
    return {
        type: START_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT,
    };
}
export function batchChangeStatusForWorkManagement(params) {
    return function(dispatch) {
        dispatch(startBatchChangeStatusForWorkManagement());
        httpClient
            .post(UrlConfig.BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT, params)
            .then(resp => dispatch(batchChangeStatusForWorkManagementSuccess(resp)))
            .catch(err => dispatch(batchChangeStatusForWorkManagementFailure(err)));        
    };
}
function batchChangeStatusForWorkManagementSuccess(resp) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_SUCCESS,
        payload: resp,
    };
}
function batchChangeStatusForWorkManagementFailure(err) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_FAILURE,
        payload: err,
    };
}
export function initBatchChangeStatusForWorkManagementActionStatus() {
    return {
        type: INIT_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_ACTION_STATUS,
    };
}


// finish
export function selectWorkManagementTask(array) {
    return {
        type: SELECT_WORKING_MANAGEMENT_TASK,
        payload: array,
    };
}
export function openFinishTaskModal(task) {
    return {
        type: OPEN_FINISH_TASK_MODAL,
        payload: task,
    };
}
export function resetFinishTaskModal() {
    return {
        type: RESET_FINISH_TASK_MODAL,
    };
}
export function finishTaskChangeInput({name, value}) {
    return {
        type: FINISH_TASK_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startFinishWorkManagementTask() {
    return {
        type: START_FINISH_WORKING_MANAGEMENT_TASK,
    };
}
export function finishWorkManagementTask(params) {
    return function(dispatch) {
        dispatch(startFinishWorkManagementTask());
        httpClient
            .post(UrlConfig.FINISH_WORKING_MANAGEMENT_TASK, params)
            .then(resp => dispatch(finishWorkManagementTaskSuccess(resp)))
            .catch(err => dispatch(finishWorkManagementTaskFailure(err)));        
    };
}
function finishWorkManagementTaskSuccess(resp) {
    return {
        type: FINISH_WORKING_MANAGEMENT_TASK_SUCCESS,
        payload: resp,
    };
}
function finishWorkManagementTaskFailure(err) {
    return {
        type: FINISH_WORKING_MANAGEMENT_TASK_FAILURE,
        payload: err,
    };
}
export function initFinishWorkManagementTaskActionStatus() {
    return {
        type: INIT_FINISH_WORKING_MANAGEMENT_TASK_ACTION_STATUS,
    };
}

