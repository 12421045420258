
export const OPEN_OPERATION_RECORD_DIALOG = 'OPEN_OPERATION_RECORD_DIALOG';
export const START_QUERY_OPERATION_RECORD = 'START_QUERY_OPERATION_RECORD';
export const QUERY_OPERATION_RECORD_SUCESS = 'QUERY_OPERATION_RECORD_SUCESS';
export const QUERY_OPERATION_RECORD_FAILURE = 'QUERY_OPERATION_RECORD_FAILURE';
export const INIT_QUERY_OPERATION_RECORD_STATUS = 'INIT_QUERY_OPERATION_RECORD_STATUS';
export const RESET_OPERATION_RECORD_DIALOG = 'RESET_OPERATION_RECORD_DIALOG';
// detail
export const SHOW_DETAIL_RCD = 'SHOW_DETAIL_RCD';
export const RESET_DETAIL_RCD = 'RESET_DETAIL_RCD';
