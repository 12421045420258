import moment from "moment";
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';


const downloadAttachment = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_ATT_DOWNLOAD_URL;


const deadlineCheckYellowWarnDate = 60;
const deadlineCheckRedWarnDate = 0;

// 距离 examDeadline 还有多少天
// examDeadline 没有表示不存在过期问题，所以返回999
const getExamDeadlineDiff = (data) => {
    const deadline = data.examDeadline;
    if(!deadline) {
        return 999;
    }
    const today = moment(moment().format('YYYY-MM-DD'));
    const deadlineMoment = moment(deadline);
    const diff = deadlineMoment.diff(today, 'day');
    return diff;
}

// 若 cptHourAccepted >= cptHourRequire，则始终返回999
// 否则，返回距离该年度 12/31 还剩多少天
const cptDeadline = '12-31';
const getCPTDeadlineDiff = (data) => {
    const cptHourRequire = data.cptHourRequire;
    const cptHourAccepted = data.cptHourAccepted;
    if(cptHourAccepted >= cptHourRequire) {
        return 999;
    }
    const deadline = moment(data.year + '-' + cptDeadline);
    const today = moment(moment().format('YYYY-MM-DD'));
    const diff = deadline.diff(today, 'day');
    return diff;
}

const licensingGridColumns = [
    {
        field: 'id',
        headerName: "Data Id",
        cellClass: 'non-number',
        width: 100,
        cellRenderer: "agGroupCellRenderer",
        checkboxSelection: true
    },
    {
        field: 'licenseBasicInfo.country',
        headerName: "Country",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'licenseBasicInfo.regulator',
        headerName: "Regulator",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'licenseBasicInfo.license',
        headerName: "License",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'licenseNo',
        headerName: "Licensing No",
        cellClass: 'non-number',
        width: 150,
    },
    {
        headerName: "Deadline Check",
        cellClass: 'non-number',
        width: 300,
        cellRenderer: (node) => {
            let content = '';
            const examStatus = node.data.examStatus;
            // examDeadline
            if(examStatus === 'Outstanding') {
                const examDiff = getExamDeadlineDiff(node.data);
                if(examDiff < deadlineCheckRedWarnDate) {
                    content += '<font color="#FF0000">Over Exam Deadline;&nbsp;</font>';
                } else if(examDiff >= deadlineCheckRedWarnDate && examDiff <= deadlineCheckYellowWarnDate) {
                    content += '<font color="#CC9933">Near Exam Deadline;&nbsp;</font>';
                }
            }
            // cptDeadline
            const currYear = moment().format('YYYY');
            const licenseCPTYearRcdList = node.data.licenseCPTYearRcdList.filter(e => e.year+'' === currYear);
            if(!licenseCPTYearRcdList || licenseCPTYearRcdList.length === 0) {
                content += '<font color="#FF0000">Error! No CPT Record data of current year;</font>';
            } else {
                const currYearCPTData = licenseCPTYearRcdList[0];
                const cptDiff = getCPTDeadlineDiff(currYearCPTData);
                if(cptDiff < deadlineCheckRedWarnDate) {
                    content += '<font color="#FF0000">Over CPT Hour Deadline;</font>';
                } else if(cptDiff >= deadlineCheckRedWarnDate && cptDiff <= deadlineCheckYellowWarnDate) {
                    content += '<font color="#CC9933">Near CPT Hour Deadline;</font>';
                }
            }
            return content;
        },
    },
    {
        field: 'regiStatus',
        headerName: "Register Status",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'licensedDate',
        headerName: "Licensed Date",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'examStatus',
        headerName: "Exam Status",
        cellClass: 'non-number',
        width: 120,
        cellClassRules: {
            // 临近deadline
            "near-deadline": function(node) {
                if(node.data.examStatus === 'Outstanding') {
                    const diff = getExamDeadlineDiff(node.data);
                    return diff >= deadlineCheckRedWarnDate && diff <= deadlineCheckYellowWarnDate;
                }
                return false;
            },
            // 超过deadline
            'exceed-deadline': function(node) {
                if(node.data.examStatus === 'Outstanding') {
                    const diff = getExamDeadlineDiff(node.data);
                    return diff < deadlineCheckRedWarnDate;
                }
                return false;
            },
        },
    },
    {
        field: 'examDeadline',
        headerName: "Exam Deadline",
        cellClass: 'non-number',
        width: 120,
        cellClassRules: {
            // 临近deadline
            "near-deadline": function(node) {
                if(node.data.examStatus === 'Outstanding') {
                    const diff = getExamDeadlineDiff(node.data);
                    return diff >= deadlineCheckRedWarnDate && diff <= deadlineCheckYellowWarnDate;
                }
                return false;
            },
            // 超过deadline
            'exceed-deadline': function(node) {
                if(node.data.examStatus === 'Outstanding') {
                    const diff = getExamDeadlineDiff(node.data);
                    return diff < deadlineCheckRedWarnDate;
                }
                return false;
            },
        },
    },
    {
        field: 'examLicenseInfo',
        headerName: "Exam Info",
        cellClass: 'non-number',
        width: 200,
    },
    {
        headerName: 'Latest Upload',
        cellClass: 'non-number',
        width: 150,
        cellRenderer: (node) => {
            const fileName = node.data.latestUpload;
            if (!!fileName) {
                const url = downloadAttachment + '/' + fileName;
                let link = document.createElement('a');
                link.innerText = fileName;
                link.target = '_blank';
                link.href = url;
                return link;
            }
            return '';
        },
    },
    {
        headerName: 'History Upload',
        cellClass: 'non-number',
        width: 150,
        cellRenderer: (node) => {
            const fileName = node.data.historyUpload;
            if (!!fileName) {
                const url = downloadAttachment + '/' + fileName;
                let link = document.createElement('a');
                link.innerText = fileName;
                link.target = '_blank';
                link.href = url;
                return link;
            }
            return '';
        },
    },
    {
        field: 'remarks',
        headerName: "Remarks",
        cellClass: 'non-number',
        width: 200,
    },
    {
        field: 'feedback',
        headerName: "Feedback",
        cellClass: 'non-number',
        width: 200,
    },
];


const licensingCPTYearRcdGridColumns = [
    {
        field: 'year',
        headerName: "Year",
        cellClass: 'non-number',
        width: 80,
    },
    {
        field: 'cptHourRequire',
        headerName: "CPT Hour Required",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'cptHourFinished',
        headerName: "CPT Hour Finished",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'cptHourAccepted',
        headerName: "CPT Hour Accepted",
        cellClass: 'non-number',
        width: 150,
        cellClassRules: {
            // 临近deadline
            "near-deadline": function(node) {
                const diff = getCPTDeadlineDiff(node.data);
                return diff >= deadlineCheckRedWarnDate && diff <= deadlineCheckYellowWarnDate;
            },
            // 超过deadline
            'exceed-deadline': function(node) {
                const diff = getCPTDeadlineDiff(node.data);
                return diff < deadlineCheckRedWarnDate;
            },
        },
    },
    {
        headerName: 'Latest Upload',
        cellClass: 'non-number',
        width: 150,
        cellRenderer: (node) => {
            const fileName = node.data.latestUpload;
            if (!!fileName) {
                const url = downloadAttachment + '/' + fileName;
                let link = document.createElement('a');
                link.innerText = fileName;
                link.target = '_blank';
                link.href = url;
                return link;
            }
            return '';
        },
    },
    {
        headerName: 'History Upload',
        cellClass: 'non-number',
        width: 150,
        cellRenderer: (node) => {
            const fileName = node.data.historyUpload;
            if (!!fileName) {
                const url = downloadAttachment + '/' + fileName;
                let link = document.createElement('a');
                link.innerText = fileName;
                link.target = '_blank';
                link.href = url;
                return link;
            }
            return '';
        },
    },
    {
        field: 'trainingInfo',
        headerName: "Training Info",
        cellClass: 'non-number',
        width: 200,
    },
    {
        field: 'remarks',
        headerName: "Remarks",
        cellClass: 'non-number',
        width: 200,
    },
    {
        field: 'feedback',
        headerName: "Feedback",
        cellClass: 'non-number',
        width: 200,
    },
];


const GridColumnMap = {
    licensingGridColumns,
    licensingCPTYearRcdGridColumns,
};

export default GridColumnMap;
