import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, Checkbox } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditReportingSummaryModalDialog extends Component {

    refreshBtnOnClicked = () => {
        const {
            id,
            location,
            fundCompany,
        } = this.props.reportingSummaryQueryFields;
        this.props.loadReportingSummaryInfo({id, location, fundCompany});
    }

    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }
    
    componentDidUpdate() {
        const {
            addOrEditReportingSummaryStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditReportingSummaryModal;

        if(addOrEditReportingSummaryStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' Reporting Summary succeeded.';
            message.success(msg);
            this.props.initSubmitSaveReportingSummaryActionStatus();
            this.props.resetSubmitSaveReportingSummaryModal();
            this.refreshBtnOnClicked();
        }
        if(addOrEditReportingSummaryStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSaveReportingSummaryActionStatus();
        }

    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditReportingSummaryChangeInput({name, value});
    }
    onFrequencyInputChange = (value) => {
        this.onInputChange({name: 'frequency', value: value});
        this.onInputChange({name: 'deadline', value: undefined});
        this.clearDecomposedDeadline();
        this.onInputChange({name: 'notification', value: undefined});
    }
    onDeadlineNAInputChange = (value) => {
        const deadline = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
        this.clearDecomposedDeadline();
        // deadline是NA时，notification必须是NA，其他情况，清空notification重新填写
        this.onInputChange({name: 'notification', value: deadline});
        
    }
    onDeadlineEveryWorkdayInputChange = (value) => {
        const deadline = !!value ? 'EveryWorkday' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineEndOfMonthInputChange = (value) => {
        const deadline = !!value ? 'EndOfMonth' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineMonthInputChange = (value) => {
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineMonth', value: value});
    }
    // 清空分解后的deadline的各参数
    clearDecomposedDeadline = () => {
        this.onInputChange({name: 'deadlineMonth', value: undefined});
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineYear', value: undefined});
    }
    onNotificationNAInputChange = (value) => {
        const notification = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'notification', value: notification});
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }


    submitSaveReportingSummaryModal = () => {
        const {
            mode,
        } = this.props.addOrEditReportingSummaryModal;
        const {
            id,
            status,
            location,
            fundCompany,
            description,
            via,
            frequency,
            deadline,
            notification,
            regulator,
            responsibleDept,
            emailTo,
            emailCC,
            regulation,
            remarks,
            comments,
            deadlineMonth,
            deadlineDate,
            deadlineYear,
        } = this.props.addOrEditReportingSummaryModal.fields;


        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }

            let combinedDeadline = deadline;
            if(combinedDeadline !== 'NA') {
                // SemiAnnually、Annually、Every3Years，进一步检查分解后的deadline
                if(frequency === 'SemiAnnually' || frequency === 'Annually') {
                    if(!deadlineMonth || !deadlineDate) {
                        const err = {respMessage: 'Month and Date of "deadline" are required, if "NA" is not checked.'};
                        this.props.submitSaveReportingSummaryFailure(err);
                        return;
                    }
                    combinedDeadline = deadlineMonth + '-' + deadlineDate;
                }
                if(frequency === 'Every3Years') {
                    if(!deadlineMonth || !deadlineDate || !deadlineYear) {
                        const err = {respMessage: 'Month, Date and StartYear of "deadline" are required, if "NA" is not checked.'};
                        this.props.submitSaveReportingSummaryFailure(err);
                        return;
                    }
                    combinedDeadline = deadlineMonth + '-' + deadlineDate + ',' + deadlineYear;
                }
            }
            // 检查deadline
            if(!combinedDeadline) {
                const err = {respMessage: '"deadline" is required, if "NA" is not checked.'};
                this.props.submitSaveReportingSummaryFailure(err);
                return;
            }
            // frequency=Quarterly时，若deadline!=NA，它只能是一个非负整数
            const nonNegIntReg = /^\d+$/;
            if(frequency === 'Quarterly' && combinedDeadline !== 'NA') {
                if(!nonNegIntReg.test(combinedDeadline)) {
                    const err = {respMessage: 'If "frequency" is "Quarterly" and "deadline" is not "NA", "deadline" can only be a positive integer or 0.'};
                    this.props.submitSaveReportingSummaryFailure(err);
                    return;
                }
                // 对于Quarterly，将诸如005之类的输入，处理成5
                combinedDeadline = combinedDeadline * 1 + '';
            }

            // 检查notification
            let convertedNotification = notification;
            if(convertedNotification === undefined || convertedNotification === null || convertedNotification === '') {
                const err = {respMessage: '"notification" is required, if "NA" is not checked.'};
                this.props.submitSaveReportingSummaryFailure(err);
                return;
            }
            if(convertedNotification !== 'NA' &&  !nonNegIntReg.test(convertedNotification)) {
                const err = {respMessage: '"notification" can only be a positive integer or 0, if "NA" is not checked.'};
                this.props.submitSaveReportingSummaryFailure(err);
                return;
            }
            // notification 是 NA 时，修改为null再提交
            if(convertedNotification === 'NA') {
                convertedNotification = null;
            }

            // notification 不是 NA 时，必须有emailTo
            if(notification !== 'NA' && !emailTo) {
                const err = {respMessage: '"emailTo" is required while "notification" is not NA.'};
                this.props.submitSaveReportingSummaryFailure(err);
                return;
            }

            // 检查email格式
            const emailReg = /^[\.a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+([a-zA-Z0-9]+)$/;
            if(!!emailTo) {
                const emailToArray = emailTo.split(';');
                for(let i=0; i<emailToArray.length; i++) {
                    let email = emailToArray[i];
                    if(!emailReg.test(email)) {
                        const err = {respMessage: 'Email "' + email +'" in "emailTo" is illegal'};
                        this.props.submitSaveReportingSummaryFailure(err);
                        return;
                    }
                }
            }
            if(!!emailCC) {
                const emailCCArray = emailCC.split(';');
                for(let i=0; i<emailCCArray.length; i++) {
                    let email = emailCCArray[i];
                    if(!emailReg.test(email)) {
                        const err = {respMessage: 'Email "' + email +'" in "emailCC" is illegal'};
                        this.props.submitSaveReportingSummaryFailure(err);
                        return;
                    }
                }
            }
           
            // 执行提交
            this.props.submitSaveReportingSummary({
                id,
                status,
                location,
                fundCompany,
                description,
                via,
                frequency,
                deadline: combinedDeadline,
                notification: convertedNotification,
                regulator,
                responsibleDept,
                emailTo,
                emailCC,
                regulation,
                remarks,
                comments,
                mode,
            });

        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            locationOptions,
            fundCompanyOptions,
            frequencyOptions,
            weekdayOptions,
            monthOptions,
            responsibleDeptOptions,
            addOrEditReportingSummaryModal,
            addOrEditReportingSummaryStatus,
            userAccountEmailOptions,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditReportingSummaryModal;

        const {
            id,
            location,
            fundCompany,
            description,
            via,
            frequency,
            deadline,
            notification,
            regulator,
            responsibleDept,
            emailTo,
            emailCC,
            regulation,
            remarks,
            comments,
            deadlineMonth,
            deadlineDate,
            deadlineYear,
        } = addOrEditReportingSummaryModal.fields;

        const emailToArray = !emailTo ? [] : emailTo.split(';');


        const monthDateOptions = [];
        let maxDays = -1;
        if(frequency === 'Monthly') {
            maxDays = 31;
        } else if(frequency === 'SemiAnnually' || frequency === 'Annually' || frequency === 'Every3Years') {
            if(deadlineMonth === '02') {
                maxDays = 29;
            } else if(deadlineMonth === '04' || deadlineMonth === '06' || deadlineMonth === '09' || deadlineMonth === '11') {
                maxDays = 30;
            } else if(deadlineMonth === '01' || deadlineMonth === '03' || deadlineMonth === '05' || deadlineMonth === '07'
                       || deadlineMonth === '08' || deadlineMonth === '10' || deadlineMonth === '12') {
                maxDays = 31;
            }
        }
        if(frequency === 'Monthly' || frequency === 'SemiAnnually' || frequency === 'Annually' || frequency === 'Every3Years') {
            for(let i=1; i<=maxDays; i++) {
                if(i<10) {
                    i = '0' + i;
                }
                monthDateOptions.push(<Option key={i}>{i}</Option>);
            }
        }

        const startYearOptions = [];
        if(frequency === 'Every3Years') {
            for(let i=2020; i<=2099; i++) {
                startYearOptions.push(<Option key={i}>{i}</Option>);
            }
        }


        const modalTitle = this.getActionFromMode(mode) + ' Reporting Summary';
        const isSubmitLoading = addOrEditReportingSummaryStatus === ACTION_STATUS.LOGINING;


        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveReportingSummaryModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveReportingSummaryModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveReportingSummaryModal() }>
                          Save
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Location">
                                    {getFieldDecorator('location', {
                                        initialValue: location,
                                        rules: [{
                                            required: true,
                                            message: 'Please select location',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'location', value: val })
                                            }} 
                                            placeholder="Please select location">
                                                {locationOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Fund/Co.">
                                    {getFieldDecorator('fundCompany', {
                                        initialValue: fundCompany,
                                        rules: [{
                                            required: true,
                                            message: 'Please select fundCompany',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'fundCompany', value: val })
                                            }} 
                                            placeholder="Please select fundCompany">
                                                {fundCompanyOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Description">
                                    {getFieldDecorator('description', {
                                        initialValue: description,
                                        rules: [{
                                            required: true,
                                            message: 'Please input description',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'description', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="System/Portal">
                                    {getFieldDecorator('via', {
                                        initialValue: via,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'via', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Recurring">
                                    {getFieldDecorator('frequency', {
                                        initialValue: frequency,
                                        rules: [{
                                            required: true,
                                            message: 'Please select frequency',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onFrequencyInputChange(val)
                                            }} 
                                            placeholder="Please select frequency">
                                                {frequencyOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Deadline/End Date">
                                    {getFieldDecorator('deadline', {
                                        initialValue: deadline,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <div>
                                            <Checkbox 
                                                style={{ marginLeft: 10 }}
                                                checked={deadline === 'NA'}
                                                onChange={(e) => {
                                                    this.onDeadlineNAInputChange(e.target.checked)
                                                }} 
                                            >NA</Checkbox>
                                        </div>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Notification">
                                    {getFieldDecorator('notification', {
                                        initialValue: notification,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <div>
                                            <Checkbox 
                                                style={{ marginLeft: 10 }}
                                                checked={notification === 'NA'}
                                                disabled={deadline === 'NA'}
                                                onChange={(e) => {
                                                    this.onNotificationNAInputChange(e.target.checked)
                                                }} 
                                            >NA</Checkbox>
                                            <font style={{ marginLeft: 10 }}>T&nbsp;-&nbsp;</font>
                                            <Input
                                                disabled={deadline === 'NA' || notification === 'NA'}
                                                value={notification !== 'NA' ? notification : undefined}
                                                style={{ width: 150, marginLeft: 10 }}
                                                placeholder='Days num' 
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'notification', value: e.target.value })
                                                }}
                                            />
                                        </div>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <div style={{marginLeft: 190 }}>{
                                    frequency === 'EveryWorkday' ?
                                        <Checkbox
                                            style={{ marginLeft: 10 }}
                                            disabled={deadline === 'NA'}
                                            checked={deadline === 'EveryWorkday'}
                                            onChange={(e) => {
                                                this.onDeadlineEveryWorkdayInputChange(e.target.checked)
                                            }} 
                                        >EveryWorkday</Checkbox> 
                                    : frequency === 'Weekly' ? 
                                        <Select
                                            allowClear
                                            disabled={deadline === 'NA'}
                                            value={frequency === 'Weekly' && deadline !== 'NA' ? deadline : undefined}
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'deadline', value: val })
                                            }} 
                                            placeholder="Day of Week">
                                                {weekdayOptions}
                                        </Select>
                                    : frequency === 'Monthly' ? 
                                        <span>
                                        <Checkbox
                                            style={{ marginLeft: 10 }}
                                            disabled={deadline === 'NA'}
                                            checked={deadline === 'EndOfMonth'}
                                            onChange={(e) => {
                                                this.onDeadlineEndOfMonthInputChange(e.target.checked)
                                            }} 
                                        >EndOfMonth</Checkbox> 
                                        <Select
                                            allowClear
                                            disabled={deadline === 'NA' || deadline === 'EndOfMonth'}
                                            value={frequency === 'Monthly' && deadline !== 'NA' && deadline !== 'EndOfMonth' ? deadline : undefined}
                                            style={{ width: 120, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'deadline', value: val })
                                            }} 
                                            placeholder="Date">
                                                {monthDateOptions}
                                        </Select>
                                        </span>
                                    : frequency === 'Quarterly' ? 
                                        <Input
                                            disabled={deadline === 'NA'}
                                            value={frequency === 'Quarterly' && deadline !== 'NA' ? deadline : undefined}
                                            style={{ width: 250, marginLeft: 10 }}
                                            placeholder='Days num after quarter ends' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'deadline', value: e.target.value })
                                            }}
                                        />
                                    : (frequency === 'SemiAnnually' || frequency === 'Annually' || frequency === 'Every3Years') ? 
                                        <span>
                                        <Select
                                            allowClear
                                            disabled={deadline === 'NA'}
                                            value={(frequency === 'SemiAnnually' || frequency === 'Annually' || frequency === 'Every3Years') && deadline !== 'NA' ? deadlineMonth : undefined}
                                            style={{ width: 90, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onDeadlineMonthInputChange(val)
                                            }} 
                                            placeholder="Month">
                                                {monthOptions}
                                        </Select>
                                        <Select
                                            allowClear
                                            disabled={deadline === 'NA'}
                                            value={(frequency === 'SemiAnnually' || frequency === 'Annually' || frequency === 'Every3Years') && deadline !== 'NA' && !!deadlineMonth ? deadlineDate : undefined}
                                            style={{ width: 90, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'deadlineDate', value: val })
                                            }} 
                                            placeholder="Date">
                                                {monthDateOptions}
                                        </Select>
                                            <span>
                                            {
                                                frequency === 'Every3Years' ? 
                                                <Select
                                                    allowClear
                                                    disabled={deadline === 'NA'}
                                                    value={deadlineYear}
                                                    style={{ width: 110, marginLeft: 10 }}
                                                    onChange={(val) => {
                                                        this.onInputChange({ name: 'deadlineYear', value: val })
                                                    }} 
                                                    placeholder="Start Year">
                                                        {startYearOptions}
                                                </Select>
                                                :<span>&nbsp;</span>
                                            }
                                            </span>
                                        </span>
                                    : <div></div>
                                }</div>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Regulator/Authority/Dept">
                                    {getFieldDecorator('regulator', {
                                        initialValue: regulator,
                                        rules: [{
                                            required: true,
                                            message: 'Please input regulator',
                                        }],
                                        })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='regulator' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'regulator', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Responsible Dept">
                                    {getFieldDecorator('responsibleDept', {
                                        initialValue: responsibleDept,
                                        rules: [{
                                            required: true,
                                            message: 'Please select responsibleDept',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'responsibleDept', value: val })
                                            }} 
                                            placeholder="responsibleDept">
                                                {responsibleDeptOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Person in Charge">
                                    {getFieldDecorator('emailTo', {
                                        initialValue: emailToArray,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <Select 
                                            allowClear
                                            mode='multiple'
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='Please select employees' 
                                            onChange={(val) => {
                                                this.onEmailInputChange({ name: 'emailTo', value: val })
                                        }}>
                                            {userAccountEmailOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            {/* <Col span={12}>
                                <FormItem {...formItemLayout} label="emailCC">
                                    {getFieldDecorator('emailCC', {
                                        initialValue: emailCC,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            placeholder='Seperate emails with semi-colons'
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'emailCC', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col> */}
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Regulation Source">
                                    {getFieldDecorator('regulation', {
                                        initialValue: regulation,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'regulation', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Action Comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                            </Col>
                        </Row>

                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditReportingSummaryModalDialogForm = Form.create({ name: 'AddOrEditReportingSummaryModalDialog' })(AddOrEditReportingSummaryModalDialog);

export default AddOrEditReportingSummaryModalDialogForm;
