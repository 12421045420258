import React, {Component} from 'react';
import './ApplyReconDashboard.css';
import { Breadcrumb, Select, Button, message, Input, Divider } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import ApplyReconGrid from './ApplyReconGrid';
import AddOrEditApplyReconModalDialog from './AddOrEditApplyReconModalDialog';
import BatchChangeStatusModalDialog from './BatchChangeStatusModalDialog';
import FinishTaskModalDialog from './FinishTaskModalDialog';
// import OperationRecordDialog from '../../../../common/operationRcd/components/OperationRecordDialog';
import { localStorageClient } from '../../../../utils/localStorageClient';

class ApplyReconDashboard extends Component {

    componentDidMount() {
        // queryString中的查询条件
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        this.onQueryInputChange({name: 'id', value: id});

        const userName = localStorageClient.getUserName();
        this.props.loadApplyReconInfo({id, userName,source:'report'});
    }


    componentDidUpdate() {
        const {
            loadApplyReconInfoActionStatus,
            loadApplyReconInfoResp,
        } = this.props;
        if(loadApplyReconInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadApplyReconInfoActionStatus();
        }
        if(loadApplyReconInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load Reporting Summary Info failed';
            if(!!loadApplyReconInfoResp && !!loadApplyReconInfoResp.respMessage) {
                err = loadApplyReconInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadApplyReconInfoActionStatus();
        }
    }

    onDataIDQueryInputChange = ({ name, value }) => {
        if(isNaN(value)) {
            return;
        }
        this.props.onQueryInputChange({ name, value });
    }

    onQueryInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    refreshBtnOnClicked = () => {
        const userName = localStorageClient.getUserName();
        const {
            id,
        } = this.props.applyReconQueryFields;
        this.props.loadApplyReconInfo({id ,userName,source:'report'});
    }


    addOrEditApplyReconBtnOnClicked = (mode) => {
        if(mode === 'Stock Pitch' || mode === 'PaperPortfolioOrBook' || mode === 'ADD') {
            this.props.openAddOrEditApplyReconModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedApplyReconArray,
            } = this.props;
            if(selectedApplyReconArray.length !== 1) {
                message.warning("Please choose one and only one Reporting Summary");
                return;
            }
            const currentData = selectedApplyReconArray[0];
            if(currentData.state !== 'New' && currentData.state !== 'Reject' && currentData.state !== 'SUBMIT' ) {
                message.warning("only be modified in New/Reject states.");
                return;
            }
            
            if (currentData.applytype.includes('Paper') || currentData.applytype.includes('Book')){
                mode='PaperPortfolioOrBook'+'_'+mode
            }else{
                mode =currentData.applytype +'_'+mode
            }
            
            this.props.openAddOrEditApplyReconModal(mode, currentData);
        }
    }


    batchChangeStatusBtnOnClicked = (mode) => {
        const {
            selectedApplyReconArray,
        } = this.props;
        if(mode === 'REPLACE') {
            if(selectedApplyReconArray.length === 0) {
                message.warning("Please choose at least one ");
                return;
            }
            const idList = selectedApplyReconArray.map(e => e.id);
            const ids_str = idList.join(',');
            this.props.openBatchChangeStatusModal(mode, idList);
        }else {
            if(selectedApplyReconArray.length === 0) {
                message.warning("Please choose at least one ");
                return;
            }
            const currentData = selectedApplyReconArray[0];
            if(currentData.state !== 'New' && currentData.state !== 'Reject' ) {
                message.warning("only be Del in New/Reject states.");
                return;
            }
            const idList = selectedApplyReconArray.map(e => e.id);
            this.props.openBatchChangeStatusModal(mode, idList);
        }
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'dataType', 'otherInfo', 'operateType', 'comments', 'before', 'after'];

    render() {
        const {
            locationOptions,
            fundCompanyOptions,
            loadApplyReconInfoActionStatus,
        } = this.props;
        const {
            id,
            location,
            fundCompany,
        } = this.props.applyReconQueryFields;

        const refreshBtnIsLoading = loadApplyReconInfoActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div className='ApplyReconWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        {/* <Breadcrumb.Item>Departments</Breadcrumb.Item>
                        <Breadcrumb.Item>Reporting Summary</Breadcrumb.Item> */}
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <Input
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="dataID" 
                        value={id}
                        onChange={(e) => {
                            this.onDataIDQueryInputChange({ name: 'id', value: e.target.value })
                        }}
                    />
                    {/* <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="location" 
                        value={location}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'location', value: val })
                        }} >
                        {locationOptions}
                    </Select>
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="fundCompany" 
                        value={fundCompany}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'fundCompany', value: val })
                        }} >
                        {fundCompanyOptions}
                    </Select> */}
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditApplyReconBtnOnClicked('Stock Pitch')} 
                        size='small'
                        className='positive-button'>
                        Stock Pitch
                    </Button>

                    <Button 
                        onClick={()=>this.addOrEditApplyReconBtnOnClicked('PaperPortfolioOrBook')} 
                        size='small'
                        className='positive-button'>
                        Paper / Book
                    </Button>

                    {/* <Button 
                        onClick={()=>this.addOrEditApplyReconBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add
                    </Button>

                    <Button 
                        onClick={()=>this.addOrEditApplyReconBtnOnClicked('COPY')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button> */}
                    <Button
                        onClick={()=>this.addOrEditApplyReconBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>
                    {/* <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('REPLACE')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negativeeee-button'>
                        REPLACE
                    </Button>
                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('PAUSE')} 
                        size='small'
                        type='primary'>
                        Pause
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('RESUME')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Resume
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'ApplyRecon'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button> */}

                </div>

                <div className='ApplyReconGrid'><ApplyReconGrid {...this.props} /></div>
                <AddOrEditApplyReconModalDialog {...this.props} />
                <BatchChangeStatusModalDialog {...this.props} />
                <FinishTaskModalDialog {...this.props} />
                {/* <OperationRecordDialog {...this.props} /> */}

            </div>
        );
    }
}

export default ApplyReconDashboard;
