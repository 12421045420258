import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker,message, Select, Button, Checkbox } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditWorkManagementModalDialog extends Component {

    refreshBtnOnClicked = () => {
        const {
            startDate,
            endDate,
        } = this.props.workManagementQueryFields;
        this.props.loadWorkManagementInfo({startDate,endDate});
    }

    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }
    
    componentDidUpdate() {
        const {
            addOrEditWorkManagementStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditWorkManagementModal;

        if(addOrEditWorkManagementStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' Reporting Summary succeeded.';
            message.success(msg);
            this.props.initSubmitSaveWorkManagementActionStatus();
            this.props.resetSubmitSaveWorkManagementModal();
            this.refreshBtnOnClicked();
        }
        if(addOrEditWorkManagementStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSaveWorkManagementActionStatus();
        }

    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditWorkManagementChangeInput({name, value});
    }
    onFrequencyInputChange = (value) => {
        this.onInputChange({name: 'frequency', value: value});
        this.onInputChange({name: 'deadline', value: undefined});
        this.clearDecomposedDeadline();
        this.onInputChange({name: 'notification', value: undefined});
    }
    onDeadlineNAInputChange = (value) => {
        const deadline = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
        this.clearDecomposedDeadline();
        // deadline是NA时，notification必须是NA，其他情况，清空notification重新填写
        this.onInputChange({name: 'notification', value: deadline});
        
    }
    onDeadlineEveryWorkdayInputChange = (value) => {
        const deadline = !!value ? 'EveryWorkday' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineEndOfMonthInputChange = (value) => {
        const deadline = !!value ? 'EndOfMonth' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineMonthInputChange = (value) => {
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineMonth', value: value});
    }
    // 清空分解后的deadline的各参数
    clearDecomposedDeadline = () => {
        this.onInputChange({name: 'deadlineMonth', value: undefined});
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineYear', value: undefined});
    }
    onNotificationNAInputChange = (value) => {
        const notification = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'notification', value: notification});
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }


    submitSaveWorkManagementModal = () => {
        const {
            mode,
        } = this.props.addOrEditWorkManagementModal;
        const {
            id,
            category,
            fundco,
            description,
            owner,
            startdate,
            targetdate,
            enddate,
            status,
            importance,
            emailTo,
            remarks,
            comments,

        } = this.props.addOrEditWorkManagementModal.fields;


        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 执行提交
            this.props.submitSaveWorkManagement({
                id,
                category,
                fundco,
                description,
                owner,
                startdate,
                targetdate,
                enddate,
                status,
                importance,
                emailTo,
                remarks,
                comments,
                mode,
            });

        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            locationOptions,
            fundCompanyOptions,
            frequencyOptions,
            weekdayOptions,
            monthOptions,
            responsibleDeptOptions,
            addOrEditWorkManagementModal,
            addOrEditWorkManagementStatus,
            userAccountEmailOptions,
            userAccountOptions,
            categoryOptions,
            fundCoOptions, 
            statusOptions, 
            importanceOptions,


        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditWorkManagementModal;

        const {
            id,
            category,
            fundco,
            description,
            owner,
            startdate,
            targetdate,
            enddate,
            status,
            importance,
            emailTo,

            remarks,
            comments,


        } = addOrEditWorkManagementModal.fields;

        const emailToArray = !emailTo ? [] : emailTo.split(';');


        const monthDateOptions = [];


        const startYearOptions = [];



        const modalTitle = this.getActionFromMode(mode) + ' Work Management22';
        const isSubmitLoading = addOrEditWorkManagementStatus === ACTION_STATUS.LOGINING;
        const startdatement = !startdate ? undefined : moment(startdate);
        const targetdatement = !targetdate ? undefined : moment(targetdate);
        const enddatement = !enddate ? undefined : moment(enddate);

        
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveWorkManagementModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveWorkManagementModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveWorkManagementModal() }>
                          Save
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Category">
                                    {getFieldDecorator('category', {
                                        initialValue: category,
                                        rules: [{
                                            required: true,
                                            message: 'Please select category',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'category', value: val })
                                            }} 
                                            placeholder="Please select category">
                                                {categoryOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Fund/Co.">
                                    {getFieldDecorator('fundco', {
                                        initialValue: fundco,
                                        rules: [{
                                            required: true,
                                            message: 'Please select fundco',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'fundco', value: val })
                                            }} 
                                            placeholder="Please select fundco">
                                                {fundCoOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Description*">
                                    {getFieldDecorator('description', {
                                        initialValue: description,
                                        rules: [{
                                            required: true,
                                            message: 'Please input description',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'description', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Owner">
                                        {getFieldDecorator('owner', {
                                            initialValue: owner,
                                            rules: [{
                                                required: false,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                allowClear
                                                mode='multiple'
                                                style={{ width: 300, marginLeft: 10 }}
                                                placeholder='Please select owner' 
                                                onChange={(val) => {
                                                    this.onEmailInputChange({ name: 'owner', value: val })
                                            }}>
                                                {userAccountOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Start Date">
                                {getFieldDecorator('startdate', {
                                    initialValue: startdatement,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Start Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'startdate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Target Date">
                                {getFieldDecorator('targetdate', {
                                    initialValue: targetdatement,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Target Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'targetdate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="End Date">
                                {getFieldDecorator('enddate', {
                                    initialValue: enddatement,
                                    rules: [{
                                        required: false,
                                        message: 'Please select End Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'enddate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                    {getFieldDecorator('status', {
                                        initialValue: status,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Status',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'status', value: val })
                                            }} 
                                            placeholder="Please select Status">
                                                {statusOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Importance">
                                    {getFieldDecorator('importance', {
                                        initialValue: importance,
                                        rules: [{
                                            required: true,
                                            message: 'Please select importance',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'importance', value: val })
                                            }} 
                                            placeholder="Please select importance">
                                                {importanceOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>


                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditWorkManagementModalDialogForm = Form.create({ name: 'AddOrEditWorkManagementModalDialog' })(AddOrEditWorkManagementModalDialog);

export default AddOrEditWorkManagementModalDialogForm;
