import React, { Component } from 'react';
// import { AgGridReact } from "@ag-grid-community/react";
import { AgGridReact } from "ag-grid-react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';
import StateSynchronizer from '../../../../utils/StateSynchronizer';
import JsonTooltipComponent from '../../../../utils/JsonTooltipComponent';
// import TagComponent from '../../../../utils/TagComponent';
class WorkManagementGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
                {field: 'id', headerName: "dataID", cellClass: 'non-number', width: 120, 
                cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
                cellStyle: (params) => {
                    if (typeof params.data == 'undefined') return {}
            
                    if ( params.data.tasksFlag === false ) return { color: '#fff', backgroundColor: '#999494' }
            
                    return {}
                  },},
                {field: 'category', headerName: 'Category', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                {field: 'fundco', headerName: 'Fund/Co', cellClass: 'non-number', width: 100},
                {field: 'description', headerName: 'Description', cellClass: 'non-number', width: 100,cellRenderer: 'jsonTooltipComponent'},
                {field: 'status', headerName: 'Status', cellClass: 'non-number', width: 100},
                {field: 'remarks', headerName: 'remarks', cellClass: 'non-number', width: 200,cellRenderer: 'jsonTooltipComponent'},
                {field: 'comments', headerName: 'comments', cellClass: 'non-number', width: 200,cellRenderer: 'jsonTooltipComponent'},
                {field: 'startdate', headerName: 'Start Date', cellClass: 'non-number', width: 150},
                {field: 'targetdate', headerName: 'Target Date', cellClass: 'non-number', width: 100},
                {field: 'enddate', headerName: 'End Date', cellClass: 'non-number', width: 100},
                {field: 'complete', headerName: '% Complete', cellClass: 'non-number', width: 120},
                {field: 'importance', headerName: 'Importance', cellClass: 'non-number', width: 120},
                {field: 'owner', headerName: 'Owner', cellClass: 'non-number', width: 100},
                {field: 'modifieTime', headerName: 'Last Update Date', cellClass: 'non-number', width: 150},

                // {field: 'emailCC', headerName: 'emailCC', cellClass: 'non-number', width: 150},
                // {field: 'regulation', headerName: 'regulation', cellClass: 'non-number', width: 150},

                {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 120},
                {field: 'createTime', headerName: 'createdTime', cellClass: 'non-number', width: 150},
                {field: 'modifiedBy', headerName: 'modifiedBy', cellClass: 'non-number', width: 120},
     
            ],
            frameworkComponents: {
                jsonTooltipComponent: JsonTooltipComponent,
                // tagComponent: TagComponent
              },
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            rowClassRules: {
                'MarkBackgroundRed': function(params) {
                    const today = moment().format('YYYY-MM-DD');
                    const taskList = params.data.workManagementSubtasksList;
                    const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                    return redTaskList.length > 0;
                },
                'MarkBackgroundGreen': function(params) {
                    const today = moment().format('YYYY-MM-DD');
                    const taskList = params.data.workManagementSubtasksList;
                    const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                    if(redTaskList.length > 0) {
                        return false;
                    }
                    const greedTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline >= today);
                    return greedTaskList.length > 0;
                },
                'MarkBackgroundGray': function(params) {
                    const today = moment().format('YYYY-MM-DD');
                    const taskList = params.data.workManagementSubtasksList;
                    const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                    if(redTaskList.length > 0) {
                        return false;
                    }
                    const greedTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline >= today);
                    if(greedTaskList.length > 0) {
                        return false;
                    }
                    return params.data.status === 'Paused';
                },
            },


            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'id', headerName: 'id', cellClass: 'non-number', width: 120 , cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                        {field: 'workid', headerName: 'workid', cellClass: 'non-number', width: 150},
                        {field: 'details', headerName: 'Details', cellClass: 'non-number', width: 150},
                        {field: 'startdate', headerName: 'Start Date', cellClass: 'non-number', width: 120},
                        //{field: 'targetdate', headerName: 'Target Date', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                        {field: 'targetdate', headerName: 'Target Date', cellClass: 'non-number', width: 100},
                        {field: 'enddate', headerName: 'End Date', cellClass: 'non-number', width: 250},
                        {field: 'personlncharge', headerName: 'PersonInCharge', cellClass: 'non-number', width: 150},
                        {field: 'status', headerName: 'Status', cellClass: 'non-number', width: 120},
                        {field: 'remarks', headerName: 'remarks', cellClass: 'non-number', width: 200,cellRenderer: 'jsonTooltipComponent'},
                        {field: 'comments', headerName: 'comments', cellClass: 'non-number', width: 200},
                        {field: 'modifiedTime', headerName: 'Last update date', cellClass: 'non-number', width: 150},
                        {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    frameworkComponents: {
                        jsonTooltipComponent: JsonTooltipComponent,
                        // tagComponent: TagComponent
                      },
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectWorkManagementTask(cptRcdDataArray);
                    },

                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.workManagementSubtasksList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">Work Management Sub</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },

            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        }
    }


    dataStatusColorCellStyle(param) {
        const status = param.value;
        if(status === 'Working' || status === 'Finished') {
            return {color: 'green'};
        }
        return {color: 'black'};
    }


    finishTaskOpenModalBtn = (node, props) => {
        const retDiv = document.createElement('div');
        const data = node.data;
        if(data.status === 'Finished') {
            return retDiv;
        }
        const finishTaskBtn = document.createElement('button');
        const finishTaskText = document.createTextNode("FinishTask");
        finishTaskBtn.appendChild(finishTaskText);
        finishTaskBtn.onclick = () => {
            props.openFinishTaskModal(data);
        }
        retDiv.appendChild(finishTaskBtn);
        return retDiv;
    }


    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let workManagementArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectWorkManagement(workManagementArray);
    }
    onDataSelectionTaskChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let workManagementSubArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectWorkManagementTask(workManagementSubArray);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        const COLUMNS_KEY = 'work-management-list-grid-col-state';
    
        StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
      };
    render() {
        const {
            loadWorkManagementInfoActionStatus,
            loadWorkManagementInfoResp,
        } = this.props;

        const isLoading = loadWorkManagementInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadWorkManagementInfoArray = [];
        if(!!loadWorkManagementInfoResp && !!loadWorkManagementInfoResp.data) {
            loadWorkManagementInfoArray = loadWorkManagementInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadWorkManagementInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            resizable={true}
                            sideBar={true}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            frameworkComponents={this.state.frameworkComponents}
                            onSelectionChanged={this.onDataSelectionChanged}
                            onGridReady={this.onGridReady}
                        />
                    )}
            </div>
        );
    }

}

export default WorkManagementGrid;
