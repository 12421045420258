import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';


const validAgreementStatus = ['New', 'Valid', 'Rejected', 'Submitted', 'RequestExpired'];

class GeneralAgreementGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {field: 'id', headerName: 'dataID', cellClass: 'non-number', width: 100, checkboxSelection: true},
                {field: 'status', headerName: 'status', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                {field: 'title', headerName: 'title', cellClass: 'non-number', width: 200},
                {field: 'department', headerName: 'department', cellClass: 'non-number', width: 100},
                {field: 'personInCharge', headerName: 'personInCharge', cellClass: 'non-number', width: 150},
                {field: 'company', headerName: 'company', cellClass: 'non-number', width: 100},
                {field: 'counterparty', headerName: 'counterparty', cellClass: 'non-number', width: 150},
                {field: 'agreementType', headerName: 'agreementType', cellClass: 'non-number', width: 100},
                {field: 'executionDate', headerName: 'executionDate', cellClass: 'non-number', width: 100},
                {field: 'expiryDate', headerName: 'expiryDate', cellClass: 'non-number', width: 100},
                {field: 'noticePeriod', headerName: 'noticePeriod', cellClass: 'non-number', width: 80, cellRenderer: this.noticePeriodCellRenderer},
                {field: '_1stNoticeDate', headerName: 'notifyStartDate', cellClass: 'non-number', width: 100},
                {field: 'amount', headerName: 'amount', cellClass: 'number', width: 100},
                {field: 'amountCCY', headerName: 'amountCCY', cellClass: 'non-number', width: 100},
                {field: 'payer', headerName: 'payer', cellClass: 'non-number', width: 100},
                {field: 'terms', headerName: 'terms', cellClass: 'non-number', width: 200},
                {field: 'uploadOriName', headerName: 'upload', cellClass: 'non-number', width: 150, cellRenderer: this.downloadGeneralAgreementCellRenderer},
                {field: 'remarks', headerName: 'remarks', cellClass: 'non-number', width: 200},
                {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 120},
                {field: 'createdTime', headerName: 'createdTime', cellClass: 'non-number', width: 150},
                {field: 'updatedBy', headerName: 'updatedBy', cellClass: 'non-number', width: 120},
                {field: 'updatedTime', headerName: 'updatedTime', cellClass: 'non-number', width: 150},
            ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                'MarkBackgroundRed': function(params) {
                    return params.data.bgColor === 'Red';
                },
                'MarkBackgroundGreen': function(params) {
                    return params.data.bgColor === 'Green';
                },
                'MarkBackgroundGray': function(params) {
                    return params.data.bgColor === 'Gray';
                },
                'MarkBackgroundYellow': function(params) {
                    return params.data.bgColor === 'Yellow';
                },
            },
            statusBar: {
            },
        };
    }

    generalAgreementDownloadUrl = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.GENERAL_AGREEMENT_DOWNLOAD_URL
    downloadGeneralAgreementCellRenderer = (node) => {
        const uploadOriName = node.data.uploadOriName;
        const uploadRealName = node.data.uploadRealName;
        if (!!uploadOriName && !!uploadRealName) {
            const url = this.generalAgreementDownloadUrl + '/' + uploadRealName;
            let link = document.createElement('a');
            link.innerText = uploadOriName;
            link.target = '_blank';
            link.href = url;
            return link;
        }
        return '';
    }

    noticePeriodCellRenderer = (node) => {
        return node.data.noticePeriod + ' days';
    }


    dataStatusColorCellStyle(param) {
        const status = param.value;
        if(status === 'Valid') {
            return {color: 'green'};
        }
        if(status === 'Rejected') {
            return {color: 'red'};
        }
        return {color: 'black'};
    }


    getRowNodeId = data => {
        return data.id;
    };


    onGridReady = (event) => {
        this.props.setGridEventOnState(event);
    }


    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let generalAgreementArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectGeneralAgreement(generalAgreementArray);
    }

    render () {
        const {
            loadGeneralAgreementActionStatus,
            loadGeneralAgreementResp,
            onlyShowValidGeneralAgreement,
        } = this.props;

        const isLoading = loadGeneralAgreementActionStatus === ACTION_STATUS.LOGINING;
        let loadGeneralAgreementArray = [];
        if(!!loadGeneralAgreementResp && !!loadGeneralAgreementResp.data) {
            loadGeneralAgreementArray = loadGeneralAgreementResp.data;
        }
        if(onlyShowValidGeneralAgreement) {
            loadGeneralAgreementArray = loadGeneralAgreementArray.filter(a => validAgreementStatus.filter(s => s === a.status).length > 0);
        }

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadGeneralAgreementArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // event
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onDataSelectionChanged}
                        />
                )}
            </div>
        );
    }
}


export default GeneralAgreementGrid;
