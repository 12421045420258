import _ from 'lodash';
import EntityMap from './EntityMap';
import { localStorageClient } from './localStorageClient';
const StateSynchronizer = {
  syncGrid(params, columnDefs, key) {
    const getState = () => localStorageClient.get(key);
    const saveState = s => localStorageClient.save(key, s);

    const oldColumnState = getState();
    const columnStateMap = EntityMap.fromArray(oldColumnState, 'colId');
    const columnDefsMap = EntityMap.fromArray(columnDefs, 'field');
    const existingColStates = (oldColumnState || [])
      .filter(c => columnDefsMap.keys.includes(c.colId))
      .map(c => {
        const matchedColumnDef = EntityMap.get(columnDefsMap, c.colId, null);
        return {
          ...c,
          pinned: matchedColumnDef.pinned,
          aggFunc: matchedColumnDef.aggFunc
        };
      });

    const newColStates = columnDefs
      .filter(cd => !columnStateMap.keys.includes(cd.field))
      .map(cd => {
        return {
          aggFunc: cd.aggFunc,
          colId: cd.field,
          hide: existingColStates.length > 0 || cd.hide,
          pinned: cd.pinned,
          pivotIndex: cd.pivotIndex,
          rowGroupIndex: cd.rowGroupIndex,
          width: 80
        };
      });

    try {
      params.columnApi.setColumnState(existingColStates.concat(newColStates));
    } catch (ex) {
      console.error(`Fail to save column state for: ${key}`);
    }

    const saveColumnState = _.debounce(
      () => saveState(params.columnApi.getColumnState()),
      500
    );

    params.api.addGlobalListener((type, event) => {
      if (type === 'columnHoverChanged') return;

      if (type.indexOf('column') >= 0) {
        saveColumnState();
      }
    });

    // Do initial saving after loading page.
    saveColumnState();
  }
};

export default StateSynchronizer;
