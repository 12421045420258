import React, { Component } from 'react';

import { AgGridReact } from "ag-grid-react";
import { Button } from 'antd';

class FundBookListGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          field: 'fund',
          headerName: "Fund",
          cellClass: 'non-number',
          width: 160,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        {
          field: 'book',
          headerName: "Book",
          cellClass: 'non-number',
          width: 120,
        }],
      rowSelection: "multiple",
      context: { componentParent: this },
      frameworkComponents: {
      },
      selectedRow: null
    };
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.closeToolPanel();
  };

  addFundBookBtnClick = () => {
    this.props.openRestrictedRuleFundBookDialog();
  }

  removeFundBookBtnClick = () => {
    const {
      fields
    } = this.props.restrictedRuleEditControlModal;
    const {
      selectedRow
    } = this.state;
    if(selectedRow){
      const fundBookList = fields.fundBookList;
      selectedRow.forEach(element => {
        fundBookList.forEach((ele, index)=>{
          if(ele.fund === element.fund && ele.book === element.book){
            fundBookList.splice(index, 1); 
          }
        })
      });
      const updatedFields = {
        ...fields,
        fundBookList
      }
      this.props.restrictedRuleEditChangeInput({ name: 'fields', value: updatedFields });
    }
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityRequestArray = rowNodeArray.map(rowNode => rowNode.data);

    this.setState({
      selectedRow: restrictedSecurityRequestArray
    });
  }

  render() {
    const {
        fields,
        className = 'grid-wrapper'
      } = this.props.restrictedRuleEditControlModal;

    let data = fields.fundBookList?fields.fundBookList:[];
    data = data.filter(r => r.fund)

    return (
      <div  className='restrictedSecurityGrid' style={{height: '240px'}}>
        <div>
            <Button 
            onClick={this.addFundBookBtnClick} 
            size='small'
            className='positive-button'>
               Add
           </Button>
                       
           <Button 
             onClick={this.removeFundBookBtnClick} 
             style= {{marginLeft : '10px'}}
             size='small'
             type='primary'>
               Remove
           </Button>
        </div>   
        <div className={`ag-theme-balham ${className}`} style= {{height: '80%'}}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={data}
            rowSelection={this.state.rowSelection}
            defaultColDef={this.state.defaultColDef}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={false}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        </div>   
      </div>
      
    );
  }
}

export default FundBookListGrid;