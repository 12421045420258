import React, { Component } from 'react';
import { Modal, Button, message } from 'antd';

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import CheckHoldingsGrid from './CheckHoldingsGrid';

class CheckHoldingsPageDialog extends Component {

    componentDidMount() {
        
    }

    componentDidUpdate() {
        const {
            queryHoldingsForCurrAccountStatus,
            queryHoldingsForCurrAccountResp,
        } = this.props;
        if(queryHoldingsForCurrAccountStatus === ACTION_STATUS.SUCCESS) {
            this.props.initQueryHoldingsForCurrAccountActionStatus();
        }
        if(queryHoldingsForCurrAccountStatus === ACTION_STATUS.ERROR) {
            let err = 'Failed on query holdings for current account';
            if(!!queryHoldingsForCurrAccountResp && !!queryHoldingsForCurrAccountResp.respMessage) {
                err = queryHoldingsForCurrAccountResp.respMessage;
            }
            message.error(err);
            this.props.initQueryHoldingsForCurrAccountActionStatus();
        }
    }


    queryHoldingsForCurrAccount = () => {
        const {
            checkHoldingsPageModal,
        } = this.props;
        const {
            accountGuid,
        } = checkHoldingsPageModal;
        this.props.queryHoldingsForCurrAccount(accountGuid);
    }


    render() {
        const {
            checkHoldingsPageModal,
            queryHoldingsForCurrAccountStatus,
        } = this.props;
        const {
            isOpen,
        } = checkHoldingsPageModal;

        const queryHoldingsForCurrAccountIsLoading  = queryHoldingsForCurrAccountStatus === ACTION_STATUS.LOGINING;

        return (
            <div className="tradeDashboardWrapper">
                <Modal
                centered
                width={1000}
                title={'Check Holdings'}
                visible={isOpen}
                onCancel={() => this.props.resetCheckHoldingsPage() }
                footer={[
                    <Button key="back" onClick={() => this.props.resetCheckHoldingsPage()}>
                      Close
                    </Button>,
                ]}
                destroyOnClose={true}
                maskClosable={false}
            >  
                <div>
                    <Button type="primary" 
                        onClick={this.queryHoldingsForCurrAccount} 
                        loading={queryHoldingsForCurrAccountIsLoading}
                        style={{ fontSize: 16, marginLeft: 5 }} 
                        size="small"
                        icon="search" />
                    &nbsp;&nbsp;(Please click on one holding to select the related equity)
                </div>
                <div className='CheckHoldingsGrid'><CheckHoldingsGrid {...this.props} /></div>
                
            </Modal>
            </div>
        );
    }

}


export default CheckHoldingsPageDialog;
