import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { localStorageClient } from '../../utils/localStorageClient';
import { httpClient } from '../../net/httpClient';
import UrlConfig from '../../net/UrlConfig';


const clearLoginInfo = () => {
  
};

const PrivateRoute = (params) => {
  const { component, ...rest } = params;
  const { pathname, search } = params.location;

  let accessToken = null;
  let searchArray = [];
  if(!!search) {
    searchArray = search.substring(1).split('&');
    accessToken = searchArray.filter(e => e.split('=')[0] === 'accessToken')
                              .map(e => e.split('=')[1]);
    accessToken = accessToken[0];
  }
  
  // 其他系统会在queryString里带着accessToken跳转到CMS Report端
  // 若有accessToken，则需要根据此token做验证
  if(!!accessToken) {
    httpClient
        .post(UrlConfig.LOGIN_BY_ACESS_TOKEN, {accessToken})
        .then(resp => {
          if(!!resp && resp.respCode === 'S0001') {
            // queryString中除了accessToken以外的其他部分
            const newSearchArray  = searchArray.filter(e => e.split('=')[0] !== 'accessToken')
            let newQueryString = '';
            if(!!newSearchArray && newSearchArray.length > 0) {
              newQueryString = '?' + newSearchArray.join('&');
            }
            window.location.href = pathname + newQueryString;
          } else {
            clearLoginInfo();
          }
          
        })
        .catch(err => {
          clearLoginInfo();
        });


  }
  
  // 没有accessToken，正常跳转
  if(localStorageClient.isLoggedIn()) {
    return (
      <Route
      {...rest}
      render={props => React.createElement(component, props) }
    />
    );
  } else {
    clearLoginInfo();
    return  (
      <Redirect
        to={{
          pathname: '/login'
        }}
      />
    )
  }
  
};

export default PrivateRoute;
