import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';
import { localStorageClient } from '../../../utils/localStorageClient';


import {
    // query
    START_LOAD_MEETING_AND_TRAINING_INFO,
    LOAD_MEETING_AND_TRAINING_INFO_SUCCESS,
    LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
    INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    // select
    SELECT_MEETING_AND_TRAINING,
    SET_GRID_EVENT_ON_STATE,
    // add or edit
    OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
    RESET_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL,
    ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
    START_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL,
    SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_SUCCESS,
    SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_FAILURE,
    INIT_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_ACTION_STATUS,
    // change status
    OPEN_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL,
    RESET_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL,
    CHANGE_MEETING_AND_TRAINING_STATUS_CHANGE_INPUT,
    START_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS,
    SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_SUCCESS,
    SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_FAILURE,
    INIT_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_ACTION_STATUS,
    // showAllshowValid
    SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,


} from './MeetingAndTrainingConstants';


// query
function startLoadMeetingAndTrainingInfo() {
    return {
      type: START_LOAD_MEETING_AND_TRAINING_INFO,
    };
}
export function loadMeetingAndTrainingInfo(params) {
    const employee = localStorageClient.getUserName();
    const queryParam = {

        type: params.type,
        starttime: params.starttime,
        endtime: params.endtime,
        speaker: params.speaker,
        person: employee,

    };
    return function(dispatch) {
        dispatch(startLoadMeetingAndTrainingInfo());
        httpClient
          .get(UrlConfig.LOAD_MEETING_AND_TRAINING_INFO, queryParam)
          .then(resp => dispatch(loadMeetingAndTrainingInfoSuccess(resp)))
          .catch(err => dispatch(loadMeetingAndTrainingInfoFailure(err)));
    };
}
function loadMeetingAndTrainingInfoSuccess(resp) {
    return {
      type: LOAD_MEETING_AND_TRAINING_INFO_SUCCESS,
      payload: resp,
    };
}
function loadMeetingAndTrainingInfoFailure(err) {
    return {
        type: LOAD_MEETING_AND_TRAINING_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadMeetingAndTrainingInfoActionStatus() {
    return {
      type: INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS,
    };
}


// select
export function selectMeetingAndTraining(array) {
    return {
        type: SELECT_MEETING_AND_TRAINING,
        payload: array,
    };
}
export function setGridEventOnState(event) {
    return {
        type: SET_GRID_EVENT_ON_STATE,
        payload: event,
    }; 
}


// add or edit
export function openAddOrEditMeetingAndTrainingModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function resetSubmitSaveMeetingAndTrainingModal() {
    return {
        type: RESET_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL,
    };
}
export function addOrEditGeneralAgreemenChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startSubmitSaveMeetingAndTrainingModal() {
    return {
      type: START_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL,
    };
}
export function submitSaveMeetingAndTrainingModal(mode, bean) {
    let url = UrlConfig.UPDATE_MEETING_AND_TRAINING_INFO;  // ADD, COPY
    if(mode === 'Feedback') {
        url = UrlConfig.UPDATE_FEEDBACKMEETING_AND_TRAINING_INFO;
    }
    return function(dispatch) {
        dispatch(startSubmitSaveMeetingAndTrainingModal());
        httpClient
          .post(url, bean)
          .then(resp => dispatch(submitSaveMeetingAndTrainingModalSuccess(resp)))
          .catch(err => dispatch(submitSaveMeetingAndTrainingModalFailure(err)));
    };
}
function submitSaveMeetingAndTrainingModalSuccess(resp) {
    return {
      type: SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_SUCCESS,
      payload: resp,
    };
}
function submitSaveMeetingAndTrainingModalFailure(err) {
    return {
        type: SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveMeetingAndTrainingModalActionStatus() {
    return {
      type: INIT_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_ACTION_STATUS,
    };
}


// change status
export function openChangeMeetingAndTrainingStatusModal(mode, idList) {
    return {
        type: OPEN_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetChangeMeetingAndTrainingStatusModal() {
    return {
        type: RESET_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL,
    };
}
export function changeGeneralAgreemenStatusChangeInput({name, value}) {
    return {
        type: CHANGE_MEETING_AND_TRAINING_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startSubmitChangeMeetingAndTrainingStatusModal() {
    return {
      type: START_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS,
    };
}
export function submitChangeMeetingAndTrainingStatusModal(bean) {
    if(bean.modes === 'Sign In' ){
        return function(dispatch) {
            dispatch(startSubmitChangeMeetingAndTrainingStatusModal());
            httpClient
              .post(UrlConfig.SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS, bean)
              .then(resp => dispatch(submitChangeMeetingAndTrainingStatusModalSuccess(resp)))
              .catch(err => dispatch(submitChangeMeetingAndTrainingStatusModalFailure(err)));
        };
    }else if(bean.modes === 'Notifi' ||  bean.modes === 'NotifiRej' ){
        const employee = localStorageClient.getUserName();
        const queryParam = {
            modes: bean.modes,
            person: employee,
    
        };
        return function(dispatch) {
            dispatch(startSubmitChangeMeetingAndTrainingStatusModal());
            httpClient
              .post(UrlConfig.SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS, queryParam)
              .then(resp => dispatch(submitChangeMeetingAndTrainingStatusModalSuccess(resp)))
              .catch(err => dispatch(submitChangeMeetingAndTrainingStatusModalFailure(err)));
        };
    }else{
        return function(dispatch) {
            dispatch(startSubmitChangeMeetingAndTrainingStatusModal());
            httpClient
              .post(UrlConfig.APPLY_CHANGE_MEETING_AND_TRAINING_STATUS, bean)
              .then(resp => dispatch(submitChangeMeetingAndTrainingStatusModalSuccess(resp)))
              .catch(err => dispatch(submitChangeMeetingAndTrainingStatusModalFailure(err)));
        };
    }
}
function submitChangeMeetingAndTrainingStatusModalSuccess(resp) {
    return {
      type: SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_SUCCESS,
      payload: resp,
    };
}
function submitChangeMeetingAndTrainingStatusModalFailure(err) {
    return {
        type: SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_FAILURE,
        payload: err,
    };
}
export function initSubmitChangeMeetingAndTrainingStatusModalActionStatus() {
    return {
      type: INIT_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_ACTION_STATUS,
    };
}


// showAllshowValid
export function showAllShowValidBtnOnClicked() {
    return {
        type: SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,
    }
}
