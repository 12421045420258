import {connect} from 'react-redux';
import CollegeApplicationDashboard from '../components/CollegeApplicationDashboard';

import {
    // query
    loadCollegeApplicationInfo,
    initLoadCollegeApplicationInfoActionStatus,
    // select
    selectCollegeApplication,
    setGridEventOnState,
    // add or edit
    openAddOrEditCollegeApplicationModal,
    resetSubmitSaveCollegeApplicationModal,
    addOrEditGeneralAgreemenChangeInput,
    submitSaveCollegeApplicationModal,
    initSubmitSaveCollegeApplicationModalActionStatus,
    // change status
    openChangeCollegeApplicationStatusModal,
    resetChangeCollegeApplicationStatusModal,
    changeGeneralAgreemenStatusChangeInput,
    submitChangeCollegeApplicationStatusModal,
    initSubmitChangeCollegeApplicationStatusModalActionStatus,
    // showAllshowValid
    showAllShowValidBtnOnClicked,
    

} from '../CollegeApplicationActions';


const mapStateToProps = state => {
    return {
        // options
        departmentOptions: state.collegeApplication.departmentOptions,
        typeOptions: state.collegeApplication.typeOptions,
        companyOptions: state.collegeApplication.companyOptions,
        agreementTypeOptions: state.collegeApplication.agreementTypeOptions,
        userAccountOptions: state.collegeApplication.userAccountOptions,

        noticePeriodOptions: state.collegeApplication.noticePeriodOptions,
        ccyOptions: state.collegeApplication.ccyOptions,
        // upload/download
        collegeApplicationUploadUrl: state.collegeApplication.collegeApplicationUploadUrl,
        collegeApplicationDownloadUrl: state.collegeApplication.collegeApplicationDownloadUrl,
                // collegeApplicationQueryFields
                collegeApplicationQueryFields: state.collegeApplication.collegeApplicationQueryFields,
        // query
                // query
                // loadCollegeApplicationInfoActionStatus: state.collegeApplication.loadCollegeApplicationInfoActionStatus,
                // loadCollegeApplicationInfoResp: state.collegeApplication.loadCollegeApplicationInfoResp,
        collegeApplicationGridEvent: state.collegeApplication.collegeApplicationGridEvent,
        selectedCollegeApplicationArray: state.collegeApplication.selectedCollegeApplicationArray,
        loadCollegeApplicationActionStatus: state.collegeApplication.loadCollegeApplicationActionStatus,
        loadCollegeApplicationResp: state.collegeApplication.loadCollegeApplicationResp,
        // select
        selectedCollegeApplicationArray: state.collegeApplication.selectedCollegeApplicationArray,
        // add or edit
        addOrEditCollegeApplicationModal: state.collegeApplication.addOrEditCollegeApplicationModal,
        submitSaveCollegeApplicationActionStatus: state.collegeApplication.submitSaveCollegeApplicationActionStatus,
        // change status
        changeCollegeApplicationStatusModal: state.collegeApplication.changeCollegeApplicationStatusModal,
        submitChangeCollegeApplicationStatusActionStatus: state.collegeApplication.submitChangeCollegeApplicationStatusActionStatus,
        // showAllshowValid
        onlyShowValidCollegeApplication: state.collegeApplication.onlyShowValidCollegeApplication,
        
    };
};


const mapDispatchToProps = dispatch => {
    return {
        // query
        // loadCollegeApplicationInfo: () => dispatch(loadCollegeApplicationInfo()),
        loadCollegeApplicationInfo: (params) => dispatch(loadCollegeApplicationInfo(params)),
        initLoadCollegeApplicationInfoActionStatus: () => dispatch(initLoadCollegeApplicationInfoActionStatus()),
        // select
        selectCollegeApplication: (array) => dispatch(selectCollegeApplication(array)),
        setGridEventOnState: (event) => dispatch(setGridEventOnState(event)),
        // add or edit
        openAddOrEditCollegeApplicationModal: (mode, data) => dispatch(openAddOrEditCollegeApplicationModal(mode, data)),
        resetSubmitSaveCollegeApplicationModal: () => dispatch(resetSubmitSaveCollegeApplicationModal()),
        addOrEditGeneralAgreemenChangeInput: (input) => dispatch(addOrEditGeneralAgreemenChangeInput(input)),
        submitSaveCollegeApplicationModal: (mode, bean) => dispatch(submitSaveCollegeApplicationModal(mode, bean)),
        initSubmitSaveCollegeApplicationModalActionStatus: () => dispatch(initSubmitSaveCollegeApplicationModalActionStatus()),
        // change status
        openChangeCollegeApplicationStatusModal: (mode, idList) => dispatch(openChangeCollegeApplicationStatusModal(mode, idList)),
        resetChangeCollegeApplicationStatusModal: () => dispatch(resetChangeCollegeApplicationStatusModal()),
        changeGeneralAgreemenStatusChangeInput: (param) => dispatch(changeGeneralAgreemenStatusChangeInput(param)),
        submitChangeCollegeApplicationStatusModal: (bean) => dispatch(submitChangeCollegeApplicationStatusModal(bean)),
        initSubmitChangeCollegeApplicationStatusModalActionStatus: () => dispatch(initSubmitChangeCollegeApplicationStatusModalActionStatus()),
        // showAllshowValid
        showAllShowValidBtnOnClicked: () => dispatch(showAllShowValidBtnOnClicked()),

    };
};


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(CollegeApplicationDashboard);

