
const expenseSummaryNewGridColumns = [
    {
        field: 'id',
        headerName: "ID",
        cellClass: 'non-number',
        width: 80,
        cellRenderer: 'agGroupCellRenderer', 
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
    },
    {
        field: 'checkStatue',
        headerName: "CheckStatus",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'approvalStatus',
        headerName: "Approval Status",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'callBackOrOlb',
        headerName: "CallBack/OLB",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'payDate',
        headerName: "Pay Date",
        cellClass: 'non-number',
        width: 100,
    },

    {
        field: 'category',
        headerName: "Category",
        cellClass: 'non-number',
        width: 100,
    },

    {
        field: 'billingEntity',
        headerName: "Billing Entity",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'payee',
        headerName: "Payee",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'payer',
        headerName: "Payer",
        cellClass: 'non-number',
        width: 120,
    },
    {
        field: 'invNo',
        headerName: "Inv No.",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'invDate',
        headerName: "Inv Date",
        cellClass: 'non-number',
        width: 100,
    },

    {
        field: 'ccy',
        headerName: "CCY",
        cellClass: 'non-number',
        width: 60,
    },
    {
        field: 'amount',
        headerName: "Amount",
        cellClass: 'number',
        width: 100,
    },
    {
        field: 'fx',
        headerName: "FX",
        cellClass: 'number',
        width: 60,
    },
    {
        field: 'amtUSD',
        headerName: "USD Amt",
        cellClass: 'number',
        width: 100,
    },
    {
        field: 'frequency',
        headerName: "Frequency",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'description',
        headerName: "Description",
        cellClass: 'non-number',
        width: 200,
        //cellRenderer: 'jsonTooltipComponent'
    },
    {
        field: 'filePath',
        headerName: "Attachment",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'approver',
        headerName: "Approver",
        cellClass: 'non-number',
        width: 100,
    },
    // {
    //     field: 'firm',
    //     headerName: "Firm",
    //     cellClass: 'non-number',
    //     width: 120,
    // },
    // {
    //     field: 'fundFirmRelated',
    //     headerName: "Fund/Firm related",
    //     cellClass: 'non-number',
    //     width: 100,
    // },
    {
        field: 'payMethod',
        headerName: "Payment Instruction",
        cellClass: 'non-number',
        width: 100,
    },

    {
        field: 'broker',
        headerName: "Broker",
        cellClass: 'non-number',
        width: 200,
    },


    {
        field: 'remarks',
        headerName: "Remarks",
        cellClass: 'non-number',
        width: 200,
        //cellRenderer: 'jsonTooltipComponent'
    },

    {
        field: 'createdBy',
        headerName: "createdBy",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'createTime',
        headerName: "createTime",
        cellClass: 'non-number',
        width: 150,
    },
    {
        field: 'lastUpdatedBy',
        headerName: "lastUpdatedBy",
        cellClass: 'non-number',
        width: 100,
    },
    {
        field: 'lastUpdateTime',
        headerName: "lastUpdateTime",
        cellClass: 'non-number',
        width: 150,
    },
];


const GridColumnMap = {
    expenseSummaryNewGridColumns,
};

export default GridColumnMap;

