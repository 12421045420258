import {connect} from 'react-redux';
import QuestionnaireRequestDashboard from '../components/QuestionnaireRequestDashboard';

import {
    // do query
    loadQuestionnaireResults,
    initLoadQuestionnaireResultsActionStatus,

} from '../QuestionnaireRequestActions';


const mapStateToProps = state => {
    return {
        // do query
        loadQuestionnaireResultsActionStatus: state.questionnaire.loadQuestionnaireResultsActionStatus,
        loadQuestionnaireResultsActionResp: state.questionnaire.loadQuestionnaireResultsActionResp,
        questionnaireResultGridColumns: state.questionnaire.questionnaireResultGridColumns,
        questionAnswerGridColumns: state.questionnaire.questionAnswerGridColumns,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // do query
        loadQuestionnaireResults: (params) => dispatch(loadQuestionnaireResults(params)),
        initLoadQuestionnaireResultsActionStatus: () => dispatch(initLoadQuestionnaireResultsActionStatus()),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(QuestionnaireRequestDashboard);
