// query
export const ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE = 'ON_WORK_MANAGEMENT_QUERY_INPUT_CHANGE';
export const START_LOAD_WORKING_MANAGEMENT_INFO = 'START_LOAD_WORKING_MANAGEMENT_INFO';
export const LOAD_WORKING_MANAGEMENT_INFO_SUCCESS = 'LOAD_WORKING_MANAGEMENT_INFO_SUCCESS';
export const LOAD_WORKING_MANAGEMENT_INFO_FAILURE = 'LOAD_WORKING_MANAGEMENT_INFO_FAILURE';
export const INIT_LOAD_WORKING_MANAGEMENT_INFO_ACTION_STATUS = 'INIT_LOAD_WORKING_MANAGEMENT_INFO_ACTION_STATUS';
// select
export const SELECT_WORKING_MANAGEMENT = 'SELECT_WORKING_MANAGEMENT';
// add or edit
export const OPEN_ADD_OR_EDIT_WORKING_MANAGEMENT_MODAL = 'OPEN_ADD_OR_EDIT_WORKING_MANAGEMENT_MODAL';
export const ADD_OR_EDIT_WORKING_MANAGEMENT_CHANGE_INPUT = 'ADD_OR_EDIT_WORKING_MANAGEMENT_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_WORKING_MANAGEMENT_MODAL = 'RESET_SUBMIT_SAVE_WORKING_MANAGEMENT_MODAL';
export const START_SUBMIT_SAVE_WORKING_MANAGEMENT = 'START_SUBMIT_SAVE_WORKING_MANAGEMENT';
export const SUBMIT_SAVE_WORKING_MANAGEMENT_SUCCESS = 'SUBMIT_SAVE_WORKING_MANAGEMENT_SUCCESS';
export const SUBMIT_SAVE_WORKING_MANAGEMENT_FAILURE = 'SUBMIT_SAVE_WORKING_MANAGEMENT_FAILURE';
export const INIT_SUBMIT_SAVE_WORKING_MANAGEMENT_ACTION_STATUS = 'INIT_SUBMIT_SAVE_WORKING_MANAGEMENT_ACTION_STATUS';


//Sub add or edit
export const OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL = 'OPEN_ADD_OR_EDIT_WORKING_MANAGEMENTSUB_MODAL';
export const ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT = 'ADD_OR_EDIT_WORKING_MANAGEMENTSUB_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL = 'RESET_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_MODAL';
export const START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB = 'START_SUBMIT_SAVE_WORKING_MANAGEMENTSUB';
export const SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS = 'SUBMIT_SAVE_WORKING_MANAGEMENTSUB_SUCCESS';
export const SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE = 'SUBMIT_SAVE_WORKING_MANAGEMENTSUB_FAILURE';
export const INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS = 'INIT_SUBMIT_SAVE_WORKING_MANAGEMENTSUB_ACTION_STATUS';


// batch change status
export const OPEN_BATCH_CHANGE_STATUS_MODAL = 'OPEN_BATCH_CHANGE_STATUS_MODAL';
export const RESET_BATCH_CHANGE_STATUS_MODAL = 'RESET_BATCH_CHANGE_STATUS_MODAL';
export const BATCH_CHANGE_STATUS_CHANGE_INPUT = 'BATCH_CHANGE_STATUS_CHANGE_INPUT';
export const START_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT = 'START_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT';
export const BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_SUCCESS = 'BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_SUCCESS';
export const BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_FAILURE = 'BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_FAILURE';
export const INIT_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_ACTION_STATUS = 'INIT_BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT_ACTION_STATUS';
// finish
export const SELECT_WORKING_MANAGEMENT_TASK = 'SELECT_WORKING_MANAGEMENT_TASK';
export const OPEN_FINISH_TASK_MODAL = 'OPEN_FINISH_TASK_MODAL';
export const RESET_FINISH_TASK_MODAL = 'RESET_FINISH_TASK_MODAL';
export const FINISH_TASK_CHANGE_INPUT = 'FINISH_TASK_CHANGE_INPUT';
export const START_FINISH_WORKING_MANAGEMENT_TASK = 'START_FINISH_WORKING_MANAGEMENT_TASK';
export const FINISH_WORKING_MANAGEMENT_TASK_SUCCESS = 'FINISH_WORKING_MANAGEMENT_TASK_SUCCESS';
export const FINISH_WORKING_MANAGEMENT_TASK_FAILURE = 'FINISH_WORKING_MANAGEMENT_TASK_FAILURE';
export const INIT_FINISH_WORKING_MANAGEMENT_TASK_ACTION_STATUS = 'INIT_FINISH_WORKING_MANAGEMENT_TASK_ACTION_STATUS';

