import {connect} from 'react-redux';
import ApplyReconDashboard from '../components/ApplyReconDashboard';


import {
    // query
    onQueryInputChange,
    loadApplyReconInfo,
    initLoadApplyReconInfoActionStatus,
    // select
    selectApplyRecon,
    // add or edit
    openAddOrEditApplyReconModal,
    addOrEditApplyReconChangeInput,
    resetSubmitSaveApplyReconModal,
    submitSaveApplyRecon,
    submitSaveApplyReconFailure,
    initSubmitSaveApplyReconActionStatus,
    // batch change status
    openBatchChangeStatusModal,
    resetBatchChangeStatusModal,
    batchChangeStatusChangeInput,
    batchChangeStatusForApplyRecon,
    initBatchChangeStatusForApplyReconActionStatus,
    // finish
    selectApplyReconTask,
    openFinishTaskModal,
    resetFinishTaskModal,
    finishTaskChangeInput,
    finishApplyReconTask,
    initFinishApplyReconTaskActionStatus,

} from '../ApplyReconActions';

// import {
//     // operationRcd
//     doOperationRecordAction,
// } from '../../../common/operationRcd/OperationRecordActions';

// import {
//     queryPendingTasksNum,
// } from '../../pendingTasks/PendingTasksActions'


const mapStateToProps = state => {
    return {
        // basic options
        locationOptions: state.applyRecon.locationOptions,
        fundCompanyOptions: state.applyRecon.fundCompanyOptions,
        frequencyOptions: state.applyRecon.frequencyOptions,
        weekdayOptions: state.applyRecon.weekdayOptions,
        monthOptions: state.applyRecon.monthOptions,
        responsibleDeptOptions: state.applyRecon.responsibleDeptOptions,
        // query
        applyReconQueryFields: state.applyRecon.applyReconQueryFields,
        loadApplyReconInfoActionStatus: state.applyRecon.loadApplyReconInfoActionStatus,
        loadApplyReconInfoResp: state.applyRecon.loadApplyReconInfoResp,
        // select
        selectedApplyReconArray: state.applyRecon.selectedApplyReconArray,
        // add or edit
        addOrEditApplyReconModal: state.applyRecon.addOrEditApplyReconModal,
        addOrEditApplyReconStatus: state.applyRecon.addOrEditApplyReconStatus,
        // batch change status
        batchChangeStatusModal: state.applyRecon.batchChangeStatusModal,
        batchChangeStatusForApplyReconStatus: state.applyRecon.batchChangeStatusForApplyReconStatus,
        // finish
        selectedApplyReconTaskArray: state.applyRecon.selectedApplyReconTaskArray,
        finishTaskModal: state.applyRecon.finishTaskModal,
        finishApplyReconTaskStatus: state.applyRecon.finishApplyReconTaskStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,
            // user account
        userAccountEmailOptions: state.applyRecon.userAccountEmailOptions,
        meetingAndTrainingUploadUrl: state.applyRecon.meetingAndTrainingUploadUrl,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadApplyReconInfo: (params) => dispatch(loadApplyReconInfo(params)),
        initLoadApplyReconInfoActionStatus: () => dispatch(initLoadApplyReconInfoActionStatus()),
        // select
        selectApplyRecon: (array) => dispatch(selectApplyRecon(array)),
        // add or edit
        openAddOrEditApplyReconModal: (mode, data) => dispatch(openAddOrEditApplyReconModal(mode, data)),
        addOrEditApplyReconChangeInput: (data) => dispatch(addOrEditApplyReconChangeInput(data)),
        resetSubmitSaveApplyReconModal: () => dispatch(resetSubmitSaveApplyReconModal()),
        submitSaveApplyRecon: (bean) => dispatch(submitSaveApplyRecon(bean)),
        submitSaveApplyReconFailure: (err) => dispatch(submitSaveApplyReconFailure(err)),
        initSubmitSaveApplyReconActionStatus: () => dispatch(initSubmitSaveApplyReconActionStatus()),
        // batch change status
        openBatchChangeStatusModal: (mode, idList) => dispatch(openBatchChangeStatusModal(mode, idList)),
        resetBatchChangeStatusModal: () => dispatch(resetBatchChangeStatusModal()),
        batchChangeStatusChangeInput: (data) => dispatch(batchChangeStatusChangeInput(data)),
        batchChangeStatusForApplyRecon: (params) => dispatch(batchChangeStatusForApplyRecon(params)),
        initBatchChangeStatusForApplyReconActionStatus: () => dispatch(initBatchChangeStatusForApplyReconActionStatus()),
        // finish
        selectApplyReconTask: (array) => dispatch(selectApplyReconTask(array)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),
        resetFinishTaskModal: () => dispatch(resetFinishTaskModal()),
        finishTaskChangeInput: (param) => dispatch(finishTaskChangeInput(param)),
        finishApplyReconTask: (task) => dispatch(finishApplyReconTask(task)),
        initFinishApplyReconTaskActionStatus: () => dispatch(initFinishApplyReconTaskActionStatus()),
        // queryPendingTasksNum: () => dispatch(queryPendingTasksNum()),
        // operationRcd
        // doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(ApplyReconDashboard);
