import React, {Component, } from 'react';
import { Button, message } from 'antd';

import './QuestionnaireRequest.css';
import QuestionnaireRequestGrid from './QuestionnaireRequestGrid';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../utils/localStorageClient';

class QuestionnaireRequestDashboard extends Component {
    componentDidMount() {
        this.queryQuestionnaireButtonOnClicked();
    }


    componentDidUpdate() {
        const {
            // do query
            loadQuestionnaireResultsActionStatus,
            loadQuestionnaireResultsActionResp,
        } = this.props;

        if(loadQuestionnaireResultsActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadQuestionnaireResultsActionStatus();
        }
        if(loadQuestionnaireResultsActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load questionnaire results failed';
            if(!!loadQuestionnaireResultsActionResp && !!loadQuestionnaireResultsActionResp.respMessage) {
                err = loadQuestionnaireResultsActionResp.respMessage;
            }
            message.error(err);
            this.props.initLoadQuestionnaireResultsActionStatus();
        }
    }


    queryQuestionnaireButtonOnClicked = () => {
        const userAccountName = localStorageClient.getUserName();
        const params = {
            questionnaireGuid: '',
            userAccountName: userAccountName,
        };
        this.props.loadQuestionnaireResults(params);
    }


    render() {
        const {
            // do query
            loadQuestionnaireResultsActionStatus,
        } = this.props;
        const isLoading = loadQuestionnaireResultsActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div className='questionnaireDashboardWrapper'>
                <div className='questionnaireOperationBar'>
                    <Button 
                        type="primary" 
                        style={{ fontSize:14 }} 
                        size='small'
                        onClick={this.queryQuestionnaireButtonOnClicked} 
                        loading={isLoading}
                     >Refresh</Button>
                </div>
                <div className='questionnaireRequestGrid'><QuestionnaireRequestGrid {...this.props} /></div>
            </div>
        );
    }
}

export default QuestionnaireRequestDashboard;

