import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

import {
    // do query
    START_LOAD_QUESTIONNAIRE_RESULTS,
    LOAD_QUESTIONNAIRE_RESULTS_SUCCESS,
    LOAD_QUESTIONNAIRE_RESULTS_FAILURE,
    INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS,
    
} from './QuestionnaireRequestConstants';


// do query
function startLoadQuestionnaireResults() {
    return {
        type: START_LOAD_QUESTIONNAIRE_RESULTS,
    };
}
export function loadQuestionnaireResults(params) {
    return function(dispatch) {
        dispatch(startLoadQuestionnaireResults());
        httpClient
          .get(UrlConfig.LOAD_QUESTIONNAIRE_RESULTS, params)
          .then(resp => dispatch(loadQuestionnaireResultsSuccess(resp)))
          .catch(err => dispatch(loadQuestionnaireResultsFailure(err)));
    };
}
function loadQuestionnaireResultsSuccess(resp) {
    return {
      type: LOAD_QUESTIONNAIRE_RESULTS_SUCCESS,
      payload: resp
    };
}
function loadQuestionnaireResultsFailure(err) {
    return {
      type: LOAD_QUESTIONNAIRE_RESULTS_FAILURE,
      payload: err
    };
}
export function initLoadQuestionnaireResultsActionStatus() {
    return {
      type: INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS,
    };
}


