import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button,Select,DatePicker,Checkbox,Upload, Icon ,Tooltip} from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import {  Steps } from 'antd';
// import { Document, Page,pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;
const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const Step = Steps.Step;


const steps = [{
  title: 'license agreement ',
  content: 'First-content',
}, {
  title: 'step 2',
  content: 'Second-content',
}, {
  title: 'step 3',
  content: 'Second-content',
}, {
  title: 'step 4',
  content: 'Last-content',
}];
const regiStatusOptions = [];
regiStatusOptions.push(<Option key={'Food, Beverage & Tobacco'}>{'Food, Beverage & Tobacco'}</Option>);
regiStatusOptions.push(<Option key={'Capital Goods'}>{'Capital Goods'}</Option>);
regiStatusOptions.push(<Option key={'Banks'}>{'Banks'}</Option>);
regiStatusOptions.push(<Option key={'Insurance'}>{'Insurance'}</Option>);
regiStatusOptions.push(<Option key={'Energy'}>{'Energy'}</Option>);
regiStatusOptions.push(<Option key={'Automobiles & Components'}>{'Automobiles & Components'}</Option>);
regiStatusOptions.push(<Option key={'Consumer Durables & Apparel'}>{'Consumer Durables & Apparel'}</Option>);
regiStatusOptions.push(<Option key={'Technology Hardware & Equipment'}>{'Technology Hardware & Equipment'}</Option>);
regiStatusOptions.push(<Option key={'Semiconductors & Semiconductor Equipment'}>{'Semiconductors & Semiconductor Equipment'}</Option>);
regiStatusOptions.push(<Option key={'Utilities'}>{'Utilities'}</Option>);
regiStatusOptions.push(<Option key={'Health Care Equipment & Services'}>{'Health Care Equipment & Services'}</Option>);
regiStatusOptions.push(<Option key={'Pharmaceuticals, Biotechnology & Life Sciences'}>{'Pharmaceuticals, Biotechnology & Life Sciences'}</Option>);
regiStatusOptions.push(<Option key={'Retailing'}>{'Retailing'}</Option>);
regiStatusOptions.push(<Option key={'Telecommunication Services'}>{'Telecommunication Services'}</Option>);
regiStatusOptions.push(<Option key={'Diversified Financials'}>{'Diversified Financials'}</Option>);
regiStatusOptions.push(<Option key={'Materials'}>{'Materials'}</Option>);
regiStatusOptions.push(<Option key={'Transportation'}>{'Transportation'}</Option>);
regiStatusOptions.push(<Option key={'Real Estate'}>{'Real Estate'}</Option>);
regiStatusOptions.push(<Option key={'Software & Services'}>{'Software & Services'}</Option>);
regiStatusOptions.push(<Option key={'Media & Entertainment'}>{'Media & Entertainment'}</Option>);
regiStatusOptions.push(<Option key={'Household & Personal Products'}>{'Household & Personal Products'}</Option>);
regiStatusOptions.push(<Option key={'Consumer Services'}>{'Consumer Services'}</Option>);
regiStatusOptions.push(<Option key={'Commercial & Professional Services'}>{'Commercial & Professional Services'}</Option>);
regiStatusOptions.push(<Option key={'Food & Staples Retailing'}>{'Food & Staples Retailing'}</Option>);

const markingOptions = [];
markingOptions.push(<Option key={'CH'}>{'CH'}</Option>);
markingOptions.push(<Option key={'JP'}>{'JP'}</Option>);
markingOptions.push(<Option key={'IN'}>{'IN'}</Option>);
markingOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);

// const agree = 'false' 

class ChangeCollegeApplicationStatusModalDialog extends Component {
    state = {
        current: 0,
        investorSelectedRowKeys: [],
        seriesSelectedRowKeys: [],
        transSelectedRowKeys: [],
        currentPage: 0,
        div: '<p style="text-align:left;" class="MsoNormal" align="left"><span style="font-size:14px"><span style="line-height:1.5"><span style="color:#000000"><strong><u>10月工作总结</u></strong></span></span></span></p><p style="text-align:left;" class="MsoNormal" align="left"> </p><p><span style="font-size:14px"><span style="line-height:1.5"><strong><span style="color:#003ba5">一、项目进展</span></strong></span></span></p>',
    };
    
    next() {
        if (this.state.current === 3) {
          return;
        }
        
        const current = this.state.current + 1;
        this.setState({ current });
      }
    
      prev() {
        if (this.state.current === 0) {
          return;
        }
        
        const current = this.state.current - 1;
        this.setState({ current });
      }
    
    refreshBtnOnClicked = () => {
        // this.props.loadCollegeApplicationInfo();
        this.props.loadCollegeApplicationInfo({type:undefined,starttime:undefined,endtime:undefined,speaker:undefined})
    }

    componentDidUpdate() {
        const {
            submitChangeCollegeApplicationStatusActionStatus,
        } = this.props;
        const {
            mode,
        } = this.props.changeCollegeApplicationStatusModal;

        if(submitChangeCollegeApplicationStatusActionStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' succeeded.';
            message.success(msg);
            this.props.initSubmitChangeCollegeApplicationStatusModalActionStatus();
            this.props.resetChangeCollegeApplicationStatusModal();
            this.refreshBtnOnClicked();
        }
        if(submitChangeCollegeApplicationStatusActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitChangeCollegeApplicationStatusModalActionStatus();
        }
    }

    getActionFromMode(mode) {
        if(mode === 'Advanced Training') {
            return 'Advanced Training';
        } else if(mode === 'Paper Portfolio') {
            return 'Paper Portfolio';
        } else if (mode === 'Stock Pitch'){
            return 'Stock Pitch'
        }
    }

    onInputChange = ({name, value}) => {
        this.props.changeGeneralAgreemenStatusChangeInput({name, value});
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }

    submitChangeCollegeApplicationStatusModal = () => {
        const {
            mode,
        } = this.props.changeCollegeApplicationStatusModal;
        const {
            idList,
            begindate,
            sector,
            market,
            complianceconfirm,
            uploadOriName,
            uploadRealName,
            // personInCharge,
            // company,
            // counterparty,
            // agreementType,
            // executionDate,
            // expiryDate,
            // noticePeriod,
            // terms,
            // uploadOriName,
            // uploadRealName,
            // amount,
            // amountCCY,
            remark,
            // comments,
            // source,
        } = this.props.changeCollegeApplicationStatusModal.fields;

        // Report端只能DEL New状态的
        // if(mode === 'DEL' && status !== 'New') {
        //     message.warn('Only the General Agreements in New status can be Deleted');
        //     return;
        // }
        // // 只有 New、Rejected、Valid状态的可以SUBMIT
        // if(mode === 'SUBMIT' && status !== 'New' && status !== 'Rejected' && status !== 'Valid') {
        //     message.warn('Only the General Agreements in New, Rejected or Valid status can be Submitted');
        //     return;
        // }
        // // 只有Valid状态的可以EXPIRE
        // if(mode === 'EXPIRE' && status !== 'Valid') {
        //     message.warn('Only the General Agreements in Valid status can be Expired');
        //     return;
        // }

        Modal.confirm({
            title: 'Are you sure to ' + this.getActionFromMode(mode) + '?',
            onOk: () => {
                this.props.submitChangeCollegeApplicationStatusModal({
                    idList,
                    begindate,
                    sector,
                    market,
                    complianceconfirm,
                    uploadOriName,
                    uploadRealName,
                    remark,
                    // comments,
                    // source,
                    modes: mode,
                    mode: mode,
                });
            }
        });
    }
    generalAgreeementHandleChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'agreementUploadFileList', value: []});
            this.onInputChange({name: 'uploadRealName', value: undefined});
            this.onInputChange({name: 'uploadOriName', value: undefined});
            return;
        }

        let attachment = '';
        let attachmentOriginName= '';
        fileList.forEach(file => {
          if (file.response) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            attachment = attachment + ";" + filename;
            attachmentOriginName = attachmentOriginName + ";" + originalFileName;
          }
        });
        this.onInputChange({name: 'agreementUploadFileList', value: fileList});
        this.onInputChange({name: 'uploadRealName', value: attachment});
        this.onInputChange({name: 'uploadOriName', value: attachmentOriginName});

    }
    onDocumentLoadSuccess = ({ numPages }) => {//numPages是总页数
        this.setState({ numPages });
    };
    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            changeCollegeApplicationStatusModal,
            submitChangeCollegeApplicationStatusActionStatus,
            collegeApplicationUploadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = changeCollegeApplicationStatusModal;
        const {
            current,
            investorSelectedRowKeys,
            seriesSelectedRowKeys,
            transSelectedRowKeys,
          } = this.state;
      
        const {
            id,
            remarks,
            begindate,
            complianceconfirm,
            sector,
            market,
            agreementUploadFileList,
            uploadOriName,
            comments,
        } = changeCollegeApplicationStatusModal.fields;

        const modalTitle = this.getActionFromMode(mode) + '';
        const isSubmitLoading = submitChangeCollegeApplicationStatusActionStatus === ACTION_STATUS.LOGINING;
        
      
        return(
            <div>
                <style>
                {`
                    .steps-content {
                    height: 500px;
                    margin-top: 20px;
                    }

                    .ant-steps {
                    width: 70%;
                    margin-left: 15%;
                    }
                    
                `}
                </style>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetChangeCollegeApplicationStatusModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetChangeCollegeApplicationStatusModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" disabled={!complianceconfirm} loading={isSubmitLoading} onClick={() => this.submitChangeCollegeApplicationStatusModal() }>
                          Submit
                        </Button>,
                    //     <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitChangeCollegeApplicationStatusModal() }>
                    //     Submit
                    //   </Button>,
                    ]}
                >

                        <Steps current={current} size="small">
                            {steps.map(item => <Step key={item.title} title={item.title} />)}
                        </Steps>
                        <div className="steps-content">
                        {
                        current === 0 && 
                        <div className='previewGridContainer'>
                            <div className='previewGrid'>
                            <div dangerouslySetInnerHTML = {{ __html:this.state.div }}></div>
                            </div>
                            <span style={{ fontSize: 10 }}>提示1：阅读条框事项</span><br/>
                            {/* <span style={{ fontSize: 10 }}>提示2：默认将创建所有未匹配的投资人</span> */}
                        </div>
                        }
                        {
                        current === 1 && 
                        <div className='previewGridContainer'>
                            <div className='previewGrid'>
                            {/* <SeriesPreviewGrid {...this.props} /> */}
                            </div>
                            {/* <span style={{ fontSize: 10 }}>提示：匹配到高水位一致的Series，勾选将会合并到已经存在的Series, 不会创建新的Series，未匹配到的，将会创建新的Series</span> */}
                        </div>
                        }
                        {
                        current === 2 && 
                        <div className='previewGridContainer'>
                            <div className='previewGrid'>
                            {/* <InvestorNetValuePreviewGrid {...this.props} /> */}
                            </div>
                        </div>
                        }
                        {
                        current === 3 && 
                        <div className='previewGridContainer'>
                            <div className='previewGrid'>
                            <Form layout="horizontal">
                                <div style={{marginLeft: 80 }}>{
                                    mode === 'Paper Portfolio' ?   
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <FormItem {...formItemLayout} label={<span>
                                                    Begin Date&nbsp;
                                                <Tooltip title=" the date you want to have your paper account ready">
                                                <Icon type="question-circle-o" />
                                                </Tooltip>
                                            </span>}>
                                                {getFieldDecorator('beginDate', {
                                                    initialValue: begindate,
                                                    rules: [{
                                                        required: false,
                                                        message: 'Please select Begin Date',
                                                    }],
                                                    })(
                                                    <DatePicker 
                                                        style={{ width: 300, marginLeft: 10 }}
                                                        onChange={(date, dateString) => {
                                                            this.onInputChange({ name: 'begindate', value: dateString })
                                                        }}
                                                    />
                                                    )}
                                                </FormItem>
                                            </Col>
                                            </Row>
                                            : <div></div>
                                        }</div>
                                    <div style={{marginLeft: 80 }}>{
                                        mode === 'Paper Portfolio' || mode === 'Stock Pitch' ? 
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <FormItem {...formItemLayout} label="Sector (tentative)">
                                                    {getFieldDecorator('sector', {
                                                        initialValue: sector,
                                                        rules: [{
                                                            required: true,
                                                            message: 'Please select sector',
                                                        }],
                                                        })(
                                                        <Select
                                                                allowClear
                                                                mode='multiple'
                                                                style={{ width: 300, marginLeft: 10 }}
                                                                onChange={(val) => {
                                                                    this.onEmailInputChange({ name: 'sector', value: val })
                                                                }} 
                                                                placeholder="Please select sector">
                                                            {regiStatusOptions}
                                                        </Select>
                                                        )}
                                                    </FormItem>
                                            </Col>
                                        </Row>
                                            : <div></div>
                                        }</div>
                                    <div style={{marginLeft: 80 }}>{
                                    mode === 'Paper Portfolio' || mode === 'Stock Pitch'? 
                                        <Row gutter={24}>
                                        <Col span={12}>
                                                <FormItem {...formItemLayout} label="Market (tentative)">
                                                    {getFieldDecorator('market', {
                                                        initialValue: market,
                                                        rules: [{
                                                            required: true,
                                                            message: 'Please select Market',
                                                        }],
                                                        })(
                                                        <Select
                                                                allowClear
                                                                mode='multiple'
                                                                style={{ width: 300, marginLeft: 10 }}
                                                                onChange={(val) => {
                                                                    this.onEmailInputChange({ name: 'market', value: val })
                                                                }} 
                                                                placeholder="Please select Market">
                                                            {markingOptions}
                                                        </Select>
                                                        )}
                                                    </FormItem>
                                            </Col>
                                        
                                        </Row> 
                                            : <div></div>
                                        }</div>  

                                    <div style={{marginLeft: 80 }}>{
                                    mode === 'Paper Portfolio' ?                          
                                    <Row gutter={24}>
                                    <Col span={12}>
                                        <FormItem {...formItemLayout} label="Upload (BBG Tickers)">
                                            {getFieldDecorator('uploadOriName', {
                                                initialValue: uploadOriName,
                                                rules: [{
                                                    required: false,
                                                    message: 'Please upload the Agreement',
                                                }],
                                                })(
                                                <Upload
                                                    name="uploadOriName"
                                                    withCredentials={true}
                                                    
                                                    // disabled={needDisable}
                                                    multiple={true}
                                                    showUploadList={true}
                                                    fileList={agreementUploadFileList}
                                                    action={collegeApplicationUploadUrl}
                                                    onChange={this.generalAgreeementHandleChange}
                                                >
                                                    <Button 
                                                        style={{ width: 300, marginLeft: 10 }}
                                                        // disabled={needDisable}
                                                    >
                                                        <Icon type="upload" />Stock lists[Optional]
                                                    </Button>
                                                </Upload>
                                            )}
                                        </FormItem>
                                    </Col>
                                    {/* <Col span={12}>
                                        <p>1.Maximum Attachment File Size: 10MB<br/>
                                        2.Support for multiple files<br/>                
                                        3.Maximum Attachment File Name Length: 80 English characters or 40 Chinese characters</p>
                                    </Col> */}
                                    
                                </Row>
                                    : <div></div>
                                }</div>    



                                       
                                <div style={{marginLeft: 80 }}>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label="Note">
                                                {getFieldDecorator('remarks', {
                                                    initialValue: remarks,
                                                    rules: [{
                                                        required: false,
                                                        message: '',
                                                    }],
                                                    })(
                                                    <TextArea 
                                                        style={{ width: 500, marginLeft: 10 }}
                                                        rows={4} 
                                                        placeholder='Please input remarks' 
                                                        onChange={(e) => {
                                                            this.onInputChange({ name: 'remark', value: e.target.value })
                                                    }}/>
                                                )}
                                            </FormItem>
                                        </Col>

                                    </Row>
                                    </div>
                                    <div style={{marginLeft: 80 }}>{
                                    mode === 'Paper Portfolio' || mode === 'Stock Pitch'?                          
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <FormItem {...formItemLayout} label="I accept the terms in the License Agreement">
                                                        
                                                        {getFieldDecorator('I accept the terms in the License Agreement', {
                                                        initialValue: complianceconfirm,
                                                        rules: [{
                                                            required: false,
                                                            message: '',
                                                        }],
                                                        })(
                                                            <Checkbox 
                                                            style={{ width: 100, marginRight: 120 }}
                                                            checked={complianceconfirm}
                                                            onChange={(e) => {
                                                            this.onInputChange({ name: 'complianceconfirm', value: e.target.checked })
                                                            }}
                                                            ></Checkbox>
                                                    )}

                                                </FormItem>
                                            </Col>
                                        </Row>
                                    : <div></div>
                                }</div>
                                </Form>
                                {
                                    !!respErrMsg &&  
                                    <Message negative>
                                        <Message.Header>Response Error Message</Message.Header>
                                        <p>{ respErrMsg }</p>
                                    </Message>
                                }
                            </div>
                        </div>
                        }
                        </div>
                    

                    <div className="steps-action">
                        <Button type="primary" size="small" style={{ marginRight: 8 }} onClick={() => this.prev()} disabled={current === 0}>
                        Previous
                        </Button>
                        <Button type="primary" size="small" onClick={() => this.next()} disabled={current === 3}>Next</Button>
                    </div>


                    
                </Modal>
            </div>
        );
    }

}

const ChangeCollegeApplicationStatusModalDialogForm = Form.create({ name: 'ChangeCollegeApplicationStatusModalDialog' })(ChangeCollegeApplicationStatusModalDialog);
export default ChangeCollegeApplicationStatusModalDialogForm;

