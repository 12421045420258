import UrlConfig from '../../../../net/UrlConfig';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import moment from 'moment';

const questionnaireResultGridColumns = [
    {
      field: 'questionnaireTitle',
      headerName: "Questionnaire Title",
      cellClass: 'non-number',
      width: 400,
      cellRenderer: "agGroupCellRenderer",
    },
    {
      field: 'answeree',
      headerName: "Answeree",
      cellClass: 'non-number',
      width: 150,
    },
    {
      field: 'submitter',
      headerName: "Submitter",
      cellClass: 'non-number',
      width: 150,
    },
    {
        field: 'createdDateLabel',
        headerName: "Received On",
        cellClass: 'non-number',
        width: 150,
    },
    {
      field: 'submittedDateLabel',
      headerName: "Submitted On",
      cellClass: 'non-number',
      width: 150,
      cellClassRules: {
          'quiz-expired': function(node) {
              if(node.data.type !== 'QUIZ') {
                return false;
              }
              let receivedOn = node.data.createdDateLabel;
              let submittedOn = node.data.submittedDateLabel;
              if(!submittedOn) {
                submittedOn = moment().format('YYYY-MM-DD');
              }
              return (submittedOn > receivedOn);
          },
      },
    },
    {
      field: 'statusLabel',
      headerName: "Status",
      cellClass: 'non-number',
      width: 100,
    },
    {
      field: 'type',
      headerName: "Type",
      cellClass: 'non-number',
      width: 120,
    },
    {
      field: 'totalScore',
      headerName: "Total Score",
      cellClass: 'non-number',
      width: 100,
    },
    {
      field: 'result',
      headerName: "Result",
      cellClass: 'non-number',
      width: 100,
      cellStyle: (param) => {
        if(param.value === 'Pending') {
            return {color: 'Goldenrod'};
        } else if(param.value === 'Pass') {
            return {color: 'green'};
        } else if(param.value === 'Fail') {
            return {color: 'red'};
        } else {
            return {color: 'black'};
        }
      }
    },
    {
      headerName: "View",
      cellClass: 'non-number',
      width: 200,
      cellRenderer: (params) => {
        let link = document.createElement('a');
        link.innerText = 'Click to view questionnaire';
        link.target = '_blank';
        link.href = 'https://' + BaseUrlConfig.admin_frontend_dn + '/questionnaire-answer?authToken=' + params.data.authToken;
        return link;
      }
    },
    {
        field: 'lastNotifyDateLabel',
        headerName: "Last Notify On",
        cellClass: 'non-number',
        width: 150,
    }
  ];


  const questionAnswerGridColumns = [
    {
      field: 'questionNumber',
      headerName: "Question Number",
      cellClass: 'non-number',
      width: 60,
    },
    {
      field: 'subQuestionNumber',
      headerName: "SubQuestion Number",
      cellClass: 'non-number',
      width: 60,
    },
    {
      field: 'questionEnglish',
      headerName: "Question English",
      cellClass: 'non-number',
      width: 350,
    },
    {
      field: 'questionChinese',
      headerName: "Question Chinese",
      cellClass: 'non-number',
      width: 350,
    },
    {
      field: 'questionTypeLabel',
      headerName: "Question Type",
      cellClass: 'non-number',
      width: 120,
    },
    {
      field: 'answerContent',
      headerName: "Answer Content",
      cellClass: 'non-number',
      width: 300,
    },
    {
      field: 'score',
      headerName: "Score",
      cellClass: 'non-number',
      width: 80,
    },
    {
      field: 'correctAnswer',
      headerName: "Correct Answer",
      cellClass: 'non-number',
      width: 150,
      cellRenderer: (params) => {
        if(!!params && !!params.data && params.data.questionnaireResultStatusLabel === 'Submitted') {
            return params.data.correctAnswer;
        }
        return '';
      }
    },
    {
      field: 'answerDateLabel',
      headerName: "Answer Date",
      cellClass: 'non-number',
      width: 150,
    },
  ];


  const GridColumnMap = {
    questionnaireResultGridColumns,
    questionAnswerGridColumns,
  };
  

  export default GridColumnMap;

