// query
export const ON_APPLY_RECON_QUERY_INPUT_CHANGE = 'ON_APPLY_RECON_QUERY_INPUT_CHANGE';
export const START_LOAD_APPLY_RECON_INFO = 'START_LOAD_APPLY_RECON_INFO';
export const LOAD_APPLY_RECON_INFO_SUCCESS = 'LOAD_APPLY_RECON_INFO_SUCCESS';
export const LOAD_APPLY_RECON_INFO_FAILURE = 'LOAD_APPLY_RECON_INFO_FAILURE';
export const INIT_LOAD_APPLY_RECON_INFO_ACTION_STATUS = 'INIT_LOAD_APPLY_RECON_INFO_ACTION_STATUS';
// select
export const SELECT_REPORTING_SUMMRAY = 'SELECT_REPORTING_SUMMRAY';
// add or edit
export const OPEN_ADD_OR_EDIT_APPLY_RECON_MODAL = 'OPEN_ADD_OR_EDIT_APPLY_RECON_MODAL';
export const ADD_OR_EDIT_APPLY_RECON_CHANGE_INPUT = 'ADD_OR_EDIT_APPLY_RECON_CHANGE_INPUT';
export const RESET_SUBMIT_SAVE_APPLY_RECON_MODAL = 'RESET_SUBMIT_SAVE_APPLY_RECON_MODAL';
export const START_SUBMIT_SAVE_APPLY_RECON = 'START_SUBMIT_SAVE_APPLY_RECON';
export const SUBMIT_SAVE_APPLY_RECON_SUCCESS = 'SUBMIT_SAVE_APPLY_RECON_SUCCESS';
export const SUBMIT_SAVE_APPLY_RECON_FAILURE = 'SUBMIT_SAVE_APPLY_RECON_FAILURE';
export const INIT_SUBMIT_SAVE_APPLY_RECON_ACTION_STATUS = 'INIT_SUBMIT_SAVE_APPLY_RECON_ACTION_STATUS';
// batch change status
export const OPEN_BATCH_CHANGE_STATUS_MODAL = 'OPEN_BATCH_CHANGE_STATUS_MODAL';
export const RESET_BATCH_CHANGE_STATUS_MODAL = 'RESET_BATCH_CHANGE_STATUS_MODAL';
export const BATCH_CHANGE_STATUS_CHANGE_INPUT = 'BATCH_CHANGE_STATUS_CHANGE_INPUT';
export const START_BATCH_CHANGE_STATUS_FOR_APPLY_RECON = 'START_BATCH_CHANGE_STATUS_FOR_APPLY_RECON';
export const BATCH_CHANGE_STATUS_FOR_APPLY_RECON_SUCCESS = 'BATCH_CHANGE_STATUS_FOR_APPLY_RECON_SUCCESS';
export const BATCH_CHANGE_STATUS_FOR_APPLY_RECON_FAILURE = 'BATCH_CHANGE_STATUS_FOR_APPLY_RECON_FAILURE';
export const INIT_BATCH_CHANGE_STATUS_FOR_APPLY_RECON_ACTION_STATUS = 'INIT_BATCH_CHANGE_STATUS_FOR_APPLY_RECON_ACTION_STATUS';
// finish
export const SELECT_REPORTING_SUMMRAY_TASK = 'SELECT_REPORTING_SUMMRAY_TASK';
export const OPEN_FINISH_TASK_MODAL = 'OPEN_FINISH_TASK_MODAL';
export const RESET_FINISH_TASK_MODAL = 'RESET_FINISH_TASK_MODAL';
export const FINISH_TASK_CHANGE_INPUT = 'FINISH_TASK_CHANGE_INPUT';
export const START_FINISH_APPLY_RECON_TASK = 'START_FINISH_APPLY_RECON_TASK';
export const FINISH_APPLY_RECON_TASK_SUCCESS = 'FINISH_APPLY_RECON_TASK_SUCCESS';
export const FINISH_APPLY_RECON_TASK_FAILURE = 'FINISH_APPLY_RECON_TASK_FAILURE';
export const INIT_FINISH_APPLY_RECON_TASK_ACTION_STATUS = 'INIT_FINISH_APPLY_RECON_TASK_ACTION_STATUS';

