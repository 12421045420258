import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button,DatePicker, Checkbox,Upload, Icon ,Tooltip} from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { localStorageClient } from '../../../../utils/localStorageClient';
import moment from 'moment';
const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const markingOptions = [];
markingOptions.push(<Option key={'CH'}>{'CH'}</Option>);
markingOptions.push(<Option key={'JP'}>{'JP'}</Option>);
markingOptions.push(<Option key={'IN'}>{'IN'}</Option>);
markingOptions.push(<Option key={'ALL'}>{'ALL'}</Option>);

const paperOrBookOptions = [];
paperOrBookOptions.push(<Option key={'Paper Portfolio'}>{'Paper Portfolio'}</Option>);
paperOrBookOptions.push(<Option key={'Book'}>{'Book'}</Option>);

class AddOrEditApplyReconModalDialog extends Component {

    refreshBtnOnClicked = () => {

        const userName = localStorageClient.getUserName();
        const {
            id,
        } = this.props.applyReconQueryFields;
        this.props.loadApplyReconInfo({id ,userName,source:'report'});

    }

    getActionFromMode(mode) {
        if(mode === 'Stock Pitch') {
            return 'Stock Pitch';
        } else if(mode === 'PaperPortfolioOrBook') {
            return 'Paper Portfolio / Book';
        }else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }
    
    componentDidUpdate() {
        const {
            addOrEditApplyReconStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditApplyReconModal;

        if(addOrEditApplyReconStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' Reporting Summary succeeded.';
            message.success(msg);
            this.props.initSubmitSaveApplyReconActionStatus();
            this.props.resetSubmitSaveApplyReconModal();
            this.refreshBtnOnClicked();
        }
        if(addOrEditApplyReconStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSaveApplyReconActionStatus();
        }

    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditApplyReconChangeInput({name, value});
    }
    onFrequencyInputChange = (value) => {
        this.onInputChange({name: 'frequency', value: value});
        this.onInputChange({name: 'deadline', value: undefined});
        this.clearDecomposedDeadline();
        this.onInputChange({name: 'notification', value: undefined});
    }
    onDeadlineNAInputChange = (value) => {
        const deadline = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
        this.clearDecomposedDeadline();
        // deadline是NA时，notification必须是NA，其他情况，清空notification重新填写
        this.onInputChange({name: 'notification', value: deadline});
        
    }
    onDeadlineEveryWorkdayInputChange = (value) => {
        const deadline = !!value ? 'EveryWorkday' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineEndOfMonthInputChange = (value) => {
        const deadline = !!value ? 'EndOfMonth' : undefined;
        this.onInputChange({name: 'deadline', value: deadline});
    }
    onDeadlineMonthInputChange = (value) => {
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineMonth', value: value});
    }
    // 清空分解后的deadline的各参数
    clearDecomposedDeadline = () => {
        this.onInputChange({name: 'deadlineMonth', value: undefined});
        this.onInputChange({name: 'deadlineDate', value: undefined});
        this.onInputChange({name: 'deadlineYear', value: undefined});
    }
    onNotificationNAInputChange = (value) => {
        const notification = !!value ? 'NA' : undefined;
        this.onInputChange({name: 'notification', value: notification});
    }
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }


    submitSaveApplyReconModal = (withSubmit) => {
        const {
            mode,
        } = this.props.addOrEditApplyReconModal;
        const {
            id,
            status,
            location,
            fundCompany,
            description,
            via,
            frequency,

            regulator,
            responsibleDept,
            emailTo,
            emailCC,
            regulation,
            remarks,
            comments,



            idList,
            begindate,
            sector,
            exception,
            market,
            complianceconfirm,
            uploadOriName,
            uploadRealName,
            applytype,
            remark,
     
        } = this.props.addOrEditApplyReconModal.fields;


        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // if(mode === 'EDIT' && approvalStatus !== 'New'  && approvalStatus !== 'Rejected') {
            //     const err = {respCode: 'F9999', respMessage: 'You can only edit a Apply in New/Rejected/ status'};
            //     this.props.submitAddOrEditAccountFailure(err);
            //     return;
            // }
            

            
           
            // 执行提交
            this.props.submitSaveApplyRecon({
                id,
                status,
                location,
                fundCompany,
                description,
                via,
                frequency,

                regulator,
                responsibleDept,
                emailTo,
                emailCC,
                regulation,
                remarks,
                comments,
                mode,
                idList,
                begindate,
                sector,
                exception,
                market,
                complianceconfirm,
                uploadOriName,
                uploadRealName,
                applytype,
                remark,
                withSubmit,
                source: 'Report',
            });

        });
    }
    generalAgreeementHandleChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'agreementUploadFileList', value: []});
            this.onInputChange({name: 'uploadRealName', value: undefined});
            this.onInputChange({name: 'uploadOriName', value: undefined});
            return;
        }

        let attachment = '';
        let attachmentOriginName= '';
        fileList.forEach(file => {
          if (file.response) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            attachment = attachment + ";" + filename;
            attachmentOriginName = attachmentOriginName + ";" + originalFileName;
          }
        });
        this.onInputChange({name: 'agreementUploadFileList', value: fileList});
        this.onInputChange({name: 'uploadRealName', value: attachment});
        this.onInputChange({name: 'uploadOriName', value: attachmentOriginName});

    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            locationOptions,
            fundCompanyOptions,
            frequencyOptions,
            weekdayOptions,
            monthOptions,
            responsibleDeptOptions,
            addOrEditApplyReconModal,
            addOrEditApplyReconStatus,
            userAccountEmailOptions,
            meetingAndTrainingUploadUrl,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditApplyReconModal;

        const {
            id,

            begindate,
            complianceconfirm,
            sector,
            exception,
            market,
            agreementUploadFileList,
            attachementUploadList,
            uploadOriName,
            applytype,
            frequency,
            filePath,
            deadline,
            notification,
            regulator,
            responsibleDept,
            emailTo,
            emailCC,
            regulation,
            remarks,
            remark,
            comments,
            deadlineMonth,
            deadlineDate,
            deadlineYear,
        } = addOrEditApplyReconModal.fields;

        const emailToArray = !emailTo ? [] : emailTo.split(';');


        const monthDateOptions = [];
        let maxDays = -1;
        if(frequency === 'Monthly') {
            maxDays = 31;
        } else if(frequency === 'SemiAnnually' || frequency === 'Annually' || frequency === 'Every3Years') {
            if(deadlineMonth === '02') {
                maxDays = 29;
            } else if(deadlineMonth === '04' || deadlineMonth === '06' || deadlineMonth === '09' || deadlineMonth === '11') {
                maxDays = 30;
            } else if(deadlineMonth === '01' || deadlineMonth === '03' || deadlineMonth === '05' || deadlineMonth === '07'
                       || deadlineMonth === '08' || deadlineMonth === '10' || deadlineMonth === '12') {
                maxDays = 31;
            }
        }
        if(frequency === 'Monthly' || frequency === 'SemiAnnually' || frequency === 'Annually' || frequency === 'Every3Years') {
            for(let i=1; i<=maxDays; i++) {
                if(i<10) {
                    i = '0' + i;
                }
                monthDateOptions.push(<Option key={i}>{i}</Option>);
            }
        }

        const startYearOptions = [];
        if(frequency === 'Every3Years') {
            for(let i=2020; i<=2099; i++) {
                startYearOptions.push(<Option key={i}>{i}</Option>);
            }
        }


        const modalTitle = this.getActionFromMode(mode) + ' Apply';
        const isSubmitLoading = addOrEditApplyReconStatus === ACTION_STATUS.LOGINING;
        const startdatement = !begindate ? undefined : moment(begindate);

        // const fath = !uploadOriName ? undefined : ;
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveApplyReconModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveApplyReconModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveApplyReconModal(false) }>
                          Save
                        </Button>,

                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveApplyReconModal(true) }>
                            Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                    <div style={{marginLeft: 80 }}>{
                        mode.includes('PaperPortfolioOrBook')
                         ? 
                            <Row gutter={24}>
                            <Col span={12}>
                                    <FormItem {...formItemLayout} label="PaperOrBook">
                                        {getFieldDecorator('ApplyType', {
                                            initialValue: applytype,
                                            rules: [
                                                {
                                                required: true,
                                                message: 'Please select ApplyType',
                                            }],
                                            })(
                                            <Select
                                                    allowClear
              
                                                    style={{ width: 300, marginLeft: 10 }}
                                                    onChange={(val) => {
                                                        this.onInputChange({ name: 'applytype', value: val })
                                            }}
                                                    placeholder="Please select ApplyType">
                                                {paperOrBookOptions}
                                            </Select>
                                            )}
                                        </FormItem>
                                </Col>
                            </Row> 
                                : <div></div>
                            }
                    </div>  
                    <div style={{marginLeft: 80 }}>{
                        mode.includes('PaperPortfolioOrBook') ?   
                            <Row gutter={24}>
                                <Col span={12}>
                                     <FormItem {...formItemLayout} label={<span>
                                        Begin Date&nbsp;
                                    <Tooltip title=" the date you want to have your paper account ready">
                                    <Icon type="question-circle-o" />
                                    </Tooltip>
                                </span>}>
                                    {getFieldDecorator('beginDate', {
                                        initialValue: startdatement,
                                        rules: [{
                                            required: false,
                                            message: 'Please select Begin Date',
                                        }],
                                        })(
                                        <DatePicker 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(date, dateString) => {
                                                this.onInputChange({ name: 'begindate', value: dateString })
                                            }}
                                        />
                                        )}
                                    </FormItem> 
                                
                                </Col>

                                
                                </Row>
                                : <div></div>
                            }
                    </div>
                    <div style={{marginLeft: 80 }}>{
                            mode.includes('PaperPortfolioOrBook') || mode.includes('Stock Pitch') ? 
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} label="Sector (tentative)">
                                        {getFieldDecorator('sector', {
                                            initialValue: sector,
                                            rules: [
                                                {
                                                required: true,
                                                message: 'Please select sector',
                                                },
                                                {
                                                    validator: (rule, value, callback) => {
                                                        // 使用正则表达式分割输入值，以分号分隔
                                                        const groups = value.split(';');
                                                        let valid = true;
                                    
                                                        // 遍历每个分组并检查是否为6位或8位数字
                                                        for (const group of groups) {
                                                            const numericValue = group.replace(/\D/g, ''); // 去除非数字字符
                                                            if (numericValue.length !== 6 && numericValue.length !== 8) {
                                                                valid = false;
                                                                break; // 如果有任何一个分组不满足条件，终止循环
                                                            }
                                                        }
                                    
                                                        if (!valid) {
                                                            callback('Input must be 6 or 8 digits for each group, separated by semicolons.eg:123456;12345678');
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                },
                                            ],
                                            })(
                                                // 
                                            <Input
                                            placeholder='sector code' 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'sector', value: e.target.value })
                                            }}
                                            />
                                           
                                            )}
                                        </FormItem>
                                        <div style={{marginLeft: 200} }>
                                            <a 
                                            href= {BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL+'/MSCI_Global_Industry_Classification_Standard_(GICS®)_Methodology_20230601_20231025095036801.pdf'} 
                                            download="MSCI_Global_Industry">SectorCode Documentation</a>
                                        </div>
                                </Col>
                            </Row>
                                : <div></div>
                            }
                    </div>
                    <div style={{marginLeft: 80 }}>{
                        mode.includes('PaperPortfolioOrBook') || mode.includes('Stock Pitch')? 
                            <Row gutter={24}>
                            <Col span={12}>
                                    <FormItem {...formItemLayout} label="Market (tentative)">
                                        {getFieldDecorator('market', {
                                            initialValue: market,
                                            rules: [
                                                {
                                                required: true,
                                                message: 'Please select Market',
                                            }],
                                            })(
                                            <Select
                                                    allowClear
                                                    mode='multiple'
                                                    style={{ width: 300, marginLeft: 10 }}
                                                    onChange={(val) => {
                                                        this.onEmailInputChange({ name: 'market', value: val })
                                            }}
                                                    placeholder="Please select Market">
                                                {markingOptions}
                                            </Select>
                                            )}
                                        </FormItem>
                                </Col>
                            </Row> 
                                : <div></div>
                            }
                    </div>  

                    <div style={{marginLeft: 80 }}>{
                        mode.includes('PaperPortfolioOrBook') ?                          
                        <Row gutter={24}>
                        <Col span={12}>
                            <FormItem {...formItemLayout} 
                            
                            label={<span>
                                Upload(BBGTicker)&nbsp;
                            <Tooltip title=" Download the template, fill it correctly, and upload it">
                            <Icon type="question-circle-o" />
                            </Tooltip>
                            </span>}
                            
                            >
                                {getFieldDecorator('uploadOriName', {
                                    initialValue: uploadOriName,
                                    rules: [{
                                        required: true,
                                        message: 'Please upload the Agreement',
                                    }],
                                    })(
                                   
                                    <Upload
                                        name="uploadOriName"
                                        withCredentials={true}
                                        
                                        // disabled={needDisable}
                                        multiple={false}
                                        showUploadList={true}
                                        fileList={agreementUploadFileList}
                                        action={meetingAndTrainingUploadUrl}
                                        onChange={this.generalAgreeementHandleChange}
                                    >
                                        <Button 
                                            style={{ width: 300, marginLeft: 10 }}
                                            // disabled={needDisable}
                                        >
                                            <Icon type="upload" />Stock lists
                                        </Button>
                                    </Upload>

                                )}
                            </FormItem>
                            <div  style={{marginLeft: 200} }>
                                <a 
                                href= {BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL+'/Trading Universe Set-up - GICS Code_20231025094718924.xlsx'} 
                                download="MSCI_Global_Industry">Download Template</a>
                            </div>
                        </Col>

                        
                    </Row>
                        : <div></div>
                    }
                    </div>    

                    <div style={{marginLeft: 80 }}>{
                            mode.includes('PaperPortfolioOrBook') ? 
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem {...formItemLayout} 
                                    label=
                                    {<span>
                                        Exception&nbsp;
                                    <Tooltip title="Stock not in your sector but you still want to trade, BBG full Ticker">
                                    <Icon type="question-circle-o" />
                                    </Tooltip>
                                    </span>}
                                    >
                                        {getFieldDecorator('exception', {
                                            initialValue: exception,
                                            rules: [
                                                {
                                                required: false,
                                                message: 'Exception Ticker',
                                                },
                                                
                                            ],
                                            })(
                                                <div>
                                            <Input
                                            placeholder='Exception Ticker' 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'exception', value: e.target.value })
                                            }}
                                            />
                                        
                                            </div>
                                            )}
                                        </FormItem>
                                </Col>
                            </Row>
                                : <div></div>
                            }
                    </div>
                    <div style={{marginLeft: 80 }}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Note">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remark,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 500, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='Please input remarks' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remark', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        
                    </div>

                       

                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditApplyReconModalDialogForm = Form.create({ name: 'AddOrEditApplyReconModalDialog' })(AddOrEditApplyReconModalDialog);

export default AddOrEditApplyReconModalDialogForm;
